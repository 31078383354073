import React from 'react';
import '../guides/APIGuides.css';

const CustomMappingsGuide = () => {
    return (
        <div className="guide-page">
            <h1>Customizing API Mappings</h1>
            <p>This guide walks you through defining custom mappings for data processing to fit your unique workflows.</p>

            <h2>Why Custom Mappings?</h2>
            <p>Custom mappings allow you to map incoming data fields to your internal data structures, ensuring seamless integration with minimal code changes.</p>

            <h2>Available Data Fields</h2>
            <p>You can map the following fields from the incoming payload. Use dot notation to access nested fields (e.g., <code>patient.firstName</code>):</p>
            <ul>
                <li><strong>selectedProviders:</strong> Object with provider information:
                    <ul>
                        <li><strong>id:</strong> Provider ID</li>
                        <li><strong>firstName:</strong> Provider's first name</li>
                        <li><strong>lastName:</strong> Provider's last name</li>
                        <li><strong>organizationName:</strong> Provider's organization name</li>
                        <li><strong>npi:</strong> National Provider Identifier</li>
                        <li><strong>phone:</strong> Provider's contact number</li>
                        <li><strong>address1:</strong> Primary address</li>
                        <li><strong>address2:</strong> Secondary address</li>
                        <li><strong>city:</strong> City</li>
                        <li><strong>state:</strong> State</li>
                        <li><strong>zip:</strong> ZIP code</li>
                    </ul>
                </li>
                <li><strong>billingCode:</strong> Array of billing codes:
                    <ul>
                        <li><strong>name:</strong> Name or description of the billing code</li>
                        <li><strong>code:</strong> Billing code (e.g., CPT, HCPCS)</li>
                        <li><strong>diagnosisCode:</strong> Associated diagnosis codes</li>
                        <li><strong>billAmount:</strong> Billed amount</li>
                    </ul>
                </li>
                <li><strong>dateOfService:</strong> Date the service was provided</li>
                <li><strong>encounterType:</strong> Type of encounter (e.g., "Emergency Room Visit")</li>
                <li><strong>patientData:</strong> Object with patient information:
                    <ul>
                        <li><strong>patient.firstName:</strong> Patient's first name</li>
                        <li><strong>patient.lastName:</strong> Patient's last name</li>
                        <li><strong>patient.dob:</strong> Patient's date of birth</li>
                        <li><strong>patient.phone:</strong> Patient's phone number</li>
                        <li><strong>patient.address1:</strong> Primary address</li>
                        <li><strong>patient.city:</strong> City</li>
                        <li><strong>patient.state:</strong> State</li>
                        <li><strong>patient.zip:</strong> ZIP code</li>
                    </ul>
                </li>
                <li><strong>payers:</strong> Object with payer information:
                    <ul>
                        <li><strong>name:</strong> Insurance provider name</li>
                        <li><strong>memberId:</strong> Insurance member ID</li>
                    </ul>
                </li>
            </ul>

            <h2>Defining Custom Mappings</h2>
            <p>To create custom mappings, follow these steps:</p>
            <ol>
                <li>Go to the API Mappings section of the dashboard.</li>
                <li>Use the mapping editor to define your mappings. Here's an example:</li>
            </ol>
            <pre>
                {`
                {
                    "patientID": "patientData.patient.patientID",
                    "firstName": "patientData.patient.firstName",
                    "lastName": "patientData.patient.lastName",
                    "dateOfService": "dateOfService",
                    "encounterType": "encounterType",
                    "billingCodes": "billingCode",
                    "providerName": "selectedProviders.firstName + ' ' + selectedProviders.lastName",
                    "payerInfo": {
                        "name": "payers.name",
                        "memberId": "payers.memberId"
                    }
                }
                `}
            </pre>
            <ol start="3">
                <li>Save the mappings and test them using the testing tool.</li>
            </ol>

            <h2>Testing Custom Mappings</h2>
            <p>Use the following payload to test your custom mappings:</p>
            <pre>
                {`
                {
                    "selectedProviders": {
                        "id": "C40AwsBwyuCXymdaaPdi",
                        "firstName": "WINCHENZO",
                        "lastName": "GRUMPY",
                        "organizationName": "GRUMPYFACE ORG",
                        "npi": "1231231234",
                        "phone": "3109991123",
                        "address1": "123 GRUMPY ST",
                        "city": "MANHATTAN",
                        "state": "NY",
                        "zip": "100010000"
                    },
                    "billingCode": [
                        {
                            "name": "Sample Code",
                            "code": "E0570",
                            "diagnosisCode": ["E299"],
                            "billAmount": 150
                        }
                    ],
                    "dateOfService": "2025-01-03",
                    "encounterType": "Emergency Room Visit",
                    "patientData": {
                        "patient": {
                            "firstName": "John",
                            "lastName": "Doe",
                            "dob": "01/01/1980",
                            "phone": "(312) 123-1231",
                            "address1": "123 Address",
                            "city": "City",
                            "state": "WA",
                            "zip": "98101"
                        },
                        "payers": {
                            "name": "AETNA",
                            "memberId": "1019971233"
                        }
                    }
                }
                `}
            </pre>

            <h2>Expected Response</h2>
            <p>The processed response will look like this:</p>
            <pre>
                {`
                {
                    "status": "success",
                    "message": "Mapping applied successfully.",
                    "data": {
                        "patientID": "123-abc",
                        "firstName": "John",
                        "lastName": "Doe",
                        "dateOfService": "2025-01-03",
                        "encounterType": "Emergency Room Visit",
                        "billingCodes": [
                            {
                                "name": "Sample Code",
                                "code": "E0570",
                                "diagnosisCode": ["E299"],
                                "billAmount": 150
                            }
                        ],
                        "providerName": "WINCHENZO GRUMPY",
                        "payerInfo": {
                            "name": "AETNA",
                            "memberId": "1019971233"
                        }
                    }
                }
                `}
            </pre>

            <h2>Additional Resources</h2>
            <ul>
                <li>
                    <a href="/tryapi/guides/lab-results-api" className="guide-link">
                        Processing Lab Test Results
                    </a>
                </li>
                <li>
                    <a href="/tryapi/guides/encounters-api" className="guide-link">
                        Getting Started with the Encounters API
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default CustomMappingsGuide;
