import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import '../guides/APIGuides.css';

export const encountersApiSpecification = {
  "openapi": "3.0.0",
  "info": {
    "title": "Encounters API",
    "version": "1.0.0",
    "description": "API for managing patient encounters."
  },
  "paths": {
    "/encounters": {
      "post": {
        "summary": "Create a new encounter",
        "description": "Endpoint to create a new encounter for a patient.",
        "requestBody": {
          "required": true,
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "patient": {
                    "type": "object",
                    "properties": {
                      "firstName": { "type": "string", "example": "John" },
                      "lastName": { "type": "string", "example": "Doe" },
                      "dob": { "type": "string", "format": "date", "example": "1980-01-01" },
                      "gender": { "type": "string", "example": "M" },
                      "address1": { "type": "string", "example": "123 Main St" },
                      "city": { "type": "string", "example": "Seattle" },
                      "state": { "type": "string", "example": "WA" },
                      "zip": { "type": "string", "example": "98101" }
                    },
                    "required": ["firstName", "lastName", "dob"]
                  },
                  "encounter": {
                    "type": "object",
                    "properties": {
                      "dateOfService": { "type": "string", "format": "date", "example": "2025-01-10" },
                      "reasonForVisit": { "type": "string", "example": "Routine checkup" },
                      "notes": { "type": "string", "example": "Patient in good health." }
                    },
                    "required": ["dateOfService", "reasonForVisit"]
                  },
                  "provider": {
                    "type": "object",
                    "properties": {
                      "organizationName": { "type": "string", "example": "Health Clinic" },
                      "npi": { "type": "string", "example": "1234567890" },
                      "providerFirstName": { "type": "string", "example": "Jane" },
                      "providerLastName": { "type": "string", "example": "Smith" }
                    },
                    "required": ["organizationName", "npi"]
                  }
                }
              },
              "examples": {
                "SamplePayload": {
                  "summary": "Sample Payload for Testing",
                  "value": {
                    "patient": {
                      "firstName": "John",
                      "lastName": "Doe",
                      "dob": "1980-01-01",
                      "gender": "M",
                      "address1": "123 Main St",
                      "city": "Seattle",
                      "state": "WA",
                      "zip": "98101"
                    },
                    "encounter": {
                      "dateOfService": "2025-01-10",
                      "reasonForVisit": "Routine checkup",
                      "notes": "Patient in good health."
                    },
                    "provider": {
                      "organizationName": "Health Clinic",
                      "npi": "1234567890",
                      "providerFirstName": "Jane",
                      "providerLastName": "Smith"
                    }
                  }
                }
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "Encounter created successfully.",
            "content": {
              "application/json": {
                "example": {
                  "status": "success",
                  "message": "Encounter created successfully.",
                  "data": {
                    "encounterId": "abc123",
                    "createdAt": "2025-01-10T12:00:00Z"
                  }
                }
              }
            }
          },
          "400": {
            "description": "Invalid input data.",
            "content": {
              "application/json": {
                "example": {
                  "status": "error",
                  "message": "Invalid input. 'dob' must be a valid date."
                }
              }
            }
          }
        }
      }
    }
  }
}
