import React, { useState, useEffect } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import PayerSelection from "../Payers/PayerSelection";
import { getFunctions, httpsCallable } from "firebase/functions";
import styles from "./PatientView.module.css";
import Modal from "react-modal";

const functions = getFunctions();

const InsuranceStatus = ({ patientSelected, onDataUpdated, uid }) => {
  const [primaryStatus, setPrimaryStatus] = useState("Active");
  const [secondaryStatus, setSecondaryStatus] = useState("Active");
  const [isPrimaryPayerMatched, setIsPrimaryPayerMatched] = useState(false);
  const [isSecondaryPayerMatched, setIsSecondaryPayerMatched] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingPayerType, setEditingPayerType] = useState(null);

  useEffect(() => {
    const payers = patientSelected?.data?.payers || {};
    setIsPrimaryPayerMatched(Boolean(payers?.CPID && payers.CPID !== "Not Available"));
    setIsSecondaryPayerMatched(
      Boolean(payers?.secondaryPayer?.CPID && payers.secondaryPayer.CPID !== "Not Available")
    );
    setPrimaryStatus(payers?.status || "Active");
    setSecondaryStatus(payers?.secondaryPayer?.status || "Active");
  }, [patientSelected]);

  const handleToggleStatus = async (type) => {
    if (!uid || !patientSelected?.id) return;

    const isPrimary = type === "primary";
    const newStatus =
      isPrimary
        ? primaryStatus === "Active" ? "Inactive" : "Active"
        : secondaryStatus === "Active" ? "Inactive" : "Active";

    try {
      const existingData = { ...patientSelected.data };

      if (isPrimary) {
        existingData.payers = {
          ...existingData.payers,
          status: newStatus,
        };
      } else {
        existingData.payers = {
          ...existingData.payers,
          secondaryPayer: {
            ...existingData.payers.secondaryPayer,
            status: newStatus,
          },
        };
      }

      const encryptFunction = httpsCallable(functions, "encrypt");
      const encryptionResult = await encryptFunction(existingData);
      const { iv, ciphertext } = encryptionResult.data;

      const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
      await updateDoc(patientRef, { patient: { iv, ciphertext } });

      if (isPrimary) setPrimaryStatus(newStatus);
      else setSecondaryStatus(newStatus);

      onDataUpdated();
    } catch (error) {
      console.error("Error updating payer status:", error);
    }
  };

  const handleOpenPayerSelector = (type) => {
    setEditingPayerType(type);
    setIsModalOpen(true);
  };

  const handlePayerSelect = async (payer) => {
    console.log("Selected payer:", payer);
    if (!payer || !editingPayerType || !uid || !patientSelected?.id) return;

    try {
      const existingData = { ...patientSelected.data };
      const isPrimary = editingPayerType === "primary";

      // STEP 1: Normalize the fields from PayerSelection
      const payerName = payer.name || payer.payerName || "Not Available";
      const memberId = payer.memberId || existingData.payers?.memberId || "Not Available";

      // If PayerSelection sometimes returns "CPID" or "tradingPartnerId", unify them:
      const cpid = payer.CPID ?? payer.tradingPartnerId ?? "Not Available";
      // Same for RealtimePayerID if it’s sometimes "realtimePayerId" or "PayerID"
      const realtimePayerID = payer.RealtimePayerID ?? payer.realtimePayerId ?? "Not Available";
      const claimStatusPayerID = payer.ClaimStatusPayerID ?? payer.claimStatusPayerId ?? "";

      if (isPrimary) {
        existingData.payers = {
          ...existingData.payers,
          name: payerName,
          memberId: memberId,
          CPID: cpid,
          RealtimePayerID: realtimePayerID,
          ClaimStatusPayerID: claimStatusPayerID,
          status: existingData.payers?.status || "Active",
        };
      } else {
        // Secondary payer fields
        const oldSecondary = existingData.payers.secondaryPayer || {};
        existingData.payers = {
          ...existingData.payers,
          secondaryPayer: {
            ...oldSecondary,
            name: payerName || oldSecondary.name || "Not Available",
            memberId: payer.memberId || oldSecondary.memberId || "Not Available",
            CPID: cpid || oldSecondary.CPID || "Not Available",
            RealtimePayerID: realtimePayerID || oldSecondary.RealtimePayerID || "Not Available",
            ClaimStatusPayerID: claimStatusPayerID || oldSecondary.ClaimStatusPayerID || "",
            status: oldSecondary.status || "Active",
          },
        };
      }

      // STEP 2: Re-encrypt entire patient object
      const encryptFunction = httpsCallable(functions, "encrypt");
      const encryptionResult = await encryptFunction(existingData);
      const { iv, ciphertext } = encryptionResult.data;

      // STEP 3: Save to Firestore
      const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
      await updateDoc(patientRef, { patient: { iv, ciphertext } });

      setIsModalOpen(false);
      onDataUpdated();
    } catch (error) {
      console.error("Error updating payer information:", error);
    }
  };

  const renderPayerStatus = (type, name, isMatched, status, memberId) => (
    <div className={styles.payerSummary} style={{ marginTop: type === "secondary" ? "10px" : "0" }}>
      <p className={styles.insuranceName} style={{ fontSize: "20px", fontWeight: "bold" }}>
        {name || `${type === "primary" ? "Primary" : "Secondary"} Insurance`}
      </p>
      {memberId && (
        <p className={styles.memberId} style={{ fontSize: "14px", color: "gray", marginTop: "5px" }}>
          Member ID: {memberId}
        </p>
      )}
      <span
        onClick={() => (isMatched ? handleToggleStatus(type) : handleOpenPayerSelector(type))}
        style={{
          cursor: "pointer",
          color: isMatched ? (status === "Active" ? "blue" : "red") : "orange",
          fontSize: "12px",
          textDecoration: "underline",
          marginTop: "5px",
        }}
      >
        {isMatched ? status : "Payer Not Matched"}
      </span>
    </div>
  );

  const tradingPartnerName =
    editingPayerType === "primary"
      ? patientSelected?.data?.payers?.name || ""
      : patientSelected?.data?.payers?.secondaryPayer?.name || "";

  return (
    <div className={styles.flexContainer}>
      {renderPayerStatus(
        "primary",
        patientSelected.data.payers?.name || "Primary Insurance",
        isPrimaryPayerMatched,
        primaryStatus,
        patientSelected.data.payers?.memberId
      )}

      {patientSelected.data.payers?.secondaryPayer?.name &&
        renderPayerStatus(
          "secondary",
          patientSelected.data.payers.secondaryPayer.name,
          isSecondaryPayerMatched,
          secondaryStatus,
          patientSelected.data.payers.secondaryPayer?.memberId
        )}

<Modal
  isOpen={isModalOpen}
  onRequestClose={() => setIsModalOpen(false)}
  contentLabel="Select Payer"
  className={styles.modal}
  overlayClassName={styles.modalOverlay}
>
  <h2>Select Payer</h2>
  <PayerSelection
    mode="all"
    onSelect={handlePayerSelect}
    TradingPartnerName={tradingPartnerName}
  />
  
  {/* NEW: A simple "Continue" button in case user wants to skip or it freezes */}
  <div style={{ marginTop: "1rem" }}>
    <button
      onClick={() => {
        // Just close the modal without calling handlePayerSelect
        setIsModalOpen(false);
      }}
      style={{ cursor: "pointer", padding: "0.5rem 1rem" }}
    >
      Continue
    </button>
  </div>
</Modal>

    </div>
  );
};

export default InsuranceStatus;
