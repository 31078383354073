import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import useUID from "../General/useUID";

const AuthDetails = () => {
  const [authUser, setAuthUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [uid, subUserUID, isLoading, error] = useUID();
  const navigate = useNavigate();

  useEffect(() => {
    if (!uid) return; // do not proceed if uid is not available
    const listen = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAuthUser(user);
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } else {
        setAuthUser(null);
        setUserData(null);
      }
    });

    return () => {
      listen();
    };
  }, [uid]);

  const userSignOut = () => {
    signOut(auth)
        .then(() => {
            // Clear the company logo session item
            sessionStorage.removeItem("companyLogo");
            sessionStorage.removeItem("logoVersion"); // Also clear logo version if used
            navigate("/");
        })
        .catch((error) => console.log(error));
};


  return (
    <div>
      {authUser ? (
        <>
          <span
            onClick={userSignOut}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "600",
              transition: "all 0.2s ease-in-out",
              marginBottom: "5px",
              width: "12rem",
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "7px" }}
            >
              <circle cx="12" cy="12" r="11.5" stroke="white" />
              <path
                d="M8 8L16 16"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 8L8 16"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Sign Out
          </span>
        </>
      ) : (
        <p>Signed Out</p>
      )}
    </div>
  );
};

export default AuthDetails;
