import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import '../guides/APIGuides.css';

export const addPatientApiSpecification = {
  openapi: "3.0.0",
  info: {
    title: "Add Patient API",
    version: "1.0.0",
    description: "API for adding or updating patient data and their encounters."
  },
  paths: {
    "/add-patient": {
      post: {
        summary: "Add or update patient data",
        description: "Endpoint to add or update patient data and their related encounters.",
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  patient: {
                    type: "object",
                    required: ["firstName", "lastName", "dob", "gender"],
                    properties: {
                      firstName: { type: "string", example: "John" },
                      lastName: { type: "string", example: "Doe" },
                      dob: { type: "string", format: "date", example: "1980-01-01" },
                      gender: { type: "string", example: "Male" },
                      address: {
                        type: "object",
                        properties: {
                          address1: { type: "string", example: "123 Main St" },
                          city: { type: "string", example: "Anytown" },
                          state: { type: "string", example: "CA" },
                          zip: { type: "string", example: "12345" }
                        }
                      },
                      memberId: { type: "string", example: "A12345" },
                      payerName: { type: "string", example: "HealthPayer" }
                    }
                  },
                  encounters: {
                    type: "array",
                    items: {
                      type: "object",
                      required: ["dateOfService", "service"],
                      properties: {
                        dateOfService: { type: "string", format: "date", example: "2025-01-12" },
                        reasonForVisit: { type: "string", example: "Routine check-up" },
                        service: { type: "string", example: "Consultation" },
                        encounterType: { type: "string", example: "In-person" },
                        controlNumber: { type: "string", example: "CN123456" },
                        encounterId: { type: "string", example: "EID123456" },
                        notes: { type: "string", example: "Patient in good health." },
                        billingCodes: {
                          type: "array",
                          items: {
                            type: "object",
                            properties: {
                              code: { type: "string", example: "A123" },
                              description: { type: "string", example: "Blood test" },
                              amount: { type: "number", format: "float", example: 50 }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              examples: {
                SamplePayload: {
                  summary: "Sample Payload for Testing",
                  value: {
                    patient: {
                      firstName: "John",
                      lastName: "Doe",
                      dob: "1980-01-01",
                      gender: "Male",
                      address: {
                        address1: "123 Main St",
                        city: "Anytown",
                        state: "CA",
                        zip: "12345"
                      },
                      memberId: "A12345",
                      payerName: "HealthPayer"
                    },
                    encounters: [
                      {
                        dateOfService: "2025-01-12",
                        reasonForVisit: "Routine check-up",
                        service: "Consultation",
                        encounterType: "In-person",
                        controlNumber: "CN123456",
                        encounterId: "EID123456",
                        notes: "Patient in good health.",
                        billingCodes: [
                          { code: "A123", description: "Blood test", amount: 50 }
                        ]
                      }
                    ]
                  }
                }
              }
            }
          }
        },
        responses: {
          "200": {
            description: "Patient data saved successfully.",
            content: {
              "application/json": {
                example: {
                  status: "success",
                  message: "Patient data saved successfully with 1 encounter(s).",
                  patientId: "UID12345",
                  encounterCount: 1
                }
              }
            }
          },
          "400": {
            description: "Invalid input or API key.",
            content: {
              "application/json": {
                example: {
                  status: "error",
                  message: "Missing required patient fields: firstName, lastName, dob, gender."
                }
              }
            }
          }
        }
      }
    }
  }
};
