import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faGlobeAmericas, faServer } from '@fortawesome/free-solid-svg-icons';
import HIPAA from '../assets/hipaa-compliance.png';
import './EncryptedBackground.css';

const SecuritySection = ({ isVisible }) => {
  const steps = [
    {
      title: 'Encryption',
      description:
        'Your data is encrypted to the highest standards set by NIST: AES-256 with multiple keys needed to decrypt.',
      icon: faLock,
    },
    {
      title: 'Geofencing',
      description:
        'We only allow access from the United States and the EU. Any VPN or dark web connections are strictly rejected.',
      icon: faGlobeAmericas,
    },
    {
      title: 'Disaster Recovery',
      description:
        'Daily backups of all your data to three separate data centers within the United States for disaster recovery.',
      icon: faServer,
    },
  ];

  const [hashes, setHashes] = useState([]);

  // Generate a random hash
  const generateHash = () => {
    return Array.from({ length: 64 }, () =>
      Math.floor(Math.random() * 16).toString(16)
    ).join('');
  };

  // Continuously add hashes at a reasonable rate
  useEffect(() => {
    const interval = setInterval(() => {
      setHashes((prev) => {
        const totalCharacters = prev.join('').length;
  
        // Stop adding hashes if the total character count exceeds the limit
        if (totalCharacters >= 7000) return prev;
  
        const newHash = generateHash();
        const newTotalCharacters = totalCharacters + newHash.length;
  
        // Only add the new hash if it keeps the total under the limit
        if (newTotalCharacters <= 7000) {
          return [...prev, newHash];
        }
        return prev;
      });
    }, 500); // Add a new hash every 500ms
  
    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);
  

  return (
    <section className="bg-dark skewed-section" id="security">
      <div className="section-content-wrapper">
        {/* Background layers */}
        <div className="encrypted-background">
            {hashes.reduce((acc, hash, index) => {
                const currentLength = acc.reduce((sum, item) => sum + item.length, 0);
                if (currentLength + hash.length <= 6000) { // Set your max character limit here
                acc.push(hash);
                }
                return acc;
            }, []).map((hash, index) => (
                <span key={index}>{hash}</span>
            ))}
            </div>

        <div className="blue-overlay"></div>
        {/* Main content */}
        <div className="contentEncrption">
          <h2 className={` ${isVisible['security'] ? 'animate' : ''}`}>
            History's First Encrypted EMR*
          </h2>
          <div className="process-steps">
            {steps.map((step, index) => (
              <div
                className={`step-3 ${isVisible['process'] ? 'animate' : ''}`}
                key={`${step.title}-${index}`}
                style={{ animationDelay: `${index * 0.2}s` }}
              >
                <FontAwesomeIcon
                  icon={step.icon}
                  className="step-icon-dark"
                  aria-label={step.title}
                />
                <h3 className="step-title-dark">{step.title}</h3>
                <p className="step-description-dark">{step.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* HIPAA Image */}
        <img src={HIPAA} alt="HIPAA Compliance" className="hipaa-image" />
      </div>

      <p className='securityFootnote'>
        *While many Electronic Medical Record (EMR) systems assert that they utilize encryption, they often depend on third-party storage providers or API partners to secure data. These systems typically do not implement a Zero-Trust security model, allowing their employees access to unencrypted information. Our platform adheres to a strict Zero-Trust architecture, ensuring that internal personnel cannot view unencrypted data.
        </p>
    </section>
  );
};

export default SecuritySection;