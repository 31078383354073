import React, { useState, useEffect } from 'react';
import '../Website.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';
import Header from '../subcomponents/Header';
import { FaClipboardCheck, FaFileMedical, FaLaptopMedical } from 'react-icons/fa';
import Confetti from '../../General/Animations/Confetti';
import '../subcomponents/MovingGradientBackground.css';

const DemoRequestForm = ({ onClose, onSubmitted }) => {
  // State variables
  const [name, setName] = useState(localStorage.getItem('name') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [date, setDate] = useState(localStorage.getItem('date') || '');
  const [time, setTime] = useState(localStorage.getItem('time') || '');
  const [timeZone, setTimeZone] = useState(localStorage.getItem('timeZone') || '');
  const [message, setMessage] = useState(localStorage.getItem('message') || '');
  const [preferredContact, setPreferredContact] = useState(localStorage.getItem('preferredContact') || '');
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token') || localStorage.getItem('token');
  const [company, setCompany] = useState( localStorage.getItem('company') ||
  new URLSearchParams(window.location.search).get('company') ||
  '');
  // Persist state to localStorage
  useEffect(() => {
    localStorage.setItem('name', name);
  }, [name]);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem('date', date);
  }, [date]);

  useEffect(() => {
    localStorage.setItem('time', time);
  }, [time]);

  useEffect(() => {
    localStorage.setItem('timeZone', timeZone);
  }, [timeZone]);

  useEffect(() => {
    localStorage.setItem('message', message);
  }, [message]);

  useEffect(() => {
    localStorage.setItem('preferredContact', preferredContact);
  }, [preferredContact]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) errors.name = 'Name is required';
    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid';
    }
    if (!date) errors.date = 'Preferred demo date is required';
    if (!company.trim()) errors.company = 'Company is required'; // Added company validation
    return errors;
  };
  

  // Check if the selected date is a weekday
  const isWeekday = (dateStr) => {
    const day = new Date(dateStr).getDay();
    return day !== 0 && day !== 6; // 0 = Sunday, 6 = Saturday
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (!isWeekday(date)) {
      setErrors({ date: 'Please choose a weekday for the demo.' });
      return;
    }

    setIsLoading(true);
    const functions = getFunctions();
    const sendDemoRequest = httpsCallable(functions, 'sendEmailDemo');

    try {
      await sendDemoRequest({ name, email, date, company });
      setIsSubmitted(true);
      if (onSubmitted) onSubmitted();
    } catch (error) {
      console.error('Error sending demo request: ', error);
      setErrors({ submit: 'There was an error submitting your request. Please try again later.' });
    } finally {
      setIsLoading(false);
    }
  };

  // Generate time options for dropdown (9 AM - 5 PM in 30-minute intervals)
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 9; hour <= 17; hour++) {
      ['00', '30'].forEach((minutes) => {
        if (hour === 17 && minutes !== '00') return; // Exclude times after 5:00 PM
        const timeString = `${hour.toString().padStart(2, '0')}:${minutes}`;
        times.push(timeString);
      });
    }
    return times;
  };

  return (
    <div className="demo-page">
      <Helmet>
        <title>Demo | Popularis</title>
        <meta name="description" content="Request a demo for Popularis." />
        <meta name="keywords" content="demo, Popularis" />
        <meta property="og:title" content="Demo Request" />
        <meta property="og:description" content="Request a demo for Popularis." />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
      </Helmet>
      <Header />
      <div className="demo-content">
        <main>
          <div className="demo-wrapper">
            {/* Left Section */}
            <div className="demo-left">
              <h2>What to Expect:</h2>
              <div className="expect-items">
                <div className="expect-item">
                  <FaClipboardCheck size={40} className="expect-icon" />
                  <div className="expect-text">
                    <h4>Go Over Requirements</h4>
                    <p>We will discuss your specific needs and requirements to tailor our services accordingly.</p>
                  </div>
                </div>
                <div className="expect-item">
                  <FaFileMedical size={40} className="expect-icon" />
                  <div className="expect-text">
                    <h4>Patient Files Overview</h4>
                    <p>Explore how to manage patient records efficiently using our comprehensive patient file system.</p>
                  </div>
                </div>
                <div className="expect-item">
                  <FaLaptopMedical size={40} className="expect-icon" />
                  <div className="expect-text">
                    <h4>Use Cases for Popularis</h4>
                    <p>Discover real-world applications and benefits of Popularis for your practice.</p>
                  </div>
                </div>
              </div>
              {/* Sign Up Button */}
              <div className="signup-button-container" style={{ marginTop: '20px' }}>
                {/* Using <a> tag */}
                <a href="/signup">
                  <button className="signup-button">Sign Up for a Popularis Account</button>
                </a>
               
              </div>
            </div>

            {/* Right Section - Demo Form */}
            <div className="demo-right">
              <div className="signup-container">
                {!isSubmitted ? (
                  <>
                    <h3 className='text-center'>Request a Demo</h3>
                    <form onSubmit={handleSubmit}>
                             {/* Name */}
                             <div className="input-field salesBlack">
                        <label className="input-field" htmlFor="name">Your Name:</label>
                        <input
                          id="name"
                          type="text"
                          value={name}
                          onChange={(event) => {
                            setName(event.target.value);
                            setErrors({ ...errors, name: '' });
                          }}
                          className={errors.name ? 'error' : ''}
                          required
                        />
                        {errors.name && <p className="error-message">{errors.name}</p>}
                      </div>

                      <div className="input-field salesBlack">
                        <label className="input-field" htmlFor="name">Company:</label>
                        <input
                          id="company"
                          type="text"
                          value={company}
                          onChange={(event) => {
                            setCompany(event.target.value);
                            setErrors({ ...errors, name: '' });
                          }}
                          className={errors.name ? 'error' : ''}
                          required
                        />
                        {errors.name && <p className="error-message">{errors.name}</p>}
                      </div>

                      {/* Email */}
                      <div className="input-field salesBlack">
                        <label className="input-field" htmlFor="email">Email:</label>
                        <input
                          id="email"
                          type="email"
                          value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                            setErrors({ ...errors, email: '' });
                          }}
                          className={errors.email ? 'error' : ''}
                          required
                        />
                        {errors.email && <p className="error-message">{errors.email}</p>}
                      </div>

                      {/* Preferred Demo Date */}
                      <div className="input-field salesBlack">
                        <label className="input-field" htmlFor="date">Preferred Demo Date:</label>
                        <input
                          id="date"
                          type="date"
                          value={date}
                          onChange={(event) => {
                            setDate(event.target.value);
                            setErrors({ ...errors, date: '' });
                          }}
                          className={errors.date ? 'error' : ''}
                          required
                        />
                        {errors.date && <p className="error-message">{errors.date}</p>}
                      </div>
                      {/* Submit Button */}
                      {errors.submit && <p className="error-message">{errors.submit}</p>}
                      <button type="submit" disabled={isLoading}>
                        {isLoading ? 'Submitting...' : 'Request Demo'}
                      </button>
                    </form>
                  </>
                ) : (
                  <div className="confetti-container">
                    <Confetti />
                    <h2>Thank you for your demo request! We will get back to you soon.</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* Removed Footer */}
      <div className="gradient-skewed-bottom"></div>
    </div>
  );
};

export default DemoRequestForm;
