import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const RoleProtectedRoute = ({ allowedRoles }) => {
  // Retrieve the user's role from session storage
  const userRole = sessionStorage.getItem('userRole');
  
  // Check if the userRole is in the allowedRoles array
  if (allowedRoles.includes(userRole)) {
    return <Outlet />; // Render the protected component if the role is allowed
  } else {
    return <Navigate to="/home" replace />; // Redirect to home or another page if not allowed
  }
};

export default RoleProtectedRoute;
