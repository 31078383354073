import React from 'react';

// Import the screenshots
import eligibilityImage from '../assets/screenshots/eligibility.png';
import customFormImage from '../assets/screenshots/customForm.png';
import billingImage from '../assets/screenshots/billing.png';
import cardsImage from '../assets/screenshots/cards.png';
import ipadPatients from '../assets/iPad_patients.png';

const UiGrid = () => {
  const cards = [
    {
      image: eligibilityImage,
      title: 'Eligibility Verification',
      description: 'Instantly verify patient eligibility across multiple payers.',
      link: '/tryclaims',
      buttonText: 'Learn more about Eligibility',
      imageClass: 'image-eligibility',
      cardClass: 'card-eligibility',
    },
    {
      image: customFormImage,
      title: 'Custom Forms',
      description: 'Create and manage custom intake forms tailored to your practice.',
      link: '/trypatients',
      buttonText: 'Learn more about Custom Forms',
      imageClass: 'image-custom-forms',
      cardClass: 'card-custom-forms',
    },
    {
      image: billingImage,
      title: 'Billing Dashboard',
      description: 'Get a birds-eye view of you billing and never be behind.',
      link: '/tryclaims',
      buttonText: 'Learn more about Billing',
      imageClass: 'image-billing',
      cardClass: 'card-billing',
    },
    {
      image: cardsImage,
      title: 'Patient Cards',
      description: 'Manage patient information efficiently with our intuitive interface.',
      link: '/tryclaims',
      buttonText: 'Learn more about Patient Cards',
      imageClass: 'image-patient-cards',
      cardClass: 'card-patient-cards',
    },
  ];

  return (
    <section className="ui-grid-section golden-container"> 

        <div className="landingPricing">
          <h3 className="pricing-subtitle">The full clinical suite of tools</h3>
          <h2 className="pricing-title">
              Starting at $199 <span className="pricing-period">per month</span>
            </h2>          
          <a href="/pricing" className="pricing-link">Learn More about Pricing &gt;&gt;</a>
          <img src={ipadPatients} className="macbookPricing" alt="MacBook displaying pricing information" />
        </div>


      <h2 className='section-title'>
        We Make Providers Powerful
      </h2>
      <div className="ui-grid-container">
        {cards.map((card, index) => {
          const isLeftColumn = index % 2 === 0; // Left column if index is even
          const offsetClass = isLeftColumn ? 'offset-left' : 'offset-right';
          return (
            <div
              className={`ui-grid-card ${card.cardClass} ${offsetClass}`}
              key={index}
            >
              <div className="ui-grid-card-image-wrapper">
                <div className="ui-grid-card-gradient"></div>
                <img src={card.image} alt={card.title} className={`ui-grid-card-image ${card.imageClass}`} />
              </div>
              <div className="ui-grid-card-content">
                <h3 className="ui-grid-card-title">{card.title}</h3>
                <p className="ui-grid-card-description">{card.description}</p>
              </div>
              {/* 'Learn more' link, initially hidden, shown on hover */}
              <div className="ui-grid-card-hover-link">
                <a href={card.link} className="ui-grid-card-link">
                  <strong>{card.buttonText}</strong> &gt;
                </a>
              </div>
            </div>
          );
        })}
      </div>
  
    </section>
  );
};

export default UiGrid;
