import React from 'react';
import '../guides/APIGuides.css';

const EncountersGuide = () => {
    return (
        <div className="guide-page">
            <h1>Getting Started with the Encounters API</h1>
            <p>This guide explains how to integrate your application with the Encounters API, including utilizing custom mappings to fit your unique workflows.</p>

            <h2>Overview</h2>
            <p>The Encounters API allows you to send detailed patient and encounter data to external systems. By using custom mappings, you can easily adapt the payload to match the requirements of third-party APIs or internal systems.</p>

            <h2>Available Data Fields</h2>
            <p>You can map the following fields from the incoming payload using dot notation (e.g., <code>patient.firstName</code>):</p>
            <ul>
                <li><strong>Patient Information:</strong>
                    <ul>
                        <li><strong>firstName:</strong> Patient's first name</li>
                        <li><strong>lastName:</strong> Patient's last name</li>
                        <li><strong>dob:</strong> Patient's date of birth (formatted as YYYY-MM-DD)</li>
                        <li><strong>gender:</strong> Patient's gender</li>
                        <li><strong>address1:</strong> Primary address</li>
                        <li><strong>city:</strong> City</li>
                        <li><strong>state:</strong> State</li>
                        <li><strong>zip:</strong> ZIP code</li>
                        <li><strong>memberId:</strong> Insurance member ID</li>
                        <li><strong>payerName:</strong> Insurance provider name</li>
                    </ul>
                </li>
                <li><strong>Encounter Information:</strong>
                    <ul>
                        <li><strong>dateOfService:</strong> Date of service</li>
                        <li><strong>reasonForVisit:</strong> Reason for the visit</li>
                        <li><strong>service:</strong> Service provided</li>
                        <li><strong>encounterType:</strong> Type of encounter (e.g., Emergency Room Visit)</li>
                        <li><strong>controlNumber:</strong> Encounter control number</li>
                        <li><strong>notes:</strong> Notes, including comments from form inputs</li>
                        <li><strong>billingCodes:</strong> Array of billing codes with details such as:
                            <ul>
                                <li><strong>name:</strong> Name of the billing code</li>
                                <li><strong>code:</strong> Billing code</li>
                                <li><strong>diagnosisCode:</strong> Diagnosis codes associated with the billing code</li>
                                <li><strong>billAmount:</strong> Amount billed</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li><strong>Provider Information:</strong>
                    <ul>
                        <li><strong>organizationName:</strong> Provider's organization name</li>
                        <li><strong>npi:</strong> National Provider Identifier</li>
                        <li><strong>providerFirstName:</strong> Provider's first name</li>
                        <li><strong>providerLastName:</strong> Provider's last name</li>
                        <li><strong>address1:</strong> Primary address</li>
                        <li><strong>city:</strong> City</li>
                        <li><strong>state:</strong> State</li>
                        <li><strong>zip:</strong> ZIP code</li>
                    </ul>
                </li>
            </ul>

            <h2>Defining Custom Mappings</h2>
            <p>To create custom mappings for your data, follow these steps:</p>
            <ol>
                <li>Navigate to the API Mappings section of your dashboard.</li>
                <li>Use the mapping editor to define your custom mappings. Here's an example:</li>
            </ol>
            <pre>
                {`
                {
                    "patient": {
                        "firstName": "patient.firstName",
                        "lastName": "patient.lastName",
                        "dob": "patient.dob",
                        "gender": "patient.gender"
                    },
                    "encounter": {
                        "dateOfService": "encounter.dateOfService",
                        "reasonForVisit": "encounter.reasonForVisit",
                        "notes": "encounter.notes"
                    },
                    "provider": {
                        "organizationName": "provider.organizationName",
                        "npi": "provider.npi",
                        "providerName": "provider.providerFirstName + ' ' + provider.providerLastName"
                    }
                }
                `}
            </pre>
            <ol start="3">
                <li>Save your mappings and test them using the provided testing tool.</li>
            </ol>

            <h2>Testing Custom Mappings</h2>
            <p>Use the following payload to test your mappings:</p>
            <pre>
                {`
                {
                    "patient": {
                        "firstName": "John",
                        "lastName": "Doe",
                        "dob": "1980-01-01",
                        "gender": "M",
                        "address1": "123 Main St",
                        "city": "Seattle",
                        "state": "WA",
                        "zip": "98101"
                    },
                    "encounter": {
                        "dateOfService": "2025-01-10",
                        "reasonForVisit": "Routine checkup",
                        "notes": "Patient in good health."
                    },
                    "provider": {
                        "organizationName": "Health Clinic",
                        "npi": "1234567890",
                        "providerFirstName": "Jane",
                        "providerLastName": "Smith"
                    }
                }
                `}
            </pre>

            <h2>Expected Response</h2>
            <p>The transformed response will look like this:</p>
            <pre>
                {`
                {
                    "patient": {
                        "firstName": "John",
                        "lastName": "Doe",
                        "dob": "1980-01-01",
                        "gender": "M",
                        "age": 45,
                        "address1": "123 Main St",
                        "city": "Seattle",
                        "state": "WA",
                        "zip": "98101",
                        "memberId": "1019971233",
                        "payerName": "Aetna"
                    },
                    "encounter": {
                        "dateOfService": "2025-01-10",
                        "reasonForVisit": "Routine checkup",
                        "notes": "Patient in good health."
                    },
                    "provider": {
                        "organizationName": "Health Clinic",
                        "npi": "1234567890",
                        "providerName": "Jane Smith"
                    }
                }
                `}
            </pre>

            <h2>Additional Resources</h2>
            <ul>
                <li>
                    <a href="/tryapi/guides/custom-api-mappings" className="guide-link">
                        Customizing API Mappings
                    </a>
                </li>
                <li>
                    <a href="/tryapi/guides/lab-results-api" className="guide-link">
                        Processing Lab Test Results
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default EncountersGuide;
