import '../guides/APIGuides.css';

export const receiveTestDataApiSpecification = {
  "openapi": "3.0.3",
  "info": {
    "title": "API Message Processor",
    "description": "A simple API for processing incoming messages with preloaded JSON for testing.",
    "version": "1.0.0"
  },
  "servers": [
    {
      "url": "https://receivetestdata-lqsbsnhsqq-uc.a.run.app",
      "description": "Testing Endpoint"
    }
  ],
  "paths": {
    "/process-message": {
      "post": {
        "summary": "Process an incoming message",
        "description": "Accepts a JSON payload and processes it according to the specified mappings.",
        "requestBody": {
          "required": true,
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "data": {
                    "type": "object",
                    "properties": {
                      "metadata": {
                        "type": "object",
                        "properties": {
                          "senderID": { "type": "string", "example": "123-abc" },
                          "patientID": { "type": "string", "example": "456-def" }
                        }
                      },
                      "order": {
                        "type": "object",
                        "properties": {
                          "accessionNumber": { "type": "string", "example": "789-ghj" },
                          "recordNumber": { "type": "string", "example": "record-001" }
                        }
                      },
                      "result": {
                        "type": "object",
                        "properties": {
                          "status": { "type": "string", "example": "Completed" },
                          "date": { "type": "string", "format": "date-time", "example": "2023-01-01T12:00:00Z" }
                        }
                      },
                      "attachments": {
                        "type": "object",
                        "properties": {
                          "reports": {
                            "type": "array",
                            "items": {
                              "type": "object",
                              "properties": {
                                "source": { "type": "string", "example": "base64" },
                                "type": { "type": "string", "example": "Lab Result" },
                                "name": { "type": "string", "example": "test-result.pdf" },
                                "data": { "type": "string", "example": "JVBERi0xLjQKJ... (truncated)" }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "example": {
                  "data": {
                    "metadata": {
                      "senderID": "123-abc",
                      "patientID": "456-def"
                    },
                    "order": {
                      "accessionNumber": "789-ghj",
                      "recordNumber": "record-001"
                    },
                    "result": {
                      "status": "Completed",
                      "date": "2023-01-01T12:00:00Z"
                    },
                    "attachments": {
                      "reports": [
                        {
                          "source": "base64",
                          "type": "Lab Result",
                          "name": "test-result.pdf",
                          "data": "JVBERi0xLjQKJ... (truncated)"
                        }
                      ]
                    }
                  }
                }
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "Message processed successfully.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "message": { "type": "string", "example": "Message processed successfully." },
                    "data": {
                      "type": "object",
                      "description": "Processed data details",
                      "example": {
                        "recordID": "abcdef123456",
                        "timestamp": "2023-01-01T12:00:00Z"
                      }
                    }
                  }
                }
              }
            }
          },
          "400": {
            "description": "Invalid input or missing fields.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": { "type": "string", "example": "Invalid input data." },
                    "details": { "type": "string", "example": "Missing required field: senderID" }
                  }
                }
              }
            }
          },
          "500": {
            "description": "Server error while processing the message.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": { "type": "string", "example": "An unexpected error occurred." },
                    "details": { "type": "string", "example": "Internal server error." }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
