import React from 'react';
import { Link } from 'react-router-dom';
import daisyPic from '../General/Dogs/daisy.png'; // Adjust the path as necessary
import Footer from '../General/Home/Footer';
import '../Auth/AUTH.css';

const NotFoundPage = () => (
  <div className="background">
    <div className="forgot-password-container auth-margin-top">
      <div className="dogs-side-by-side">
        <div className="left-content">
          <h1 style={{textAlign: "left", marginBottom: "2rem"}}  className='typography-headline'>404 - Page Not Found</h1>
          <p style={{textAlign: "left", marginBottom: "2rem"}} className='typography-label-alt-dark'>We're sorry, the page you are looking for cannot be found.</p>
          <p><Link to="/" className="signin-btn">Go back home</Link></p>
        </div>
        <div style={{ marginRight: "5rem"}} className="right-content">
            <div className='dogs-side-by-side'>
          <img src={daisyPic} alt="Daisy the Dog" className="dog-image" />
          <div className="dog-text">
            <h2 className="dog-h2">Daisy</h2>
            <p className='typography-label-alt-dark'>Dogs of Popularis</p>
          </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default NotFoundPage;
