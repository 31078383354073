import React, { useState, useEffect } from "react";
import styles from "../PatientFiles.modules.css";
import "firebase/functions";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  doc,
  getDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import useUID from "../../General/useUID";
import { auth, db } from "../../../firebase";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import HelpArticleLink from "../../Articles/HelpArticleLink";

function Zoom({ email, phone, onClose, patientId }) {
  const [patientEmail, setPatientEmail] = useState(email);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [duration, setDuration] = useState("");
  const [providerEmail, setProviderEmail] = useState("");
  const [meetingDetails, setMeetingDetails] = useState(null);
  const [uid, subUserUID] = useUID();
  const [isScheduling, setIsScheduling] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchEmail = async () => {
      if (uid) {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setProviderEmail(docSnap.data().email);
        }
      }
    };

    fetchEmail();
  }, [uid]);

  const functions = getFunctions();
  const scheduleZoomCall = httpsCallable(functions, "scheduleZoomCall");

  const validateInputs = () => {
    const newErrors = {};
    if (!providerEmail) newErrors.providerEmail = "Provider email is required.";
    if (!patientEmail) newErrors.patientEmail = "Patient email is required.";
    if (!date) newErrors.date = "Date is required.";
    if (!time) newErrors.time = "Time is required.";
    if (!duration) newErrors.duration = "Duration is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleScheduleCall = async () => {
    if (!validateInputs()) return;

    setIsScheduling(true);
    try {
      const dateTime = new Date(date + "T" + time);
      const response = await scheduleZoomCall({
        patientEmail: patientEmail,
        providerEmail: providerEmail,
        dateTime: dateTime.toISOString(),
        duration: duration,
      });

      const logData = {
        timestamp: Date.now(),
        patientId: patientId,
        activity: "Zoom call scheduled",
        activityType: "zoomScheduled",
        uid: uid,
      };

      await addDoc(collection(db, "users", uid, "activityLogs"), logData);

      if (response.data.success) {
        setMeetingDetails(response.data.meeting);
      } else {
        console.error(
          "Error scheduling Zoom call:",
          JSON.stringify(response.data.error)
        );
        setErrors({ form: "Error scheduling Zoom call: " + JSON.stringify(response.data.error) });
      }
    } catch (error) {
      console.error("Error scheduling Zoom call:", JSON.stringify(error));
      setErrors({ form: "Error scheduling Zoom call: " + JSON.stringify(error) });
    }
    setIsScheduling(false);
  };

  return (
    <>  
      <div className="closeButtonContainer">
      <button onClick={onClose} className="filesCloseButton">
        X
      </button>
      <p className="closeBarNav">Schedule a Zoom Call</p>
    </div>
    <div className="notesContainer">
      <div className={styles.zoomcontent}>
        {isScheduling ? (
          <div>
            <HexagonSpinner />
            <p>Scheduling your Zoom call...</p>
          </div>
        ) : meetingDetails ? (
          <div>
            <h2>Zoom Call Scheduled</h2>
            <p>Meeting ID: {meetingDetails.id}</p>
            <p>Start Time: {new Date(meetingDetails.start_time).toLocaleString()}</p>
            <p>
              <a href={meetingDetails.join_url} target="_blank" rel="noopener noreferrer">
                Join Meeting
              </a>
            </p>
            <button onClick={() => navigator.clipboard.writeText(meetingDetails.join_url)}>
              Copy Meeting Link
            </button>
            <button onClick={onClose} className="filesCloseButton">X</button>
          </div>
        ) : (
          <div>
            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor="provideremail">Provider Email:</label>
                <input
                  type="email"
                  value={providerEmail}
                  onChange={(e) => setProviderEmail(e.target.value)}
                  autoComplete="email"
                />
                {errors.providerEmail && <p className="error">{errors.providerEmail}</p>}
              </div>
              <div className="input-field">
                <label htmlFor="patientemail">Patient Email:</label>
                <input
                  type="email"
                  value={patientEmail}
                  onChange={(e) => setPatientEmail(e.target.value)}
                  placeholder={patientEmail}
                  autoComplete="email"
                />
                {errors.patientEmail && <p className="error">{errors.patientEmail}</p>}
              </div>
            </div>
            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor="date">Date:</label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  placeholder="Select a date"
                />
                {errors.date && <p className="error">{errors.date}</p>}
              </div>
              <div className="input-field">
                <label htmlFor="time">Time:</label>
                <input
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  placeholder="Select a time"
                />
                {errors.time && <p className="error">{errors.time}</p>}
              </div>
            </div>
            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor="duration">Duration:</label>
                <select onChange={(e) => setDuration(e.target.value)}>
                  <option value="" disabled selected>
                    Select duration
                  </option>
                  <option value="15">15 minutes</option>
                  <option value="30">30 minutes</option>
                  <option value="45">45 minutes</option>
                  <option value="60">60 minutes</option>
                </select>
                {errors.duration && <p className="error">{errors.duration}</p>}
              </div>
            </div>
            {errors.form && <p className="error">{errors.form}</p>}
            <div className="content-center mt-3">
              <button onClick={handleScheduleCall} className="schedule-call-button">
                Schedule Call
              </button>
            </div>
            <div className="help-article-wide">
              <HelpArticleLink
                article={{
                  title: "How to Schedule Zoom Call",
                  link: "https://popularishealth.com/article/How-to-Schedule-a-Zoom-Call"
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
   </>

  );
}

export default Zoom;
