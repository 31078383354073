import React from 'react';
import smallLogo from '../../popularis_logos/Popularis_logo_single.png';
import { minWidth } from '@mui/system';

const SmallLogo = () => {
  const styles = {
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logo: {
      width: '7vw',
      minWidth: '4rem',
      height: 'auto',
      marginBottom: '1rem',
      animation: 'rotateTo30deg 2s forwards',
    },
  };

  return (
    <div style={styles.logoContainer}>
      <img src={smallLogo} alt="Logo" style={styles.logo} />
    </div>
  );
};

// CSS for the animation
const css = `
@keyframes rotateTo30deg {
  from {
    transform: rotate(30deg);
  }
  to {
    transform: rotate(0deg);
  }
}
`;

const style = document.createElement('style');
style.textContent = css;
document.head.append(style);

export default SmallLogo;
