import React from "react";
import IntakeSearch from "./IntakeSearch";
import IntakeEligibility from "./IntakeEligibility";
import IntakeDownload from "./IntakeDownload";
import IntakePrint from "./IntakePrint";
import { FaSyncAlt } from "react-icons/fa";

const IntakeToolbar = ({
  selectAll,
  setSelectAll,
  onRefresh,
  selectedPatients,
  patients,
  onFilter,
  clinicToken,
  loading // <-- New prop for toolbar spinner
}) => {
  return (
    <div className="intakeview-toolbar">
      <div className="intakeview-toolbar-left">
        {loading ? (
          // If loading, show spinner instead of checkbox + refresh button
          <div className="small-spinner">
            <div className="small-spinner-circle"></div>
          </div>
        ) : (
          <>
            <input
              type="checkbox"
              className="intake-checkbox"
              checked={selectAll}
              onChange={(e) => setSelectAll(e.target.checked)}
              title="Select All"
            />
            <button onClick={onRefresh} title="Refresh" className="intakeview-refresh-button">
              <FaSyncAlt />
            </button>
          </>
        )}
      </div>
      <div className="intakeview-toolbar-center">
        <IntakeSearch patients={patients} onFilter={onFilter} />
      </div>
      <div className="intakeview-toolbar-right">
        {selectedPatients.length > 0 && (
          <>
            <IntakeEligibility />
            <IntakeDownload
              selectedPatients={selectedPatients}
              patients={patients}
              clinicId={clinicToken}
            />
            <IntakePrint />
          </>
        )}
      </div>
    </div>
  );
};

export default IntakeToolbar;
