import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const ProtectedRoute = ({ children, allowNoSubscription = false }) => {
  const [authUser, setAuthUser] = useState(null);
  const [is2FAVerified, setIs2FAVerified] = useState(true); // Default to true for debugging
  const [hasSubscription, setHasSubscription] = useState(true); // Default to true for debugging
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthUser(user || null);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);


  if (isLoading) {
    return <div></div>;
  }

  if (!authUser) {
    return <Navigate to="/signin" />;
  }

  if (!is2FAVerified) {
    return <Navigate to="/signin" />;
  }

  if (allowNoSubscription === false && !hasSubscription) {
    return <Navigate to="/home" />;
  }

  return children;
};

export default ProtectedRoute;
