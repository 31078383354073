import React from "react";
import { FaPrint } from "react-icons/fa";

const IntakePrint = () => {
  const handlePrint = () => {
    console.log("Print button clicked");
    alert("This feature is still under construction.");
  };

  return (
    <button onClick={handlePrint} title="Print">
      <FaPrint />
    </button>
  );
};

export default IntakePrint;
