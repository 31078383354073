import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import Modal from "react-modal";
import { db } from "../../../firebase";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";
import BatchClaimsJSON from "./BatchClaimsJSON"; // Import the BatchClaimsJSON component

const BillableEncounters = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [encounters, setEncounters] = useState([]);
  const [filteredEncounters, setFilteredEncounters] = useState([]); // State for filtered encounters
  const [showBatchClaimsJSON, setShowBatchClaimsJSON] = useState(false); // State to manage overlay visibility
  const [showCheckboxes, setShowCheckboxes] = useState(false); // State to show checkboxes
  const [selectedEncounters, setSelectedEncounters] = useState([]); // State to manage selected encounters
  const [selectedPatientsData, setSelectedPatientsData] = useState([]); // State to manage selected patients' data
  const [errors, setErrors] = useState({}); // State to manage validation errors
  const [uid] = useUID();
  const [selectAll, setSelectAll] = useState(false); // State for Select All functionality
  const [loadedCount, setLoadedCount] = useState(0); // State to manage number of loaded encounters
  const [showFilterModal, setShowFilterModal] = useState(false); // State to manage filter modal visibility
  const [fromDate, setFromDate] = useState(""); // State for start date filter
  const [toDate, setToDate] = useState("");   // State for end date filter
  const [filterBilled, setFilterBilled] = useState(""); // State for billed filter
  const [filterDuplicates, setFilterDuplicates] = useState(false); // State for duplicates filter

  const functions = getFunctions();
  const batchDecryptEncounters = httpsCallable(functions, "batchDecryptEncounters");

  /**
   * Safely formats a date-like value as MM/DD/YYYY.
   */
  function formatDate(dateValue) {
    if (!dateValue) {
      return "Unknown date";
    }

    let dateObj;
    // Firestore Timestamp?
    if (dateValue.toDate) {
      dateObj = dateValue.toDate();
    }
    // Already a Date object?
    else if (dateValue instanceof Date) {
      dateObj = dateValue;
    }
    // Possibly a string?
    else {
      dateObj = new Date(dateValue);
    }

    if (isNaN(dateObj.getTime())) {
      // If invalid date
      return "Unknown date";
    }

    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day   = ("0" + dateObj.getDate()).slice(-2);
    const year  = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  }

  /**
   * Load encounters from Firestore, but do NOT rely on "timestamp".
   * We'll let the decrypted data have `dateOfService`.
   */
  const loadMoreEncounters = async () => {
    if (!uid) return;

    setLoading(true);

    const patientDataCollectionRef = collection(db, `patients/${uid}/patientData`);
    const patientDocs = await getDocs(patientDataCollectionRef);

    const patients = [];
    const rawEncounters = [];

    // Collect patients + encounters
    await Promise.all(
      patientDocs.docs.map(async (patientDoc) => {
        const patientData = patientDoc.data();

        // We'll only push the encrypted fields you need
        patients.push({
          id: patientDoc.id, // patient ID
          ciphertext: patientData.patient.ciphertext,
          iv: patientData.patient.iv,
        });

        const encountersRef = collection(patientDoc.ref, "encounters");
        const encounterDocs = await getDocs(encountersRef);

        encounterDocs.docs.forEach((encounterDoc) => {
          const encounterData = encounterDoc.data();
          rawEncounters.push({
            id: encounterDoc.id,
            patientId: patientDoc.id,
            ciphertext: encounterData.ciphertext,
            iv: encounterData.iv,
            BillingStatus: encounterData.BillingStatus,
            // We remove "timestamp" references to focus on dateOfService
          });
        });
      })
    );

    // Decrypt in batch
    const decryptedResponse = await batchDecryptEncounters({
      patients,
      encounters: rawEncounters,
    });

    const decryptedPatients = decryptedResponse.data.decryptedPatients;
    const decryptedEncounters = decryptedResponse.data.decryptedEncounters;

    console.log("Decrypted Patients:", decryptedPatients);
console.log("Decrypted Encounters:", decryptedEncounters);


    // Map patient IDs -> decrypted patient data
    const patientIdToPatientData = {};
    decryptedPatients.forEach((decryptedPatient) => {
      if (decryptedPatient) {
        patientIdToPatientData[decryptedPatient.id] = decryptedPatient.data;
      }
    });

    // Combine decrypted encounters with their corresponding patients
    const combinedData = decryptedEncounters.map((encObj) => {
      if (!encObj) return null;

      const {
        id: encounterId,
        patientId,
        data: decryptedEncounterData,
        BillingStatus,
      } = encObj;

      const patient = patientIdToPatientData[patientId];
      if (!patient || !decryptedEncounterData) {
        console.warn(`Missing data for encounter ID: ${encounterId}`);
        return null;
      }

      // Pull out the dateOfService from the decrypted data
      const dateOfService = decryptedEncounterData.dateOfService;

      return {
        ...decryptedEncounterData,
        // Display name
        patientName: `${patient.patient?.firstName || ""} ${patient.patient?.middleName || ""} ${patient.patient?.lastName || ""}`.trim(),
        // Format dateOfService into string for table
        date: formatDate(dateOfService),

        diagnosisCodes: formatDiagnosisCode(decryptedEncounterData.diagnosisCode || []),
        id: encounterId,
        isBilled: BillingStatus === true,

        // These are for further exporting or claims processing
        patient: patient.patient || {},
        payers: patient.payers || [],

        selectedBillingCodes: [],
      };
    });

    // Filter out nulls
    const filteredData = combinedData.filter((item) => item !== null);

    // Update local state
    setEncounters((prev) => [...prev, ...filteredData]);
    setLoading(false);
  };

  // Fetch on mount
  useEffect(() => {
    loadMoreEncounters();
  }, [uid]);

  // Re-apply filters
  useEffect(() => {
    applyFilters();
  }, [encounters, fromDate, toDate, filterBilled, filterDuplicates]);

  /**
   * Format diagnosis code array
   */
  function formatDiagnosisCode(diagnosisCode) {
    if (Array.isArray(diagnosisCode)) {
      const formattedCodes = diagnosisCode
        .map((dc) => `${dc.code} - ${dc.short_description}`)
        .join(", ");
      return formattedCodes;
    } else if (typeof diagnosisCode === "string") {
      return diagnosisCode;
    }
    return "Not available";
  }

  /**
   * Handle "Send Claims" -> shows checkboxes
   */
  const handleSendClaimsClick = () => {
    setShowCheckboxes(true);
  };

  /**
   * Each row checkbox
   */
  const handleCheckboxChange = (encounterId) => {
    setSelectedEncounters((prev) =>
      prev.includes(encounterId)
        ? prev.filter((id) => id !== encounterId)
        : [...prev, encounterId]
    );
  };

  /**
   * "Select All" check
   */
  const handleSelectAllChange = async () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // If we want to ensure all possible encounters are loaded:
      while (loadedCount < encounters.length) {
        await loadMoreEncounters();
      }
      const allEncounterIds = encounters
        .filter((encounter) => !encounter.isBilled)
        .map((enc) => enc.id);
      setSelectedEncounters(allEncounterIds);
    } else {
      setSelectedEncounters([]);
    }
  };

  /**
   * Validate selected patients before continuing
   */
  const validateSelectedPatients = () => {
    let errors = {};

    selectedPatientsData.forEach((patientWrapper, index) => {
      const patientErrors = [];
      const patientData = patientWrapper?.patient;
      const payerData = patientWrapper?.payers;
      const { address } = patientData || {};

      if (!address || !address.address1) {
        patientErrors.push("Address is missing.");
      }

      const memberId = patientData?.memberId || payerData?.memberId;
      if (!memberId) {
        patientErrors.push("Member ID is missing.");
      }

      if (!payerData || !payerData.name) {
        patientErrors.push("Payer information is missing.");
      }

      if (patientErrors.length) {
        errors[index] = patientErrors;
      }
    });

    return errors;
  };

  /**
   * Continue -> gather selected data -> open JSON overlay
   */
  const handleContinueClick = async () => {
    const selectedData = encounters.filter((enc) =>
      selectedEncounters.includes(enc.id)
    );
  
    // Await the resolved value
    const preparedPatientsData = await preparePatientBatch(selectedData);
    console.log("Prepared Patients Data:", preparedPatientsData);
  
    setSelectedPatientsData(preparedPatientsData);
  
    const validationErrors = validateSelectedPatients();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setShowBatchClaimsJSON(true);
    }
  };
  
  /**
   * Convert an existing date or date-string to MM/DD/YYYY
   */
  const formatDateToMMDDYYYY = (raw) => {
    if (!raw) return "";
    let dateObj = new Date(raw);
    if (isNaN(dateObj.getTime())) return "";

    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day   = ("0" + dateObj.getDate()).slice(-2);
    const year  = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  };

  /**
   * Prepare data in the shape that BatchClaimsJSON expects
   */
  const preparePatientBatch = async (selectedData) => {
  const updatedData = await Promise.all(
    selectedData.map(async (enc) => {
      const patientWrapper = enc.patient;
      const payerData = enc.payers;
      let tradingPartnerId = payerData?.tradingPartnerId || payerData?.CPID;

      if (!tradingPartnerId) {
        // Lookup CPID in the Payers database if missing
        tradingPartnerId = await fetchTradingPartnerId(payerData?.name);
      }

      const memberId = patientWrapper.memberId || payerData?.memberId;
      const {
        firstName,
        middleName,
        lastName,
        dob,
        gender,
        address,
      } = patientWrapper ?? {};

      const patientId = enc.id ?? {};
      const dos = formatDateToMMDDYYYY(enc.dateOfService); // or `enc.date` if preferred
      const dobFormatted = formatDateToMMDDYYYY(dob);

      return {
        patient: {
          firstName: firstName || "",
          middleName: middleName || "",
          lastName: lastName || "",
          dob: dobFormatted || "",
          gender: gender || "",
          address: {
            address1: address?.address1 || "",
            address2: address?.address2 || "",
            city: address?.city || "",
            state: address?.state || "",
            zip: address?.zip || "",
          },
        },
        payers: {
          name: payerData?.name || "",
          tradingPartnerId: tradingPartnerId || "Not Found",
          memberId: memberId || "",
          secondaryName: payerData?.secondaryPayer?.name || "",
          secondaryMemberId: payerData?.secondaryPayer?.memberId || "",
        },
        dos: dos || "",
        selectedBillingCodes: enc.billingCode || [],
        id: patientId || "",
      };
    })
  );

  return updatedData;
};
  /**
   * Update selected patients if user modifies them in the JSON modal
   */
  const handleUpdatePatientsData = (newData) => {
    setSelectedPatientsData(newData);
  };

  /**
   * Download CSV
   */
  const downloadCSV = () => {
    const headers = [
      "firstName", "middleName", "lastName", "dateOfBirth", "gender",
      "address1", "address2", "city", "state", "zip",
      "payer", "memberId", "secondaryPayer", "secondaryMemberId",
      "dateOfService", "dateOfServiceTo",
      "diagnosisCode1", "diagnosisCode2", "diagnosisCode3",
      "billingCode1", "billingCode2", "billingCode3",
      "chargeAmount1", "chargeAmount2", "chargeAmount3",
      "priorAuthorizationNumber", "resubmitClaimControlNumber",
    ];

    const dataForCSV = filteredEncounters.map((enc) => {
      // In your real code, ensure `enc.billingCode` is an array
      const diagnosisCodes = enc.billingCode
        ? enc.billingCode.reduce(
            (acc, code) => [...acc, ...code.diagnosisCode],
            []
          )
        : [];
      const billingCodes = enc.billingCode?.map((code) => code.code) || [];
      const chargeAmounts = enc.billingCode?.map((code) => code.billAmount) || [];

      return {
        firstName: enc.patient.firstName || "",
        middleName: enc.patient.middleName || "",
        lastName: enc.patient.lastName || "",
        dateOfBirth: formatDateToMMDDYYYY(enc.patient.dob) || "",
        gender: enc.patient.gender || "",
        address1: enc.patient.address.address1 || "",
        address2: enc.patient.address.address2 || "",
        city: enc.patient.address.city || "",
        state: enc.patient.address.state || "",
        zip: enc.patient.address.zip || "",
        payer: enc.payers.name || "",
        memberId: enc.payers.memberId || "",
        secondaryPayer: enc.payers.secondaryName || "",
        secondaryMemberId: enc.payers.secondaryMemberId || "",
        dateOfService: formatDateToMMDDYYYY(enc.date),
        dateOfServiceTo: "",
        diagnosisCode1: diagnosisCodes[0] || "",
        diagnosisCode2: diagnosisCodes[1] || "",
        diagnosisCode3: diagnosisCodes[2] || "",
        billingCode1: billingCodes[0] || "",
        billingCode2: billingCodes[1] || "",
        billingCode3: billingCodes[2] || "",
        chargeAmount1: chargeAmounts[0] || "",
        chargeAmount2: chargeAmounts[1] || "",
        chargeAmount3: chargeAmounts[2] || "",
        priorAuthorizationNumber: "",
        resubmitClaimControlNumber: "",
      };
    });

    const csvContent =
      [headers.join(",")].concat(
        dataForCSV.map((row) => headers.map((h) => row[h]).join(","))
      ).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "billable_encounters.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * Apply filter logic
   */
  const applyFilters = () => {
    let filtered = encounters;

    if (fromDate) {
      const fromDateMs = new Date(fromDate).getTime();
      filtered = filtered.filter(
        (enc) => new Date(enc.date).getTime() >= fromDateMs
      );
    }

    if (toDate) {
      const toDateMs = new Date(toDate).getTime();
      filtered = filtered.filter(
        (enc) => new Date(enc.date).getTime() <= toDateMs
      );
    }

    if (filterBilled) {
      const isBilled = filterBilled === "true";
      filtered = filtered.filter((enc) => enc.isBilled === isBilled);
    }

    if (filterDuplicates) {
      const uniqueEncounters = new Map();
      filtered.forEach((enc) => {
        // Make a key out of provider + codes + date + controlNumber, etc.
        const key = `${enc.providerfirstName}-${enc.providerlastName}-` +
                    `${enc.billingCode?.map((c) => c.code).join(",")}-` +
                    `${enc.date}-${enc.controlNumber}`;
        if (!uniqueEncounters.has(key)) {
          uniqueEncounters.set(key, enc);
        }
      });
      filtered = Array.from(uniqueEncounters.values());
    }

    // Sort descending by the date string
    filtered.sort((a, b) => new Date(b.date) - new Date(a.date));

    setFilteredEncounters(filtered);
    setShowFilterModal(false);
  };

  /**
   * Clear filters
   */
  const clearFilters = () => {
    setFromDate("");
    setToDate("");
    setFilterBilled("");
    setFilterDuplicates(false);
    setFilteredEncounters(encounters);
    setShowFilterModal(false);
  };

  const fetchTradingPartnerId = async (payerName) => {
    try {
      const payerQuery = query(
        collection(db, "Payers"),
        where("payerName", "==", payerName)
      );
      const querySnapshot = await getDocs(payerQuery);
  
      if (!querySnapshot.empty) {
        const payerData = querySnapshot.docs[0].data();
        return payerData?.CPID || null; // Return CPID if available
      }
    } catch (error) {
      console.error(`Error fetching tradingPartnerId for ${payerName}:`, error);
    }
    return null; // Return null if not found
  };

  

  return (
    <div className="calendarContainer">
      <div className="centerHeader">
        <h3>Billable Encounters</h3>
        <p>Encounters created in Patients are shown here for Billing.</p>
      </div>

      <button onClick={() => setShowFilterModal(true)}>Add Filters</button>

      {!loading && filteredEncounters.length > 0 && (
        <button onClick={downloadCSV}>Download CSV</button>
      )}

      {loading ? (
        <>
          <HexagonSpinner />
          <p className="authMessage">Decrypting latest Encounters...</p>
        </>
      ) : (
        <>
          {showCheckboxes ? (
            <button onClick={handleContinueClick}>Continue</button>
          ) : (
            <button onClick={handleSendClaimsClick}>Send Claims</button>
          )}

          <table>
            <thead>
              <tr>
                {showCheckboxes && (
                  <th>
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                      title="Select All"
                    />
                  </th>
                )}
                <th>Date of Service</th>
                <th>Patient</th>
                <th>Service Line 1</th>
                <th>Provider</th>
                <th>Encounter Type</th>
                <th>Billing Status</th>
              </tr>
            </thead>

            <tbody>
              {filteredEncounters.map((encounter, index) => (
                <tr key={index}>
                  {showCheckboxes && !encounter.isBilled && (
                    <td>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={selectedEncounters.includes(encounter.id)}
                        onChange={() => handleCheckboxChange(encounter.id)}
                      />
                    </td>
                  )}
                  <td>{encounter.date}</td>
                  <td>{encounter.patientName || "Name not available"}</td>
                  <td>
                    {encounter.billingCode && encounter.billingCode[0]
                      ? encounter.billingCode[0].name
                      : "N/A"}
                  </td>
                  <td>
                    {`${encounter.providerfirstName || "Unknown"} ${
                      encounter.providerlastName || "Provider"
                    }`}
                  </td>
                  <td>{encounter.encounterType}</td>
                  <td>{encounter.isBilled ? "Billed" : "Not Billed"}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {!loading && filteredEncounters.length > loadedCount && (
            <button onClick={loadMoreEncounters}>Load More</button>
          )}
        </>
      )}

      {showBatchClaimsJSON && (
        <div className="overlay">
          <BatchClaimsJSON
            onClose={() => setShowBatchClaimsJSON(false)}
            selectedPatientsData={selectedPatientsData}
            onUpdatePatientsData={handleUpdatePatientsData}
          />
        </div>
      )}

      <Modal
        isOpen={showFilterModal}
        onRequestClose={() => setShowFilterModal(false)}
        contentLabel="Filter Modal"
        className="confirmModal"
      >
        <h2>Add Filters</h2>

        <div className="dateFilters">
          <label htmlFor="fromDate">From Date:</label>
          <input
            type="date"
            id="fromDate"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />

          <label htmlFor="toDate">To Date:</label>
          <input
            type="date"
            id="toDate"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>

        <div>
          <label>Billed:</label>
          <select
            onChange={(e) => setFilterBilled(e.target.value)}
            value={filterBilled}
          >
            <option value="">All</option>
            <option value="true">Billed</option>
            <option value="false">Not Billed</option>
          </select>
        </div>

        <div>
          <label>
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={filterDuplicates}
              onChange={() => setFilterDuplicates(!filterDuplicates)}
            />
            Remove Duplicates
          </label>
        </div>

        <button onClick={applyFilters}>Apply Filters</button>
        <button onClick={clearFilters}>Clear Filters</button>
        <button onClick={() => setShowFilterModal(false)}>Close</button>
      </Modal>
    </div>
  );
};

export default BillableEncounters;
