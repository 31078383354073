import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import en from './locales/en.json';
import bn from './locales/bn.json';
import ar from './locales/ar.json';
import hi from './locales/hi.json';
import ja from './locales/ja.json';
import ru from './locales/ru.json';
import fr from './locales/fr.json';
import pt from './locales/pt.json';
import es from './locales/es.json';
import nl from './locales/nl.json';
import el from './locales/el.json';
import it from './locales/it.json';
import de from './locales/de.json';
import zh from './locales/zh.json';

const resources = {
  en: { translation: en },
  bn: { translation: bn },
  ar: { translation: ar },
  hi: { translation: hi },
  ja: { translation: ja },
  ru: { translation: ru },
  fr: { translation: fr },
  pt: { translation: pt },
  es: { translation: es },
  nl: { translation: nl },
  el: { translation: el },
  it: { translation: it },
  de: { translation: de },
  zh: { translation: zh },
};

i18n
  .use(LanguageDetector) // Detect user's language
  .use(initReactI18next) // Integrate with React
  .init({
    resources,
    fallbackLng: 'en', // Default to English if language not detected
    interpolation: { escapeValue: false }, // React handles escaping
  });

export default i18n;
