import React, { useState } from 'react';
import { auth, db } from "../../../firebase";
import { useEffect } from "react";
import { doc, onSnapshot } from 'firebase/firestore';
import useUID from "../useUID";
import SidebarAPI from "./SidebarAPI";
import Callbacks from "./Callbacks"; // Import the Callbacks component
import APICredentialsButton from './Keys';
import Topbar from "../Topbar";
import ResultsAPI from './Results';

function API() {
  const [user, setUser] = useState(null);
  const [uid, subUserUID, isLoading, error] = useUID();
  const [showCallbacks, setShowCallbacks] = useState(false); // State to control the visibility of Callbacks
  const [showResults, setShowResults] = useState(false); // State to control the visibility of Callbacks

  useEffect(() => {
    if (!uid) return;

    const userRef = doc(db, "users", uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setUser(doc.data());
      }
    });

    return () => unsubscribe();
  }, [uid]);

  if (!user) {
    return <div>Loading...</div>;
  }

  if (user.AccountTier !== 'Enterprise') {
    return <div>Access Denied: This page is only accessible to users with an Enterprise account.</div>;
  }

  const toggleCallbacks = () => {
    setShowCallbacks(!showCallbacks); // Toggle the visibility
  };

  const toggleResults = () => {
    setShowResults(!showResults); // Toggle the visibility
  };
  
  return (
    <div className='background'>
      <Topbar />
      <div className="apiPage">
        <div className="sidebar-container">
          <SidebarAPI onToggleCallbacks={toggleCallbacks} onToggleResults={toggleResults}/>
        </div>
        {showCallbacks && (
          <div className="callbacks-container">
            <Callbacks />
          </div>
        )}
         {showResults && (
          <div className="callbacks-container">
            <ResultsAPI />
          </div>
        )}
        <APICredentialsButton />
      </div>
    </div>
  );
}

export default API;
