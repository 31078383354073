import React from "react";

const IntakeEligibility = () => {
  const handleEligibility = () => {
    console.log("Eligibility button clicked");
    // Display an alert indicating the feature is under construction
    alert("This feature is still under construction.");
  };

  return (
    <button onClick={handleEligibility} title="Check Eligibility" style={{ marginRight: "1rem" }}>
      Eligibility
    </button>
  );
};

export default IntakeEligibility;
