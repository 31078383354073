import React, { useState } from "react";
import Switch from "react-switch";

const InputTypeChoice = ({
  fieldItem,
  isVisible = true,
  preview = true,
  handleInputChange,
  formElementId,
  handleOptionChange,
  formElementIndex,
  fieldIndex,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    fieldItem.value ? fieldItem.value.split(", ").filter(Boolean) : []
  );

  const handleOptionClick = (option) => {
    handleOptionChange(formElementIndex, fieldIndex, option);
    setSelectedOptions((prevSelectedOptions) => {
      if (fieldItem.multiple) {
        if (prevSelectedOptions.includes(option)) {
          return prevSelectedOptions.filter((opt) => opt !== option);
        } else {
          return [...prevSelectedOptions, option];
        }
      } else {
        if (prevSelectedOptions.includes(option)) {
          return [];
        } else {
          return [option];
        }
      }
    });
  };

  const handleSelectAll = () => {
    if (fieldItem.multiple) {
      const allOptions = fieldItem.options || [];
      if (selectedOptions.length === allOptions.length) {
        setSelectedOptions([]);
        allOptions.forEach(option => {
          handleOptionChange(formElementIndex, fieldIndex, option);
        });
      } else {
        setSelectedOptions(allOptions);
        allOptions.forEach(option => {
          if (!selectedOptions.includes(option)) {
            handleOptionChange(formElementIndex, fieldIndex, option);
          }
        });
      }
    }
  };

  const allOptionsSelected = fieldItem?.options && 
    selectedOptions.length === fieldItem.options.length;

  return (
    <>
      {isVisible && (
        <div className="ChoiceInputContainer">
          <div className="ChoiceHeader">
            <label className="ChoiceLabel">
              {fieldItem?.label}
              <span className="RequiredMark">
                {fieldItem?.required && " *"}
              </span>
            </label>
          </div>
          <div className="ChoiceOptionsContainer">
            {fieldItem?.multiple && fieldItem?.selectAllButton && (
              <div className="ChoiceOption">
                <Switch
                  disabled={preview}
                  checked={allOptionsSelected}
                  onChange={handleSelectAll}
                  offColor="#ccc"
                  onColor="#00316f"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  width={72}
                  height={36}
                  handleDiameter={32}
                />
                <label className="pt-3 ml-3">Select All</label>
              </div>
            )}
            {Array.isArray(fieldItem?.options) && fieldItem?.options.length > 0 ? (
              fieldItem?.options.map((option, index) => (
                <div key={index} className="ChoiceOption">
                  <Switch
                    disabled={preview}
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleOptionClick(option)}
                    offColor="#ccc"
                    onColor="#00316f"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={72}
                    height={36}
                    handleDiameter={32}
                  />
                  <label className="pt-3 ml-3">{option}</label>
                </div>
              ))
            ) : (
              <div>No options available</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InputTypeChoice;