import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import PatientList from "./PatientList";
import "./PatientFiles.modules.css";
import Topbar from "../General/Topbar";
import PatientView from "./PatientView";
import FolderControls from "./FolderControls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

function PatientFiles() {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("All Patients");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [resetView, setResetView] = useState(false);
  const [checkboxOpen, setCheckboxOpen] = useState(false);

  // State for sidebar visibility and closing animation
  const [sidebarState, setSidebarState] = useState({
    type: null,       // "folder", "patient", or null
    isClosing: false, // whether it's in the process of closing
  });

  const { patientIdfromURL } = useParams();
  const scrollingTableRef = useRef(null);

  // Close the currently open sidebar (if any), then do callback
  const closeOpenSidebar = (callback) => {
    if (sidebarState.type && !sidebarState.isClosing) {
      setSidebarState((prev) => ({ ...prev, isClosing: true }));

      setTimeout(() => {
        setSidebarState({ type: null, isClosing: false });
        if (callback) callback();
      }, 300); // matches CSS animation duration
    } else {
      if (callback) callback();
    }
  };

  const handleSelectPatient = (patient) => {
    if (!checkboxOpen) {
      closeOpenSidebar(() => {
        setSelectedPatient(patient);
        setResetView((prev) => !prev);
      });
    }
  };

  const handleUpdateData = () => {
    setDataUpdated((prev) => !prev);
  };

  // 1) Function: If we get `true` from PatientList, open "folder" sidebar
  const handleOpenFolder = (shouldOpen) => {
    if (shouldOpen) {
      toggleSidebar("folder");
    }
  };

  // Toggle or close sidebars
  const toggleSidebar = (sidebarType) => {
    if (sidebarState.type === sidebarType && !sidebarState.isClosing) {
      setSidebarState((prev) => ({ ...prev, isClosing: true }));
      setTimeout(() => {
        setSidebarState({ type: null, isClosing: false });
      }, 300);
    } else {
      setSidebarState({ type: sidebarType, isClosing: false });
    }
  };

  // Determine appropriate class name based on sidebar state
  const getSidebarClass = (sidebarType) => {
    if (sidebarState.type === sidebarType) {
      return sidebarState.isClosing ? "hidden slide-out" : "visible";
    }
    return "hidden";
  };

  return (
    <div className="background">
      <Topbar />
      <div className="content">
        {/* PatientList Toggle Button */}
        <button
          className={`toggleSidebarBtn patientBtn ${
            sidebarState.type === "patient" && !sidebarState.isClosing
              ? "active"
              : ""
          }`}
          onClick={() => toggleSidebar("patient")}
        >
          <FontAwesomeIcon
            icon={
              sidebarState.type === "patient" && !sidebarState.isClosing
                ? faChevronLeft
                : faChevronRight
            }
          />
        </button>

        <div className="patientWindow">
          <PatientView
            patientSelected={selectedPatient}
            onDataUpdated={handleUpdateData}
            resetView={resetView}
          />
        </div>

        {/* The folder sidebar */}
        <div
          className={`folderControlsContainer fullWidthSideBar ${getSidebarClass(
            "folder"
          )}`}
        >
          <FolderControls
            onFolderChange={setSelectedFolder}
            selectedFolder={selectedFolder}
          />
        </div>

        {/* The patient sidebar */}
        <div
          className={`patientViewBar fullWidthSideBar ${getSidebarClass(
            "patient"
          )}`}
          ref={scrollingTableRef}
        >
          <PatientList
            onSelectPatient={handleSelectPatient}
            selectedFolder={selectedFolder}
            selectedPatient={selectedPatient}
            patientIdfromURL={patientIdfromURL}
            scrollingTableRef={scrollingTableRef}
            DataUpdated={dataUpdated}
            onOpenFolder={handleOpenFolder}
            isCheckboxOpen={setCheckboxOpen}
          />
        </div>
      </div>
    </div>
  );
}

export default PatientFiles;
