import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import useUID from "../../../General/useUID";
import { db } from "../../../../firebase";
import HexagonSpinner from "../../../General/Animations/Hexspinner";

const functions = getFunctions();
const decryptFunction = httpsCallable(functions, "decrypt");

function IntakeView({ patientId, clinicToken, intakeId }) {
  const [formData, setFormData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [uid] = useUID();

  useEffect(() => {
    // Reset form data when dependencies change
    setFormData([]);
  
    if (!uid || !clinicToken) {
      return; // Exit early if required parameters are missing
    }
  
    const fetchIntakeForms = async () => {
      try {
        setIsLoadingData(true);
        let intakeFormsSnapshot;

        if (intakeId) {
          // Search by intake ID first
          const intakeFormRef = collection(db, "clinics", clinicToken, "intakeForms");
          const intakeFormQuery = query(intakeFormRef, where("__name__", "==", intakeId));
          intakeFormsSnapshot = await getDocs(intakeFormQuery);

          if (!intakeFormsSnapshot.empty) {
            console.log("Found intake form by ID.");
          } else {
            console.warn("No intake form found by ID, falling back to patient ID.");
          }
        }

        // Fall back to searching by patient ID if no form found by intake ID
        if (!intakeFormsSnapshot || intakeFormsSnapshot.empty) {
          const intakeFormsRef = collection(db, "clinics", clinicToken, "intakeForms");
          const intakeFormsQuery = query(intakeFormsRef, where("patientId", "==", patientId));
          intakeFormsSnapshot = await getDocs(intakeFormsQuery);
        }

        if (!intakeFormsSnapshot.empty) {
          const intakeFormsData = await Promise.all(
            intakeFormsSnapshot.docs.map(async (docSnap) => {
              const { ciphertext, iv } = docSnap.data();
              if (!ciphertext || !iv) {
                console.warn("Missing encryption fields in intake form:", docSnap.id);
                return [];
              }

              const decryptedResponse = await decryptFunction({ ciphertext, iv });
              return decryptedResponse.data.data || [];
            })
          );

          setFormData(intakeFormsData.filter((data) => data.length > 0)); // Filter out empty forms
        } else {
          console.warn("No intake forms found for the given patient.");
          setFormData([]); // Clear form data if no forms are found
        }
      } catch (error) {
        console.error("Error fetching intake forms:", error);
        setFormData([]); // Ensure form data is cleared on error
      } finally {
        setIsLoadingData(false); // Hide the loading spinner
      }
    };

    fetchIntakeForms();
  }, [patientId, clinicToken, intakeId, uid]);

  return (
    <div className="encountersContainer">
      {formData.length > 0 && (
        <>
          <h3>Intake Form Results</h3>
          <div
            style={{
              height: "1px",
              backgroundColor: "black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>
        </>
      )}
      {isLoadingData ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <HexagonSpinner />
        </div>
      ) : (
        formData.map((form, formIndex) => (
          <div key={formIndex}>
            {form.map((input) => (
              <div key={input.id}>
                {input.fields.map((item, itemIndex) => (
                  <React.Fragment key={itemIndex}>
                    {item.isVisible && (
                      <div>
                        {["text", "phone", "email", "date", "time", "choice", "textarea"].includes(item.type) && (
                          <p>
                            <span style={{ opacity: 0.75 }}>{item.label}:</span>{" "}
                            <span>{item.value}</span>
                          </p>
                        )}
                        {item.type === "signature" && (
                          <div>
                            <p style={{ opacity: 0.75 }}>{item.label}:</p>
                            <img src={item.value} alt="signature" />
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
}

export default IntakeView;
