import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../guides/APIGuides.css';
import Header from '../subcomponents/Header'

// Import guides
import EncountersGuide from '../guides/EncountersGuide';
import LabResultsGuide from '../guides/LabResultsGuide';
import CustomMappingsGuide from '../guides/CustomMappingsGuide';
import AddPatientGuide from '../guides/addPatientGuide';

const GuideViewer = () => {
    const { guide } = useParams();
    const navigate = useNavigate();

    const renderGuide = () => {
        switch (guide) {
            case 'encounters-api':
                return <EncountersGuide />;
            case 'lab-results-api':
                return <LabResultsGuide />;
            case 'custom-api-mappings':
                return <CustomMappingsGuide />;
            case 'add-patient-api':
                return <AddPatientGuide />;
            default:
                return <p>Guide not found.</p>;
        }
    };

    return (
  <>
    <Header />
        <div className="guide-page">
            <button className="back-button" onClick={() => navigate('/tryapi')}>
                &larr; Back to Guides
            </button>
            {renderGuide()}
        </div>
     </>
    );
};

export default GuideViewer;
