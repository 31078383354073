import React, { useState, useEffect } from "react";
import "../../AddPatient/AddPatient.modules.css";
import InputMask from "react-input-mask";
import StateSelector from "../../../Payers/StateSelector";
import { useTranslation } from 'react-i18next';

function PersonalInformation({
  requiredFields,
  UserData = {},
  onUpdateUserData,
  errors,
}) {
  const [userData, setUserData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dob: "",
    phone: "",
    email: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (UserData) {
      setUserData({
        firstName: UserData.firstName || "",
        middleName: UserData.middleName || "",
        lastName: UserData.lastName || "",
        gender: UserData.gender || "",
        dob: UserData.dob || "",
        phone: UserData.phone || "",
        email: UserData.email || "",
        address: {
          address1: UserData.address?.address1 || "",
          address2: UserData.address?.address2 || "",
          city: UserData.address?.city || "",
          state: UserData.address?.state || "",
          zip: UserData.address?.zip || "",
        },
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedUserData = { ...userData };
  
    if (name === "dob") {
      // Convert to mm/dd/yyyy format before saving
      const formattedDate = formatDate(value);
      updatedUserData[name] = formattedDate;
    } else if (
      name === "address1" ||
      name === "address2" ||
      name === "city" ||
      name === "state" ||
      name === "zip"
    ) {
      updatedUserData.address = {
        ...updatedUserData.address,
        [name]: value,
      };
    } else {
      updatedUserData[name] = value;
    }
  
    setUserData(updatedUserData);
    onUpdateUserData(updatedUserData);
  };
  
  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${month}/${day}/${year}`;
  };
  
  const toISODate = (date) => {
    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };
  
  
  

  const handleGenderChange = (gender) => {
    const updatedUserData = { ...userData, gender };
    setUserData(updatedUserData);
    onUpdateUserData(updatedUserData);
  };

  return (
    <>
      <div className="input-group-row">
        <div className="input-field">
          <label htmlFor="firstName">
            {t("firstName")}
            {errors?.firstName && requiredFields.firstName && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="text"
            name="firstName"
            value={userData.firstName}
            onChange={handleInputChange}
            required={requiredFields.firstName}
          />
        </div>

        <div className="input-field">
          <label htmlFor="middleName">{t("middleName")}</label>
          <input
            type="text"
            name="middleName"
            style={{maxWidth: "5rem"}}
            value={userData.middleName}
            onChange={handleInputChange}
          />
        </div>

        <div className="input-field">
          <label htmlFor="lastName">
            {t("lastName")}
            {errors?.lastName && requiredFields.lastName && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="text"
            name="lastName"
            value={userData.lastName}
            onChange={handleInputChange}
            required={requiredFields.lastName}
          />
        </div>
      </div>

      <div className="input-group">
        <label htmlFor="dob">
          {t("dob")}
          {errors?.dob && requiredFields.dob && (
            <span className="RequiredMark">*</span>
          )}
        </label>
        <input
            type="date"
            name="dob"
            value={userData.dob ? toISODate(userData.dob) : ""}
            onChange={handleInputChange}
            required={requiredFields.dob}
          />


        <div className="gender-buttons">
          <label>
            {t("gender")}
            {errors?.gender && requiredFields.gender && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <button
            type="button"
            className={`gender-button ${
              userData.gender === "M" ? "selected" : ""
            }`}
            onClick={() => handleGenderChange("M")}
          >
            {t("male")}
          </button>
          <button
            type="button"
            className={`gender-button ${
              userData.gender === "F" ? "selected" : ""
            }`}
            onClick={() => handleGenderChange("F")}
          >
            {t("female")}
          </button>
        </div>
      </div>

      <div className="input-group-row">
        <div className="input-field">
          <label htmlFor="address1">
            {t("addressLine1")}
            {errors?.addressLine1 && requiredFields.address && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="text"
            name="address1"
            value={userData.address.address1}
            onChange={handleInputChange}
            required={requiredFields.address}
          />
        </div>

        <div className="input-field">
          <label htmlFor="address2">{t("addressLine2")}</label>
          <input
            type="text"
            name="address2"
            value={userData.address.address2}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="input-group-row">
        <div className="input-field">
          <label htmlFor="city">
            {t("city")}
            {errors?.city && requiredFields.address && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="text"
            name="city"
            value={userData.address.city}
            onChange={handleInputChange}
            required={requiredFields.address}
          />
        </div>

        <div className="input-field">
          <label htmlFor="state">
            {t("state")}
            {errors?.state && requiredFields.address && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <StateSelector
            className="w-100"
            selectedState={userData.address.state}
            setSelectedState={(state) => {
              setUserData((prevState) => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  state,
                },
              }));
            }}
          />
        </div>

        <div className="input-field">
          <label htmlFor="zip">
            {t("zip")}
            {errors?.zip && requiredFields.address && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
             type="tel"             
            inputMode="numeric"
            name="zip"
            maxLength="5"
            pattern="^\d{5}$"
            value={userData.address.zip}
            onChange={handleInputChange}
            className="zipInput"
            required={requiredFields.address}
          />
        </div>
      </div>

      <div className="input-group-row">
        <div className="input-field">
          <label htmlFor="email">
            {t("email")}
            {errors?.email && requiredFields.email && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="email"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            required={requiredFields.email}
          />
        </div>
        <div className="input-field">
          <label htmlFor="phone">
            {t("phone")}
            {errors?.phone && requiredFields.phone && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <InputMask
          mask="(999) 999-9999"
          value={userData.phone}
          onChange={handleInputChange}
          placeholder={t("phonePlaceholder")}
          required={requiredFields.phone}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              type="tel"            // Use "tel" to open numeric keypad
              inputMode="numeric"
              name="phone"
            />
          )}
        </InputMask>
        </div>
      </div>
    </>
  );
}

export default PersonalInformation;