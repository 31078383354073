import React, { useState, useEffect } from "react";
import { db } from "../../../../../firebase";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import AppointmentSlots from "./AppointmentSlots";
import { format, parse } from "date-fns";

const Appointments = ({ clinicId, onNext, onAppointmentSelected }) => {
  const [clinicData, setClinicData] = useState(null);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClinicData = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "clinics", clinicId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setClinicData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching clinic data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchBookedSlots = async () => {
      try {
        const appointmentsRef = collection(db, `clinics/${clinicId}/appointments`);
        const querySnapshot = await getDocs(appointmentsRef);
    
        const slots = querySnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
    
          try {
            // Parse and standardize the date field
            const parsedDate = parse(data.date, "MMMM do", new Date()); // Adjust format to match Firestore `date`
            acc.push({
              date: format(parsedDate, "yyyy-MM-dd"), // Format to "yyyy-MM-dd"
              time: data.time, // Keep time as is
            });
          } catch (error) {
            console.warn(`Skipping invalid date format for appointment: ${data.date}`);
          }
    
          return acc;
        }, []);
    
        console.log("Fetched Booked Slots:", slots);
        setBookedSlots(slots);
      } catch (error) {
        console.error("Error fetching booked slots:", error.message);
      }
    };
    
    
    fetchClinicData();
    fetchBookedSlots();
  }, [clinicId]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : clinicData ? (
        <AppointmentSlots
          workingHours={clinicData.workingHours}
          hoursBefore={clinicData.hoursBefore}
          appointmentTypes={clinicData.appointmentTypes}
          clinicTimeZone={clinicData.timeZone}
          bookedSlots={bookedSlots}
          clinicId={clinicId}
          onNext={onNext}
          onAppointmentSelected={onAppointmentSelected}
        />
      ) : (
        <p>Clinic data not found.</p>
      )}
    </div>
  );
};

export default Appointments;
