import React, { useState, useEffect } from 'react';
import QuickSearch from "../../Eligibility/Quicksearch";
import '../PatientFiles.modules.css';
import HelpArticleLink from "../../Articles/HelpArticleLink";

function PatientFilesEligibility({ firstName, lastName, dob, gender, onClose, patientId, memberid, tradingPartnerName }) {

  function convertDobFormat(dob) {
    if (!dob) return '';
    
    // Check if dob includes slashes
    if (dob.includes('/')) {
      const [month, day, year] = dob.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    
    // If dob includes dashes, assume it's already in YYYY-MM-DD format
    if (dob.includes('-')) {
      return dob;
    }
    
    // Handle other formats or return an empty string
    return '';
  }
  

  return (
    <div className='actionContainer'>
      <div className="ClaimContainer">
        <div className="closeButtonContainer">
        <button onClick={onClose} className="filesCloseButton">
          X
        </button>
        <p className="closeBarNav">Eligibility Search</p>
      </div>        
    <div className='quickSearchHelper'>
        <QuickSearch
          firstName={firstName}
          lastName={lastName}
          dob={convertDobFormat(dob)}
          gender={gender}
          memberid={memberid}
          patientId={patientId}
          initialTradingPartnerName={tradingPartnerName}
        />

      <div className="help-article-wide">
              <HelpArticleLink 
              article={{ 
              title: 'Billing from Patients', 
              link: 'https://popularishealth.com/article/Billing-for-Patients' 
              }} 
            />
         </div>
      </div>
      </div>      
    </div>


  );
};

export default PatientFilesEligibility;
