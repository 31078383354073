import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  doc,
  collection,
  addDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
  setDoc,
  query,
  onSnapshot
} from "firebase/firestore";
import ProviderSelection from "../../Providers/ProviderSelection";
import { getFunctions, httpsCallable } from "firebase/functions";
import useUID from "../../General/useUID";
import HexSpinner from "../../General/Animations/Hexspinner";
import FormSelection from "../FormEditor/FormSelection/FormSelection";
import ValidateSubform from "../../PatientFiles/FormEditor/FormSelection/ValidateSubform";
import {
  convertDateToMMDDYYYY,
  convertDateToYYYYMMDD,
} from "../../General/DateConversion";
import { differenceInDays, parse } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClinicMedical, faHospital, faAmbulance, faLaptopMedical, faHome, faUserMd, faEye, faFileMedical, faHandshake, faShieldAlt, faHandHoldingMedical, faBuilding, faHeartbeat, faBed, faSmile, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faRegularHeart } from "@fortawesome/free-regular-svg-icons";
import BillingCodesSelector from "../../Claims/Subcomponents/BillingCodesSelector";
import CallbackSelection from "./CallbackSelection";
import { View } from "lucide-react";

const functions = getFunctions();
const decryptFunction = httpsCallable(functions, "decrypt");

const getInputFormattedDate = (onClose) => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = today.getFullYear();
  return `${year}-${month}-${day}`;
};

function BlankEncounter({
  patientId,
  onClose,
  duration,
  encounterData = null,
  providers = { providers },
  patientData
}) {
  const [dateOfService, setDateOfService] = useState(
    encounterData ? encounterData.dateOfService : getInputFormattedDate()
  );
  const [billingCode, setBillingCode] = useState("");
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [reasonForVisit, setReasonForVisit] = useState("");
  const [service, setService] = useState("");
  const [encounterType, setEncounterType] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [providerfirstName, setProviderfirstName] = useState("");
  const [providerlastName, setProviderlastName] = useState("");
  const [durationValue, setDurationValue] = useState({ hours: 0, minutes: 0 });
  const [formInputValues, setFormInputValues] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [selectedFormData, setSelectedFormData] = useState([]);
  const [selectedFormDataInit, setSelectedFormDataInit] = useState([]);
  const [formInputValuesInit, setFormInputValuesInit] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [controlNumber, setControlNumber] = useState('');
  const [encounterId, setEncounterId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [callbackInfo, setCallbackInfo] = useState("");
  const [favoriteServiceType, setFavoriteServiceType] = useState("");
  const [uid, subUserUID] = useUID();
  const [recentEncounterWarning, setRecentEncounterWarning] = useState("");

  const faIcons = {
    "Outpatient Visit": faClinicMedical,
    "Inpatient Admission": faHospital,
    "Emergency Room Visit": faAmbulance,
    "Telemedicine Visit": faLaptopMedical,
    "Home Health Visit": faHome,
    "Ambulatory Care": faUserMd,
    "Day Surgery": faClinicMedical,
    "Observation": faEye,
    "Ancillary Services": faFileMedical,
    "Follow-up Visit": faHandshake,
    "Preventative Visit": faShieldAlt,
    "Rehabilitation Visit": faHandHoldingMedical,
    "Referral Visit": faBuilding,
    "Urgent Care Visit": faAmbulance,
    "Post-operative Visit": faHeartbeat,
    "Nursing Home Visit": faBed,
    "Routine Check-up": faSmile,
    "Maternity & Antenatal Visits": faUserTie,
  };

  useEffect(() => {
    const isValid = validateForm();
    if (formValid !== isValid) {
      setFormValid(isValid);
    }
  }, [
    dateOfService,
    selectedProviders,
    billingCode,
    service,
    reasonForVisit,
    encounterType,
    formValid,
  ]);

  useEffect(() => {
    setControlNumber(generateControlNumber());
    setEncounterId(generateEncounterId());
  }, []);

  useEffect(() => {
    if (!uid) return;

    const fetchFavoriteServiceType = async () => {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const favoriteType = userData.favoriteServiceType || "";
        setFavoriteServiceType(favoriteType);
        setEncounterType(favoriteType); // Automatically select favorite service type
      }
    };

    fetchFavoriteServiceType();

    if (encounterData) {
      setDateOfService(encounterData.dateOfService);
      setBillingCode(encounterData?.billingCode);
      setEncounterType(encounterData.encounterType);
      setSelectedProviders(encounterData.selectedProviders);
      setProviderfirstName(encounterData.selectedProvider?.firstName || "");
      setProviderlastName(encounterData.selectedProvider?.lastName || "");
      setSelectedFormId(encounterData.selectedFormId);
      setFormInputValues(encounterData.formInputs);
      setFormInputValuesInit(encounterData.formInputs);
      setSelectedFormDataInit(encounterData.selectedFormData);
      setOrganizationName(encounterData.selectedProvider?.organizationName || "");
    }

    if (duration) {
      const hours = Math.floor(duration / (1000 * 60 * 60));
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const seconds = Math.floor((duration / 1000) % 60);
      const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      setDurationValue(formattedDuration);
    } else if (encounterData && encounterData.duration) {
      setDurationValue(encounterData.duration);
    } else {
      setDurationValue("00:00:00");
    }
  }, [encounterData, duration, uid]);

  const resetForm = () => {
    setDateOfService(getInputFormattedDate());
    setEncounterType(favoriteServiceType); // Set to favorite service type
    setSelectedProviders("");
    setOrganizationName("");
    setProviderfirstName("");
    setProviderlastName("");
    setDurationValue("00:00:00");
    setFormInputValues([]);
    setHasSubmitted(false);
    setFormValid(true);
    setFormErrors({});
    setFormInputValuesInit([]);
  };

  const getRequiredFieldsMessage = () => {
    const requiredFields = [
      { field: "Date of Service", value: dateOfService },
      { field: "Provider", value: selectedProviders },
      { field: "Billing Code", value: billingCode },
      { field: "Encounter Type", value: encounterType }
    ];

    const missingFields = requiredFields
      .filter(field => !field.value || (field.field === "Encounter Type" && (field.value === "" || field.value === "Select an option")))
      .map(field => field.field);

    return missingFields.length === 0
      ? ""
      : `Please fill in the following required fields: ${missingFields.join(", ")}.`;
  };

  const validateForm = () => {
    const errors = {};

    const requiredFields = {
      dateOfService: "Date of Service",
      selectedProviders: "Provider",
      billingCode: "Billing Code",
      encounterType: "Encounter Type"
    };

    Object.entries(requiredFields).forEach(([field, label]) => {
      if (
        !eval(field) ||
        (typeof eval(field) === "string" && eval(field).trim() === "") ||
        (field === "encounterType" && (encounterType === "" || encounterType === "Select an option"))
      ) {
        errors[field] = `${label} is required`;
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleBillingCodesSelect = (selectedCode) => {
    // console.log("selectedCode", selectedCode)
    setBillingCode(selectedCode)
    // setFormValid(validateForm());
  };

  const handleProviderSelection = (data) => {
    if (data) {
      setSelectedProviders(data);
      setOrganizationName(data.organizationName || "");
      setProviderfirstName(data.firstName || "");
      setProviderlastName(data.lastName || "");
    } else {
      setSelectedProviders(null);
      setOrganizationName("");
      setProviderfirstName("");
      setProviderlastName("");
    }
  };

  const saveEncounterData = async (encounterDataToSave) => {
    try {
      const encryptFunction = httpsCallable(functions, "encrypt");
      const encryptedResponse = await encryptFunction(encounterDataToSave);
      const encryptedData = encryptedResponse.data;

      const encountersRef = collection(
        doc(db, "patients", uid, "patientData", patientId),
        "encounters"
      );

      const saveData = {
        ...encryptedData,
        timestamp: serverTimestamp(),
      };

      let encounterDoc;
      if (encounterDataToSave.id) {
        await updateDoc(doc(encountersRef, encounterDataToSave.id), saveData);
        encounterDoc = { id: encounterDataToSave.id };

        await logActivity({
          activity: "Encounter updated",
          activityType: "addEncounter",
          encounterId: encounterDataToSave.id,
        });
      } else {
        encounterDoc = await addDoc(encountersRef, saveData);

        await logActivity({
          activity: "New encounter added",
          activityType: "addEncounter",
          encounterId: encounterDoc.id,
        });
      }

      return encounterDoc;
    } catch (error) {
      console.error("Error saving encounter:", error);
      throw error;
    }
  };

  const triggerAPICallback = async (encounterDataToSave, encounterDocId) => {
    try {
      const apiQueueRef = collection(doc(db, "users", uid), "apiQueue");
      const queueDoc = await addDoc(apiQueueRef, {
        encounterId: encounterDocId,
        apiType: 'encountersCallback',
        status: 'pending',
        callbackInfo,
        timestamp: serverTimestamp(),
        patientId,
      });

      console.log("Encounter data added to the apiQueue with pending status");

      await logActivity({
        activity: "Callback attempt",
        activityType: "callback",
        encounterId: encounterDocId,
      });

      const callEncountersAPICallback = httpsCallable(functions, 'encountersAPICallback');

      // Await the result to handle the `isRunning` boolean
      const result = await callEncountersAPICallback({
        uid,
        patientId,
        encounterData: encounterDataToSave,
        callbackInfo,
        queueDocId: queueDoc.id,
        patientData,
      });

      // Check if the function is running before proceeding
      if (result.data.isRunning) {
        console.log("Function is running, proceeding with save operation.");

        // Handle the success result
        const successMessage = result.data.message || "No success message found";
        const logMessage = `Callback Success Message: ${successMessage}`;
        const addLogFunction = httpsCallable(functions, 'addLog');
        await addLogFunction({ uid: uid, message: logMessage });

        await logActivity({
          activity: "Callback response logged",
          activityType: "callback",
          encounterId: encounterDocId,
        });
      } else {
        console.error("Function is not running as expected.");
        setErrorMessage("Error: The function did not start as expected.");
      }

    } catch (error) {
      console.error("Error in API callback process:", error);
    }
  };

  const logActivity = async ({ activity, activityType, encounterId }) => {
    try {
      const logData = {
        timestamp: Date.now(),
        patientId,
        encounterId,
        activity,
        activityType,
        uid,
      };
      await addDoc(collection(db, "users", uid, "activityLogs"), logData);
    } catch (error) {
      console.error("Error logging activity:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasSubmitted(true);

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    setIsLoading(true);

    if (selectedFormId) {
      let subFormValidation = ValidateSubform(selectedFormData, formInputValues);

      if (!subFormValidation.isValid) {
        setIsLoading(false);
        setErrorMessage(subFormValidation.message);
        return;
      }
    }

    console.log("selectedFormData", selectedFormData)
    console.log("formInputValues", formInputValues)

    const formattedDateOfService = convertDateToMMDDYYYY(dateOfService);
    const encounterDataToSave = {
      dateOfService: formattedDateOfService,
      billingCode,
      encounterType,
      organizationName,
      selectedProviders,
      providerfirstName,
      providerlastName,
      duration: durationValue,
      selectedFormId,
      selectedFormData,
      formInputs: formInputValues,
      controlNumber,
      encounterId,
    };

    if (encounterData?.id) {
      encounterDataToSave.id = encounterData.id;
    }

    try {
      const encounterDoc = await saveEncounterData(encounterDataToSave);

      setErrorMessage("");
      resetForm();
      onClose();


      if (callbackInfo && callbackInfo.length > 0) {
        triggerAPICallback(encounterDataToSave, encounterDoc.id);
      }
    } catch (error) {
      console.error("Error saving encounter:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to generate a 9-digit control number
  const generateControlNumber = () => {
    return Math.floor(100000000 + Math.random() * 900000000).toString();
  };

  // Function to generate a 5-character alphanumeric encounter ID
  const generateEncounterId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleCallbackSelection = (callback) => {
    setCallbackInfo(callback)
  };

  const handleSetFavoriteServiceType = async () => {
    if (uid && encounterType) {
      const userDocRef = doc(db, "users", uid);
      await setDoc(userDocRef, { favoriteServiceType: encounterType }, { merge: true });
      setFavoriteServiceType(encounterType);
    }
  };

  useEffect(() => {
    if (!uid || !patientId) {
      console.log("UID or Patient ID is missing", { uid, patientId });
      return;
    }

    const encountersRef = collection(
      db,
      "patients",
      uid,
      "patientData",
      patientId,
      "encounters"
    );

    const encountersQuery = query(encountersRef);

    const unsubscribe = onSnapshot(encountersQuery, async (snapshot) => {
      const today = new Date();
      let recentEncounter = null;

      for (const doc of snapshot.docs) {
        const encounter = doc.data();

        let decryptedData = encounter;
        if (encounter.ciphertext && encounter.iv) {
          try {
            const encryptedData = {
              ciphertext: encounter.ciphertext,
              iv: encounter.iv,
            };
            const decryptedResponse = await decryptFunction(encryptedData);
            decryptedData = decryptedResponse.data;
          } catch (error) {
            console.error("Error decrypting encounter data: ", error);
            continue; // Skip this encounter if decryption fails
          }
        }

        const encounterDate = decryptedData.dateOfService
          ? parse(decryptedData.dateOfService, "MM/dd/yyyy", new Date())
          : null;

        if (encounterDate && differenceInDays(today, encounterDate) <= 30) {
          const daysAgo = differenceInDays(today, encounterDate);
          recentEncounter = `This patient had an encounter ${daysAgo} days ago.`;
          break; // Stop checking after finding the most recent encounter
        }
      }

      setRecentEncounterWarning(recentEncounter || "");
    });

    return () => unsubscribe();
  }, [uid, patientId]);


  return (
    <>
      <div className="actionContainer">
        <div className="popupContainer">
          <div className="popupContent">
            <button
              className="filesCloseButtonFixed"
              onClick={() => {
                resetForm();
                onClose();
              }}
            >
              X
            </button>

            <div className="centerHeader">
              <h3>
                {encounterData ? "Edit Encounter" : "New Blank Encounter"}
              </h3>
            </div>

            <div className="input-group-row mt-3">
              <div className="input-field">
                <label>
                  Date of Service:
                  {formErrors.dateOfService && (
                    <span className="RequiredMark"> *</span>
                  )}
                </label>
                <input
                  type="date"
                  value={convertDateToYYYYMMDD(dateOfService)}
                  onChange={(e) => setDateOfService(e.target.value)}
                />
              </div>

              <div className="input-group-row">
                <div className="input-field">
                  <label>
                    Encounter Type:
                    {formErrors.encounterType && (
                      <span className="RequiredMark"> *</span>
                    )}
                  </label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <select
                      className="w-90"
                      value={encounterType}
                      onChange={(e) => setEncounterType(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {Object.entries(faIcons).map(([type, icon]) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={handleSetFavoriteServiceType}
                      title='Set Encounter Type as default'
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        marginLeft: '8px',
                        color: encounterType === favoriteServiceType ? 'red' : 'grey'
                      }}
                    >
                      <FontAwesomeIcon
                        icon={encounterType === favoriteServiceType ? faSolidHeart : faRegularHeart}
                        size="lg"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-5 w-90 p-5 pt-0 mt-0">
              <label className="pt-5">
                Select a Provider:
                {formErrors.selectedProviders && (
                  <span className="RequiredMark"> *</span>
                )}
              </label>
              <ProviderSelection
                onProviderSelect={handleProviderSelection}
                selectedProviderIdexternal={encounterData?.selectedProviders}
              />
              <div className="pt-5 w-90">
                <label>
                  Billing Codes:
                  {formErrors.billingCode && (
                    <span className="RequiredMark"> *</span>
                  )}
                </label>
                {/* <View className="my-3"> */}
                <BillingCodesSelector
                  className="py-3"
                  onBillingCodeSelect={handleBillingCodesSelect}
                  mode="encounters"
                  initialSelectedCode={billingCode[0]?.id}
                />
                {/* </View> */}
              </div>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "black",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <label className="mt-4">Select a Form Layout: (optional)</label>
              <FormSelection
                onFormInputChange={setFormInputValues}
                selectedFormId={selectedFormId}
                setSelectedFormId={setSelectedFormId}
                setSelectedFormDataTranfer={setSelectedFormData}
                formInputValuesInit={formInputValuesInit}
                selectedFormDataInit={selectedFormDataInit}
                isEditing={!!encounterData}
              />
              <>
                <CallbackSelection uid={uid} onSelect={handleCallbackSelection} />
              </>
              <div className="my-5 display-grid">
              {recentEncounterWarning && (
                    <div style={{ color: "red", marginTop: "20px" }}>
                      {recentEncounterWarning}
                    </div>
                  )}
                {!formValid && hasSubmitted && (
                  <p style={{ color: "red" }}>{getRequiredFieldsMessage()}</p>
                )}

                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              
                {isLoading ? (
                  <HexSpinner />
                ) : (
                  
                  <button
                    type="submit"
                    className="mx-auto"
                    disabled={isLoading}
                    onClick={handleSubmit}
                  >
                    Save Encounter
                  </button>
                )}
              </div>
              <div className="mt-3">
                <p>Encounter ID: <strong>{encounterId}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlankEncounter;
