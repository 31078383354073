import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug, faClipboardList, faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import './APIIntegrationsSection.css';

const APIIntegrationsSection = ({ isVisible }) => {
  const canvasRef = useRef(null);
  let animationFrame;

  const integrationSteps = [
    {
      title: 'Seamless Integration',
      description: "Effortlessly integrate with external systems to enhance your workflows.",
      icon: faPlug,
    },
    {
      title: 'Custom Solutions',
      description: "Our team is ready to assist with custom integrations tailored to your needs.",
      icon: faClipboardList,
    },
    {
      title: 'Enterprise Support',
      description: 'Enterprise users enjoy free integrations as part of our commitment to your success.',
      icon: faNotesMedical,
    },
  ];

  const drawConnections = (time) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas not found.");
      return;
    }
  
    const ctx = canvas.getContext('2d');
    const container = document.querySelector('.process-steps');
  
    if (!container) {
      console.error("Container '.process-steps' not found.");
      return;
    }
  
    const nodes = Array.from(container.children);
    if (nodes.length < 2) {
      console.warn("Not enough nodes to connect.");
      return;
    }
  
    // Adjust canvas size
    canvas.width = container.offsetWidth;
    canvas.height = container.offsetHeight;
  
    // Clear previous drawings
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    ctx.strokeStyle = 'rgba(0, 255, 255, 0.7)';
    ctx.lineWidth = 2;
  
    nodes.forEach((node, index) => {
      if (index < nodes.length - 1) {
        const current = node.getBoundingClientRect();
        const next = nodes[index + 1].getBoundingClientRect();
  
        // Get positions relative to the canvas
        const startX = current.left + current.width / 2 - canvas.getBoundingClientRect().left;
        const startY = current.top + current.height / 2 - canvas.getBoundingClientRect().top;
  
        const endX = next.left + next.width / 2 - canvas.getBoundingClientRect().left;
        const endY = next.top + next.height / 2 - canvas.getBoundingClientRect().top;
  
        const controlOffset = Math.sin(time / 500 + index) * 50;
  
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.bezierCurveTo(
          (startX + endX) / 2, startY - controlOffset,
          (startX + endX) / 2, endY + controlOffset,
          endX, endY
        );
        ctx.stroke();
      }
    });
  
    // Debugging logs
    console.log("Canvas dimensions:", canvas.width, canvas.height);
    console.log("Nodes count:", nodes.length);
    nodes.forEach((node, index) => {
      console.log(`Node ${index} position:`, node.getBoundingClientRect());
    });
  
    // Request next frame
    animationFrame = requestAnimationFrame(drawConnections);
  };
  

  useEffect(() => {
    animationFrame = requestAnimationFrame(drawConnections);

    window.addEventListener('resize', () => drawConnections(0));

    return () => {
      cancelAnimationFrame(animationFrame);
      window.removeEventListener('resize', () => drawConnections(0));
    };
  }, []);

  return (
    <section className="bg-dark skewed-section" id="api-integrations">
      <div className="golden-container">
        <h2 className={`security-section ${isVisible['api-integrations'] ? 'animate' : ''}`}>
          API Integrations Made Easy
        </h2>
        <div className="process-steps">
          {integrationSteps.map((step, index) => (
            <div
              className={`step-3 ${isVisible['api-integrations'] ? 'animate' : ''}`}
              key={index}
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              <FontAwesomeIcon icon={step.icon} className="step-icon-dark" />
              <h3 className="step-title-dark">{step.title}</h3>
              <p className="step-description-dark">{step.description}</p>
            </div>
          ))}
        </div>
        <div className="cta-container-center">
          <Link to="/tryapi">
            <button className="cta-button">Read Docs</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default APIIntegrationsSection;
