import React, { useState, useRef } from 'react';
import SignatureCanvas from "react-signature-canvas";

const InputTypeSignature = ({
  fieldItem,
  isVisible = true,
  preview = true,
  handleInputChange = () => { },
}) => {
  const [isSigned, setIsSigned] = useState(false);
  const [initialValue] = useState(fieldItem?.value);
  const [showCanvas, setShowCanvas] = useState(!initialValue);
  const signatureRef = useRef(null);

  const clearSignature = (event) => {
    event.preventDefault();
    if (signatureRef.current) {
      signatureRef.current.clear();
      setIsSigned(false);
    }
    setShowCanvas(true);
    handleInputChange({ target: { name: fieldItem?.name, value: null } });
  };

  const handleSignatureEnd = () => {
    if (signatureRef.current) {
      const signatureImage = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
      handleInputChange({ target: { name: fieldItem?.name, value: signatureImage } });
    }
  };

  return (
    <>
      {isVisible && (
        <div className="m-auto signature-canvas-width">
          {fieldItem?.label && (
            <label>
              {fieldItem?.label}
              <span className="RequiredMark">
                {fieldItem?.required && " *"}
              </span>
            </label>
          )}
          
          {!showCanvas && initialValue ? (
            <div className="w-full">
              <img 
                src={initialValue} 
                alt="Signature" 
                className="w-full h-48 object-contain bg-white border border-gray-300 rounded"
              />
            </div>
          ) : (
            <SignatureCanvas
              ref={signatureRef}
              penColor="black"
              className={preview ? 'signature-canvas-form-white' : "signature-canvas-form"}
              canvasProps={{
                width: 200 * 1.618,
                height: 200,
                className: preview ? 'signature-canvas-form-white' : "signature-canvas-form",
                willReadFrequently: true,
              }}
              onEnd={handleSignatureEnd}
            />
          )}
          
          <button 
            className="primary mx-0 signature-canvas-width mt-2" 
            onClick={clearSignature}
          >
            Clear Signature
          </button>
        </div>
      )}
    </>
  );
};

export default InputTypeSignature;