import React, { useState, useEffect } from "react";
import { db, auth } from "../../../firebase";
import {
    collection,
    addDoc,
    onSnapshot,
    updateDoc,
    doc,
    deleteDoc,
    query,
    where,
    getDocs,
} from "firebase/firestore";

function ResultsAPI() {
    const [name, setName] = useState("");
    const [resultMappings, setResultMappings] = useState("");
    const [apiSpecs, setApiSpecs] = useState([]);
    const [editing, setEditing] = useState(null);
    const [error, setError] = useState("");

    const uid = auth.currentUser?.uid;
    const apiSpecsRef = collection(db, "APISpecs");

    useEffect(() => {
        const unsubscribe = onSnapshot(apiSpecsRef, (querySnapshot) => {
            const specs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setApiSpecs(specs);
        });

        return () => unsubscribe();
    }, []);

    const addApiSpec = async () => {
        if (!name || !resultMappings) {
            setError("Please fill in all fields.");
            return;
        }

        const existingSpecQuery = query(apiSpecsRef, where("UserID", "==", uid), where("Event", "==", "Results"));
        const querySnapshot = await getDocs(existingSpecQuery);
        if (!querySnapshot.empty) {
            setError(`You already have a Results API specification for this event type.`);
            return;
        }

        await addDoc(apiSpecsRef, {
            Name: name,
            Event: "Results", // Fixed event type
            UserID: uid,
            resultMappings: resultMappings ? JSON.parse(resultMappings) : {},
        });

        setName("");
        setResultMappings("");
        setError("");
    };

    const updateApiSpec = async () => {
        const specDoc = doc(db, "APISpecs", editing.id);
        await updateDoc(specDoc, {
            Name: editing.Name,
            resultMappings: editing.resultMappings,
        });
        setEditing(null);
    };

    const deleteApiSpec = async (id) => {
        const specDoc = doc(db, "APISpecs", id);
        await deleteDoc(specDoc);
    };

    return (
        <div className="resultsApiContainer">
            <h3>Results API</h3>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
            />
            <textarea
                value={resultMappings}
                onChange={(e) => setResultMappings(e.target.value)}
                placeholder="Result Mappings JSON"
            />
            <button onClick={addApiSpec}>Add Results API</button>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {apiSpecs.map((spec) => (
                        <tr key={spec.id}>
                            {editing && editing.id === spec.id ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            value={editing.Name}
                                            onChange={(e) => setEditing({ ...editing, Name: e.target.value })}
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            value={JSON.stringify(editing.resultMappings, null, 2)}
                                            onChange={(e) =>
                                                setEditing({
                                                    ...editing,
                                                    resultMappings: JSON.parse(e.target.value),
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <button onClick={updateApiSpec}>Save</button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{spec.Name}</td>
                                    <td>
                                        <button onClick={() => setEditing(spec)}>Edit</button>
                                        <button onClick={() => deleteApiSpec(spec.id)}>Delete</button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ResultsAPI;
