import React from 'react';
import '../guides/APIGuides.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const LabResultsGuide = () => {
    return (
        <div className="guide-page">
            <h1>Processing Lab Test Results</h1>
            <p>
                This guide explains how to process lab test results and integrate them into your system using our Lab Results API.
                Learn how to map and customize your fields for a streamlined workflow.
            </p>

            {/* Run in Postman Button */}
            <div className="postman-button-container">
                <a
                    href="https://www.postman.com/galactic-zodiac-954587/apis/request/zumif8p/resultspost?action=share&source=copy-link&creator=25649850&ctx=documentation"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="postman-button"
                >
                    <FontAwesomeIcon icon={faPlay} className="play-icon" />
                    <span>Run in Postman</span>
                </a>
            </div>


            <h2>Setup</h2>
            <p>Follow these steps to get started:</p>
            <ul>
                <li>
                    <strong>Step 1:</strong> Obtain API access credentials from the 
                    <h2>Authentication</h2>
            <p>To authenticate with the Add Patient API, you need an API key and Client ID. Follow these steps to generate or regenerate your credentials:</p>
            <ol>
                <li>Log in to the <strong>Admin Account</strong> of your application.</li>
                <li>Navigate to the <strong>Settings</strong> page and click on the <strong>API</strong> section.</li>
                <li>Scroll to the bottom right of the page and click the <strong>Generate API Credentials</strong> button to create new credentials, or click <strong>Regenerate</strong> if you need to reset your keys.</li>
                <li>Note down the <strong>Client ID</strong> and <strong>API Key</strong>. These will be required for making authenticated API requests.</li>
            </ol>
            <p>Include the API key in the request headers as follows:</p>
            <pre>
                {`
                {
                    "x-api-key": "YOUR_API_KEY"
                }
                `}
            </pre>
               </li>
                <li>
                    <strong>Step 2:</strong> Review the API schema in the 
                    <a href="/tryapi/references" className="guide-link"> References Section</a>.
                </li>
                <li>
                    <strong>Step 3:</strong> Set up custom mappings to handle additional fields as needed.
                </li>
            </ul>
            <h2>Test Endpoint</h2>
            <p>
                Use the following endpoint for testing your integration:
                <pre className="code-block">
                    https://receivetestdata-lqsbsnhsqq-uc.a.run.app
                </pre>
            </p>
            <h2>Available Data Fields</h2>
            <p>The following fields can be mapped and retrieved from the Lab Results API:</p>
            <ul>
                <li><strong>senderID:</strong> Identifier for the data sender.</li>
                <li><strong>patientID:</strong> Unique identifier for the patient.</li>
                <li><strong>accessionNumber:</strong> Custom control number for results linkage.</li>
                <li><strong>recordNo:</strong> Record number for internal tracking.</li>
                <li><strong>status:</strong> The status of the lab result (e.g., "Completed").</li>
                <li><strong>ResultedDateUTC:</strong> Date and time the lab result was finalized.</li>
                <li><strong>reports:</strong> An array of report objects with:
                    <ul>
                        <li><strong>source:</strong> Source type (e.g., "base64").</li>
                        <li><strong>type:</strong> Type of the report (e.g., "Lab Result").</li>
                        <li><strong>name:</strong> Name of the report file.</li>
                        <li><strong>data:</strong> Encoded report data.</li>
                    </ul>
                </li>
                <li><strong>results:</strong> Object containing test result details, including:
                    <ul>
                        <li><strong>testName:</strong> Name of the test (e.g., "Hemoglobin A1c").</li>
                        <li><strong>result:</strong> Test result value (e.g., "5.7%").</li>
                        <li><strong>units:</strong> Measurement units (e.g., "%").</li>
                        <li><strong>referenceRange:</strong> Normal range for the test.</li>
                    </ul>
                </li>
            </ul>
            <h2>Example Request</h2>
            <pre className="code-block">
                {`
POST https://receivetestdata-lqsbsnhsqq-uc.a.run.app
Content-Type: application/json

{
    "data": {
        "senderID": "123-abc",
        "patientID": "456-def",
        "accessionNumber": "789-ghj",
        "recordNo": "record-001",
        "status": "Completed",
        "ResultedDateUTC": "2023-01-01T12:00:00Z",
        "reports": [
            {
                "source": "base64",
                "type": "Lab Result",
                "name": "test-result.pdf",
                "data": "JVBERi0xLjQKJ... (truncated)"
            }
        ],
        "results": {
            "testName": "Hemoglobin A1c",
            "result": "5.7%",
            "units": "%",
            "referenceRange": "4.0%-5.6%"
        }
    }
}
                `}
            </pre>
            <h2>Response</h2>
            <pre className="code-block">
                {`
{
    "status": "success",
    "message": "Lab results processed successfully.",
    "data": {
        "recordID": "abcdef123456",
        "timestamp": "2023-01-01T12:00:00Z"
    }
}
                `}
            </pre>
            <h2>Learn More</h2>
            <p>
                For advanced customization and integration options, explore our other guides:
            </p>
            <ul>
                <li>
                    <a href="/tryapi/guides/encounters-api" className="guide-link">
                        Getting Started with the Encounters API
                    </a>
                </li>
                <li>
                    <a href="/tryapi/guides/custom-api-mappings" className="guide-link">
                        Customizing API Mappings
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default LabResultsGuide;
