import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaTasks, FaSearch } from "react-icons/fa";

const IntakeSearch = ({ patients, onFilter }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    dateFrom: "",
    dateTo: "",
    hasAppointment: false,
    hasIntakeForm: false,
  });
  const [isFocused, setIsFocused] = useState(false);

  // --------------------------------
  // 1) Filter logic whenever filters or search change
  // --------------------------------
  useEffect(() => {
    const debounce = setTimeout(() => {
      applyFilters();
    }, 300);

    return () => clearTimeout(debounce);
  }, [searchQuery, filters]);

  // --------------------------------
  // 2) applyFilters: do the actual filtering
  // --------------------------------
  const applyFilters = () => {
    let filtered = [...patients]; // make a copy of the full array

    // -- Search by full name
    if (searchQuery.trim() !== "") {
      filtered = filtered.filter((patient) => {
        const firstName = patient?.data?.patient?.firstName || "";
        const middleName = patient?.data?.patient?.middleName || "";
        const lastName = patient?.data?.patient?.lastName || "";

        const fullName = `${firstName} ${middleName} ${lastName}`
          .trim()
          .toLowerCase();
        return fullName.includes(searchQuery.toLowerCase());
      });
    }

    // -- Date From
    if (filters.dateFrom) {
      filtered = filtered.filter((patient) => {
        const createdDate = new Date(patient?.created);
        return createdDate >= new Date(filters.dateFrom);
      });
    }

    // -- Date To
    if (filters.dateTo) {
      filtered = filtered.filter((patient) => {
        const createdDate = new Date(patient?.created);
        return createdDate <= new Date(filters.dateTo);
      });
    }

    // -- hasAppointment
    if (filters.hasAppointment) {
      filtered = filtered.filter((patient) => patient.hasAppointment);
    }

    // -- hasIntakeForm
    if (filters.hasIntakeForm) {
      filtered = filtered.filter((patient) => patient.hasIntakeForm);
    }

    // Send filtered array up to parent
    onFilter(filtered);
  };

  // --------------------------------
  // 3) handleFilterChange
  // --------------------------------
  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // --------------------------------
  // 4) Render
  // --------------------------------
  return (
    <div className="intakeview-search-container">
      <div
        className={`intakeview-search-bar ${
          isFocused ? "intakeview-search-focused" : ""
        }`}
      >
        <FaSearch className="intakeview-search-icon" />
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search Intake"
          className="intakeview-search-input"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <FaTasks
          className="intakeview-filter-icon"
          title="Filter"
          onClick={() => setIsModalOpen(true)}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Filter Patients"
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
          content: {
            maxWidth: "400px",
            margin: "auto",
            padding: "2rem",
            borderRadius: "8px",
          },
        }}
      >
        <h2>Filter Patients</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            applyFilters();
            setIsModalOpen(false);
          }}
        >
          <div className="intakeview-filter-item">
            <label>
              Date From:
              <input
                type="date"
                name="dateFrom"
                value={filters.dateFrom}
                onChange={handleFilterChange}
                className="intakeview-filter-input"
              />
            </label>
          </div>
          <div className="intakeview-filter-item">
            <label>
              Date To:
              <input
                type="date"
                name="dateTo"
                value={filters.dateTo}
                onChange={handleFilterChange}
                className="intakeview-filter-input"
              />
            </label>
          </div>
          <div className="intakeview-filter-item">
            <label>
              <input
                type="checkbox"
                name="hasAppointment"
                checked={filters.hasAppointment}
                onChange={handleFilterChange}
                className="intake-checkbox"
              />
              Has Appointment
            </label>
          </div>
          <div className="intakeview-filter-item">
            <label>
              <input
                type="checkbox"
                name="hasIntakeForm"
                checked={filters.hasIntakeForm}
                onChange={handleFilterChange}
                className="intake-checkbox"
              />
              Has Intake Form
            </label>
          </div>
          <div className="intakeview-filter-actions">
            <button type="submit" className="intakeview-filter-button">
              Apply
            </button>
            <button
              type="button"
              onClick={() => {
                // Clear filters
                setFilters({
                  dateFrom: "",
                  dateTo: "",
                  hasAppointment: false,
                  hasIntakeForm: false,
                });
                // We also can re-run filtering or let useEffect do it
                applyFilters();
                setIsModalOpen(false);
              }}
              className="intakeview-filter-button"
            >
              Clear
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default IntakeSearch;
