import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMedical, faUserCheck, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function PricingTable({ showPaymentForm }) {
  const plans = [
    {
      name: 'Freebie',
      description: "Popularis is Latin for 'for the people' - Get unrestricted access.",
      buttonText: 'Get Started',
      price: 'Free',
      features: ['25 free claims or eligibility requests', 'Add up to 2 Providers', 'All patient management tools', 'Custom and Branded Intake Forms']
    },
    {
      name: 'Professional',
      description: 'Manage your operation with eligibility and get paid with claims.',
      buttonText: 'Get Started',
      price: '$199/month',
      features: ['$0.40 per claims or eligibility requests', 'Unlimited Patients', 'Batch Eligibility', 'Add up to 3 Providers']
    },
    {
      name: 'Enterprise',
      description: 'For practices that are growing or have multiple locations.',
      buttonText: 'Get Started',
      price: '$699/month',
      features: ['$0.35 per claims or eligibility requests','Unlimited Providers and Users', 'Access to APIs and custom APIs', 'Dedicated US-Based Support']
    },
  ];
  

  return (
    <div className="pricing-table">
      <div className="plan-container">
        {plans.map((plan, index) => (
          <div className="plan-card" key={index}>
            <h2>{plan.name}</h2>
            <div className="plan-description">
              <p>{plan.description}</p>
            </div>            <ul>
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            <div className='price-container'>
              <span className="price">{plan.price.split('/')[0]}</span>
              {plan.price.split('/')[1] && (
                <span className="month">/{plan.price.split('/')[1]}</span>
              )}
            </div>
            <Link to="/signup">
              <button>{plan.buttonText}</button>
            </Link>
          </div>
        ))}
      </div> 
    </div>
  );
}

export default PricingTable;
