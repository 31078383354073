import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../subcomponents/Footer';
import Header from '../subcomponents/Header';
import { useNavigate } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import '../guides/APIGuides.css';

// Import API Specifications
import { encountersApiSpecification } from '../API/EncountersAPISwagger';
import { receiveTestDataApiSpecification } from '../API/ReceiveTestDataAPISwagger';
import { addPatientApiSpecification } from '../API/addPatientEndpointAPI';

const guidesList = [
    { path: 'encounters-api', title: 'Getting Started with the Encounters API' },
    { path: 'lab-results-api', title: 'Processing Lab Test Results' },
    { path: 'custom-api-mappings', title: 'Customizing API Mappings' },
    { path: 'add-patient-api', title: 'Add Patient Endpoint API'}
];

const TryAPI = () => {
    const [view, setView] = useState('references'); // 'references' or 'guides'
    const navigate = useNavigate();

    const combinedApiSpecification = {
        openapi: "3.0.0",
        info: {
            title: "Combined API Documentation",
            version: "1.0.0",
            description: "Combined documentation for Encounters API and Test Data Reception API.",
        },
        paths: { ...encountersApiSpecification.paths, ...receiveTestDataApiSpecification.paths, ...addPatientApiSpecification.paths },
        components: encountersApiSpecification.components,
    };

    return (
        <div className="api-page">
            <Helmet>
                <title>API Integration</title>
                <meta name="description" content="API integration guides and references for Popularis APIs." />
            </Helmet>
            <Header />
            <main className="api-main-content">
                <div className="api-header">
                    <h1>Integrate with Us</h1>
                   
                    <div className="switcher-container">
                        <button
                            className={`switcher-button ${view === 'references' ? 'active' : ''}`}
                            onClick={() => setView('references')}
                        >
                            References
                        </button>
                        <button
                            className={`switcher-button ${view === 'guides' ? 'active' : ''}`}
                            onClick={() => setView('guides')}
                        >
                            Guides
                        </button>
                    </div>
                </div>
                {view === 'references' ? (
                    <div className="swagger-container">
                        <SwaggerUI spec={combinedApiSpecification} />
                    </div>
                ) : (
                    <div className="guides-container">
                        <h2>API Integration Guides</h2>
                        <ul className="guides-list">
                            {guidesList.map(({ path, title }) => (
                                <li key={path}>
                                    <button
                                        className="guide-link"
                                        onClick={() => navigate(`/tryapi/guides/${path}`)}
                                    >
                                        {title}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default TryAPI;
