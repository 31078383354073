import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PayerSelection from "../../../Payers/PayerSelection";
import "../../AddPatient/AddPatient.modules.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../firebase"; 
import { useTranslation } from "react-i18next";
import '../Clinics.css';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function InsuranceInformation({ onInsuranceInfoChange }) {
  const { clinic } = useParams() || {};
  const { t } = useTranslation();

  const [memberId, setMemberId] = useState("");
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [showSecondaryPayer, setShowSecondaryPayer] = useState(false);
  const [secondaryMemberId, setSecondaryMemberId] = useState("");
  const [selectedSecondaryPayer, setSelectedSecondaryPayer] = useState(null);

  // FRONT of Insurance Card
  const [insuranceCardImage, setInsuranceCardImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);

  // BACK of Insurance Card
  const [insuranceCardBackImage, setInsuranceCardBackImage] = useState(null);
  const [previewBackImage, setPreviewBackImage] = useState(null);
  const [backFile, setBackFile] = useState(null);

  // ID Front
  const [idFrontImage, setIdFrontImage] = useState(null);
  const [previewIdFront, setPreviewIdFront] = useState(null);
  const [idFrontFile, setIdFrontFile] = useState(null);

  // ID Back
  const [idBackImage, setIdBackImage] = useState(null);
  const [previewIdBack, setPreviewIdBack] = useState(null);
  const [idBackFile, setIdBackFile] = useState(null);

  /**
   * Helper: Upload file to Firebase Storage with a specific name based on fieldName.
   * Then store the resulting URL in the right piece of state AND call onInsuranceInfoChange.
   */
  const uploadImageToFirebase = async (theFile, setImageUrl, setPreview, fieldName) => {
    // 1) Derive a descriptive file name
    let newFileName = theFile.name; // fallback
    if (fieldName === "insuranceCardImage") {
      newFileName = "InsuranceFront.jpeg";
    } else if (fieldName === "insuranceCardBackImage") {
      newFileName = "InsuranceBack.jpeg";
    } else if (fieldName === "idFrontImage") {
      newFileName = "IDFront.jpeg";
    } else if (fieldName === "idBackImage") {
      newFileName = "IDBack.jpeg";
    }

    // 2) Upload file to Firebase Storage path
    const storageRefPath = storageRef(storage, `PatientDocuments/${clinic}/${newFileName}`);
    await uploadBytes(storageRefPath, theFile);
    const url = await getDownloadURL(storageRefPath);

    // 3) Update the correct state variable
    setImageUrl(url);
    notifyParent();

    // 4) Notify parent component (pass updated URLs for all images)
    if (onInsuranceInfoChange) {
      onInsuranceInfoChange({
        memberId,
        selectedPayer,
        insuranceCardImage,
        insuranceCardBackImage,
        idFrontImage,
        idBackImage,
        [fieldName]: url,
      });
    }

    // 5) For preview in the UI
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(theFile);
  };

  // ---------------------- FRONT Insurance Card Handlers ----------------------
  const handleFileChangeFront = (e) => {
    const theFile = e.target.files?.[0];
    if (!theFile) return;

    setFile(theFile);
    uploadImageToFirebase(
      theFile,
      setInsuranceCardImage,
      setPreviewImage,
      "insuranceCardImage"
    );
  };

  const onCameraClickFront = async () => {
    if (isMobile) {
      const videoElement = document.getElementById("videoPreviewFront");
      try {
        const constraints = { video: { facingMode: "environment" } };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoElement.srcObject = stream;
        videoElement.style.display = "block";
        videoElement.onloadedmetadata = () => videoElement.play();

        document.getElementById("captureButtonFront").style.display = "block";
        document.getElementById("startCameraButtonFront").style.display = "none";
      } catch (error) {
        console.error(t("cameraAccessError"), error);
        alert(t("cameraAccessAlert"));
      }
    } else {
      document.getElementById("fileInputFront").click();
    }
  };

  const capturePhotoFront = () => {
    const videoElement = document.getElementById("videoPreviewFront");
    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;

    canvas.getContext("2d").drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const capturedFile = new File([blob], "InsuranceFront.jpeg", { type: "image/jpeg" });
      setFile(capturedFile);
      uploadImageToFirebase(
        capturedFile,
        setInsuranceCardImage,
        setPreviewImage,
        "insuranceCardImage"
      );
    }, "image/jpeg");

    const tracks = videoElement.srcObject.getTracks();
    tracks.forEach((track) => track.stop());

    videoElement.style.display = "none";
    document.getElementById("captureButtonFront").style.display = "none";
  };

  // ---------------------- BACK Insurance Card Handlers ----------------------
  const handleFileChangeBack = (e) => {
    const theFile = e.target.files?.[0];
    if (!theFile) return;

    setBackFile(theFile);
    uploadImageToFirebase(
      theFile,
      setInsuranceCardBackImage,
      setPreviewBackImage,
      "insuranceCardBackImage"
    );
  };

  const onCameraClickBack = async () => {
    if (isMobile) {
      const videoElement = document.getElementById("videoPreviewBack");
      try {
        const constraints = { video: { facingMode: "environment" } };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoElement.srcObject = stream;
        videoElement.style.display = "block";
        videoElement.onloadedmetadata = () => videoElement.play();

        document.getElementById("captureButtonBack").style.display = "block";
        document.getElementById("startCameraButtonBack").style.display = "none";
      } catch (error) {
        console.error(t("cameraAccessError"), error);
        alert(t("cameraAccessAlert"));
      }
    } else {
      document.getElementById("fileInputBack").click();
    }
  };

  const capturePhotoBack = () => {
    const videoElement = document.getElementById("videoPreviewBack");
    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;

    canvas.getContext("2d").drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const capturedFile = new File([blob], "InsuranceBack.jpeg", { type: "image/jpeg" });
      setBackFile(capturedFile);
      uploadImageToFirebase(
        capturedFile,
        setInsuranceCardBackImage,
        setPreviewBackImage,
        "insuranceCardBackImage"
      );
    }, "image/jpeg");

    const tracks = videoElement.srcObject.getTracks();
    tracks.forEach((track) => track.stop());

    videoElement.style.display = "none";
    document.getElementById("captureButtonBack").style.display = "none";
  };

  // ---------------------- ID Front Handlers ----------------------
  const handleFileChangeIdFront = (e) => {
    const theFile = e.target.files?.[0];
    console.log("handleFileChangeIdFront triggered - file:", theFile);

    if (!theFile) return;

    setIdFrontFile(theFile);
    uploadImageToFirebase(
      theFile,
      setIdFrontImage,
      setPreviewIdFront,
      "idFrontImage"
    );
  };

  const onCameraClickIdFront = async () => {
    if (isMobile) {
      const videoElement = document.getElementById("videoPreviewIdFront");
      try {
        const constraints = { video: { facingMode: "environment" } };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoElement.srcObject = stream;
        videoElement.style.display = "block";
        videoElement.onloadedmetadata = () => videoElement.play();

        document.getElementById("captureButtonIdFront").style.display = "block";
        document.getElementById("startCameraButtonIdFront").style.display = "none";
      } catch (error) {
        console.error(t("cameraAccessError"), error);
        alert(t("cameraAccessAlert"));
      }
    } else {
      document.getElementById("fileInputIdFront").click();
    }
  };

  const capturePhotoIdFront = () => {
    const videoElement = document.getElementById("videoPreviewIdFront");
    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;

    canvas.getContext("2d").drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const capturedFile = new File([blob], "IDFront.jpeg", { type: "image/jpeg" });
      setIdFrontFile(capturedFile);
      uploadImageToFirebase(
        capturedFile,
        setIdFrontImage,
        setPreviewIdFront,
        "idFrontImage"
      );
    }, "image/jpeg");

    const tracks = videoElement.srcObject.getTracks();
    tracks.forEach((track) => track.stop());

    videoElement.style.display = "none";
    document.getElementById("captureButtonIdFront").style.display = "none";
  };

  // ---------------------- ID Back Handlers ----------------------
  const handleFileChangeIdBack = (e) => {
    const theFile = e.target.files?.[0];
    if (!theFile) return;

    setIdBackFile(theFile);
    uploadImageToFirebase(
      theFile,
      setIdBackImage,
      setPreviewIdBack,
      "idBackImage"
    );
  };

  const onCameraClickIdBack = async () => {
    if (isMobile) {
      const videoElement = document.getElementById("videoPreviewIdBack");
      try {
        const constraints = { video: { facingMode: "environment" } };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoElement.srcObject = stream;
        videoElement.style.display = "block";
        videoElement.onloadedmetadata = () => videoElement.play();

        document.getElementById("captureButtonIdBack").style.display = "block";
        document.getElementById("startCameraButtonIdBack").style.display = "none";
      } catch (error) {
        console.error(t("cameraAccessError"), error);
        alert(t("cameraAccessAlert"));
      }
    } else {
      document.getElementById("fileInputIdBack").click();
    }
  };

  const capturePhotoIdBack = () => {
    const videoElement = document.getElementById("videoPreviewIdBack");
    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;

    canvas.getContext("2d").drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const capturedFile = new File([blob], "IDBack.jpeg", { type: "image/jpeg" });
      setIdBackFile(capturedFile);
      uploadImageToFirebase(
        capturedFile,
        setIdBackImage,
        setPreviewIdBack,
        "idBackImage"
      );
    }, "image/jpeg");

    const tracks = videoElement.srcObject.getTracks();
    tracks.forEach((track) => track.stop());

    videoElement.style.display = "none";
    document.getElementById("captureButtonIdBack").style.display = "none";
  };

  

  const handlePayerUnselect = () => {
    setSelectedPayer(null);
  };



  const handleMemberIdChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setMemberId(sanitizedValue);
    notifyParent();
  };

  const handlePayerSelect = (payer) => {
    setSelectedPayer(payer);
    console.log("Selected Primary Payer:", payer);
    notifyParent();
  };

  const handleSecondaryMemberIdChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setSecondaryMemberId(sanitizedValue);
    console.log("Updated Secondary Member ID:", sanitizedValue);
    notifyParent();
  };

  const handleSecondaryPayerSelect = (payer) => {
    setSelectedSecondaryPayer(payer);
    console.log("Selected Secondary Payer:", payer);
    notifyParent();
  };



  const handleFileChange = (e, setImage, setPreview, fieldName) => {
    const theFile = e.target.files?.[0];
    if (!theFile) return;
    uploadImageToFirebase(theFile, setImage, setPreview, fieldName);
  };

  
  const notifyParent = () => {
    const data = {
      memberId,
      selectedPayer,
      insuranceCardImage,
      insuranceCardBackImage,
      idFrontImage,
      idBackImage,
      secondaryMemberId, // Ensure this is passed
      selectedSecondaryPayer, // Ensure this is passed
    };
    console.log("Notifying parent with data:", data); // Debug log
    if (onInsuranceInfoChange) {
      onInsuranceInfoChange(data);
    }
  };
  
  
  // ----------------------------------------------------------------------
  //  Render
  // ----------------------------------------------------------------------
  return (
    <>
      <div className="centerHeader">
        <h3 className="mt-4">{t("insurance")}</h3>
      </div>
      <div className="input-group-row">
        <div className="input-field">
          <label htmlFor="memberId">{t("memberId")}</label>
          <input
            type="text"
            name="memberId"
            value={memberId}
            onChange={handleMemberIdChange}
          />
        </div>
      </div>
    
      <div className="input-group-payerSelection">
          <>
            <div className="centerHeader">
            <h3 className="mt-4">{t("selectInsurancePayer")}</h3>
          </div>
          </>
          <div className="input-group-payerSelection">
  {/* Primary Payer Selection */}
  <PayerSelection onSelect={handlePayerSelect} required mode="intake" />

  {/* Add Secondary Payer Button */}
  {!showSecondaryPayer && (
    <button
      type="button"
      className="secondaryButton"
      onClick={() => setShowSecondaryPayer(true)}
    >
      + Add Secondary Payer
    </button>
  )}

  {/* Secondary Payer Fields */}
  {showSecondaryPayer && (
    <div>
      <div className="input-group">
        <label htmlFor="secondaryMemberId">Secondary Member ID</label>
        <input
          id="secondaryMemberId"
          type="text"
          name="secondaryMemberId"
          value={secondaryMemberId}
          onChange={handleSecondaryMemberIdChange}
        />
      </div>

      {/* Secondary Payer Selection */}
      <PayerSelection
         onSelect={handleSecondaryPayerSelect} 
        mode="intake" // Ensuring intake mode
      />
    </div>
  )}
</div>


      </div>
      <div style={{ height: "1px", backgroundColor: "black", margin: "1rem 0" }} />

    
      <div className="twocolumngrid">

{/* Left Column: Insurance Card */}
<div>
  <h3 className="text-center">Insurance Card</h3>

  {/** INSURANCE CARD FRONT **/}
  <div className="input-group">
    <h4>{t("insuranceCardFront")}</h4>
    {isMobile ? (
      <>
        <video
          id="videoPreviewFront"
          width="300"
          height="200"
          autoPlay
          muted
          playsInline
          style={{ display: "none" }}
        ></video>

        {/* If NO insuranceCardImage yet, show "Take Photo" button */}
        {!insuranceCardImage && (
          <button id="startCameraButtonFront" onClick={onCameraClickFront}>
            <FontAwesomeIcon icon={faCamera} />
            {t("takeInsurancePhoto")}
          </button>
        )}

        {/* If user ALREADY has an insuranceCardImage, show "Change Photo" button */}
        {insuranceCardImage && (
          <button id="startCameraButtonFront" onClick={onCameraClickFront}>
            <FontAwesomeIcon icon={faCameraRetro} />
            {t("changeInsurancePhoto")}
          </button>
        )}

        <button
          id="captureButtonFront"
          onClick={capturePhotoFront}
          style={{ display: "none" }}
        >
          {t("capture")}
        </button>
        <input
          type="file"
          id="fileInputFront"
          style={{ display: "none" }}
          accept="image/*"
          capture="camera"
          onChange={handleFileChangeFront}
        />
      </>
    ) : (
      <>
        <input
          type="file"
          id="fileInputFront"
          style={{ display: "none" }}
          accept="image/*"
          capture="camera"
          onChange={handleFileChangeFront}
        />
        <button onClick={onCameraClickFront} className="cameraButton">
          <FontAwesomeIcon
            icon={insuranceCardImage ? faCameraRetro : faCamera}
            style={{ marginRight: ".5rem"}}
          />
          {file ? t("changeInsurancePhoto") : t("takeOrSelectInsurancePhoto")}
        </button>
      </>
    )}
  </div>

  {/** INSURANCE CARD BACK **/}
  <div className="input-group">
    <h4>{t("insuranceCardBack")}</h4>
    {isMobile ? (
      <>
        <video
          id="videoPreviewBack"
          width="300"
          height="200"
          autoPlay
          muted
          playsInline
          style={{ display: "none" }}
        ></video>

        {!insuranceCardBackImage && (
          <button id="startCameraButtonBack" onClick={onCameraClickBack}>
            <FontAwesomeIcon icon={faCamera} />
            {t("takeInsurancePhoto")}
          </button>
        )}
        {insuranceCardBackImage && (
          <button id="startCameraButtonBack" onClick={onCameraClickBack}>
            <FontAwesomeIcon icon={faCameraRetro} />
            {t("changeInsurancePhoto")}
          </button>
        )}

        <button
          id="captureButtonBack"
          onClick={capturePhotoBack}
          style={{ display: "none" }}
        >
          {t("capture")}
        </button>
        <input
          type="file"
          id="fileInputBack"
          style={{ display: "none" }}
          accept="image/*"
          capture="camera"
          onChange={handleFileChangeBack}
        />
      </>
    ) : (
      <>
        <input
          type="file"
          id="fileInputBack"
          style={{ display: "none" }}
          accept="image/*"
          capture="camera"
          onChange={handleFileChangeBack}
        />
        <button onClick={onCameraClickBack} className="cameraButton">
          <FontAwesomeIcon
            icon={insuranceCardBackImage ? faCameraRetro : faCamera}
            style={{ marginRight: ".5rem"}}
          />
          {backFile ? t("changeInsurancePhoto") : t("takeOrSelectInsurancePhoto")}
        </button>
      </>
    )}
  </div>
</div>

{/* Right Column: ID Card */}
<div>
  <h3 className="text-center">ID Card</h3>

  {/** ID FRONT **/}
  <div className="input-group">
    <h4>{t("idCardFront")}</h4>
    {isMobile ? (
      <>
        <video
          id="videoPreviewIdFront"
          width="300"
          height="200"
          autoPlay
          muted
          playsInline
          style={{ display: "none" }}
        ></video>

        {!idFrontImage && (
          <button id="startCameraButtonIdFront" onClick={onCameraClickIdFront}>
            <FontAwesomeIcon icon={faCamera} />
            {t("takeInsurancePhoto")}
          </button>
        )}
        {idFrontImage && (
          <button id="startCameraButtonIdFront" onClick={onCameraClickIdFront}>
            <FontAwesomeIcon icon={faCameraRetro} />
            {t("changeInsurancePhoto")}
          </button>
        )}

        <button
          id="captureButtonIdFront"
          onClick={capturePhotoIdFront}
          style={{ display: "none" }}
        >
          {t("capture")}
        </button>
        <input
          type="file"
          id="fileInputIdFront"
          style={{ display: "none" }}
          accept="image/*"
          capture="camera"
          onChange={handleFileChangeIdFront}
        />
      </>
    ) : (
      <>
        <input
          type="file"
          id="fileInputIdFront"
          style={{ display: "none" }}
          accept="image/*"
          capture="camera"
          onChange={handleFileChangeIdFront}
        />
        <button onClick={onCameraClickIdFront} className="cameraButton">
          <FontAwesomeIcon
            icon={idFrontImage ? faCameraRetro : faCamera}
            style={{ marginRight: ".5rem"}}
          />
          {idFrontFile ? t("changeIDPhoto") : t("takeOrSelectIDPhoto")}
        </button>
      </>
    )}
  </div>

  {/** ID BACK **/}
  <div className="input-group">
    <h4>{t("idCardBack")}</h4>
    {isMobile ? (
      <>
        <video
          id="videoPreviewIdBack"
          width="300"
          height="200"
          autoPlay
          muted
          playsInline
          style={{ display: "none" }}
        ></video>

        {!idBackImage && (
          <button id="startCameraButtonIdBack" onClick={onCameraClickIdBack}>
            <FontAwesomeIcon icon={faCamera} />
            {t("takeInsurancePhoto")}
          </button>
        )}
        {idBackImage && (
          <button id="startCameraButtonIdBack" onClick={onCameraClickIdBack}>
            <FontAwesomeIcon icon={faCameraRetro} />
            {t("changeInsurancePhoto")}
          </button>
        )}

        <button
          id="captureButtonIdBack"
          onClick={capturePhotoIdBack}
          style={{ display: "none" }}
        >
          {t("capture")}
        </button>
        <input
          type="file"
          id="fileInputIdBack"
          style={{ display: "none" }}
          accept="image/*"
          capture="camera"
          onChange={handleFileChangeIdBack}
        />
      </>
    ) : (
      <>
        <input
          type="file"
          id="fileInputIdBack"
          style={{ display: "none" }}
          accept="image/*"
          capture="camera"
          onChange={handleFileChangeIdBack}
        />
        <button onClick={onCameraClickIdBack} className="cameraButton">
          <FontAwesomeIcon
            icon={idBackImage ? faCameraRetro : faCamera}
            style={{ marginRight: ".5rem"}}
          />
          {idBackFile ? t("changeIDPhoto") : t("takeOrSelectIDPhoto")}
        </button>
      </>
    )}
  </div>
</div>
</div>



    </>
  );
}

export default InsuranceInformation;
