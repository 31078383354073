import React, { useState, useEffect } from "react";
import { collection, doc, query, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faClinicMedical, faHospital, faAmbulance, faUserMd, faHome, faEye,
  faFileMedical, faHandshake, faBed, faHeartbeat, faHandHoldingMedical,
  faLaptopMedical, faBuilding, faUserTie, faSmile, faShieldAlt
} from "@fortawesome/free-solid-svg-icons";
import useUID from "../../General/useUID";
import { getFunctions, httpsCallable } from "firebase/functions";
import "./Encounters.css";
import { db } from "../../../firebase";

const functions = getFunctions();
const decryptFunction = httpsCallable(functions, 'decrypt');

// Mapping of encounter types to FontAwesome icons
const faIcons = {
  "Outpatient Visit": faClinicMedical,
  "Inpatient Admission": faHospital,
  "Emergency Room Visit": faAmbulance,
  "Telemedicine Visit": faLaptopMedical,
  "Home Health Visit": faHome,
  "Ambulatory Care": faUserMd,
  "Day Surgery": faClinicMedical,
  "Observation": faEye,
  "Ancillary Services": faFileMedical,
  "Follow-up Visit": faHandshake,
  "Preventative Visit": faShieldAlt,
  "Rehabilitation Visit": faHandHoldingMedical,
  "Referral Visit": faBuilding,
  "Urgent Care Visit": faAmbulance,
  "Post-operative Visit": faHeartbeat,
  "Nursing Home Visit": faBed,
  "Routine Check-up": faSmile,
  "Maternity & Antenatal Visits": faUserTie,
};

function EncountersView({ patientId }) {
  const [encounters, setEncounters] = useState([]);
  const [openEncounterId, setOpenEncounterId] = useState(null);
  const [uid, subUserUID, isLoading, error] = useUID();

  useEffect(() => {
    if (!uid) return;
    if (!patientId) {
      setEncounters([]); // Clear encounters when patientId is not available
      return;
    }
    const encountersRef = collection(
      doc(db, "patients", uid, "patientData", patientId),
      "encounters"
    );
  
    const encountersQuery = query(encountersRef);
  
    const unsubscribe = onSnapshot(encountersQuery, async (snapshot) => {
      const loadedEncounters = [];
      for (const doc of snapshot.docs) {
        const encounter = doc.data();
        if (encounter.ciphertext && encounter.iv) {
          try {
            const encryptedData = {
              ciphertext: encounter.ciphertext,
              iv: encounter.iv,
            };
            const decryptedResponse = await decryptFunction(encryptedData);
            const decryptedData = decryptedResponse.data;
            loadedEncounters.push({
              id: doc.id,
              ...decryptedData,
              timestamp: encounter.timestamp?.toDate(),
            });
          } catch (error) {
            console.error("Error decrypting encounter data: ", error);
          }
        } else {
          loadedEncounters.push({
            id: doc.id,
            ...encounter,
            timestamp: encounter.timestamp?.toDate(),
          });
        }
      }
  
      // Sort encounters by timestamp in descending order (newest first)
      loadedEncounters.sort((a, b) => b.timestamp - a.timestamp);
  
      const providerDataPromises = loadedEncounters.map(async (encounter) => {
        if (!encounter.selectedProviders || !encounter.selectedProviders.providerId) {
          return { organizationName: null, firstName: null, lastName: null };
        }
        const providerDocRef = doc(
          db,
          "users",
          uid,
          "providers",
          encounter.selectedProviders.providerId
        );
        const providerSnapshot = await getDoc(providerDocRef);
        return providerSnapshot.exists()
          ? providerSnapshot.data()
          : { organizationName: null, firstName: null, lastName: null };
      });
  
      const providersData = await Promise.all(providerDataPromises);
  
      const encountersWithProviders = loadedEncounters.map(
        (encounter, index) => ({
          ...encounter,
          ...providersData[index],
        })
      );
  
      setEncounters(encountersWithProviders);
    });
  
    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [uid, patientId]);
  

  const handleToggle = (id) => {
    if (openEncounterId === id) {
      setOpenEncounterId(null);
    } else {
      setOpenEncounterId(id);
    }
  };

  const handleDelete = async (encounterId) => {
    try {
      const encounterDocRef = doc(
        db,
        "patients",
        uid,
        "patientData",
        patientId,
        "encounters",
        encounterId
      );

      await updateDoc(encounterDocRef, {
        isDeleted: true,
        BillingStatus: "Deleted",
      });
    } catch (error) {
      console.error("Error deleting encounter:", error);
    }
  };

  const handleToggleBillingStatus = async (encounterId, currentStatus) => {
    try {
      const encounterDocRef = doc(
        db,
        "patients",
        uid,
        "patientData",
        patientId,
        "encounters",
        encounterId
      );

      const newStatus = currentStatus === "NotBilled" ? "Billed" : "NotBilled";
      await updateDoc(encounterDocRef, {
        BillingStatus: newStatus,
      });
    } catch (error) {
      console.error("Error updating billing status:", error);
    }
  };

  // Utility function to format duration in minutes and seconds
  const formatDuration = (duration) => {
    if (!duration || duration === "00:00:00") {
      return null; // Return null if duration is invalid or zero
    }
    const [hours, minutes, seconds] = duration.split(":");
    const totalSeconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    const mins = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;
    return `${mins}m ${secs}s`;
  };

  return (
    <div className="encountersContainer">
      {encounters.length > 0 && encounters.filter((encounter) => !encounter.isDeleted).length > 0 && (
        <>
          <h3>Encounters</h3>
          <div
            style={{
              height: "1px",
              backgroundColor: "black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>
        </>
      )}
      {encounters
        .filter((encounter) => !encounter.isDeleted)
        .map((encounter) => (
          <div
            key={encounter.id}
            className={`encounterCard ${
              encounter.BillingStatus && encounter.BillingStatus === "NotBilled"
                ? "notBilled"
                : ""
            }`}
            onClick={() => handleToggle(encounter.id)}
            title={"Click to expand"}
          >
            <div className="serviceAndDateContainer">
              {faIcons[encounter.encounterType] && (
                <FontAwesomeIcon
                  icon={faIcons[encounter.encounterType]}
                  className="encounterIcon"
                  title={encounter.encounterType}
                />
              )}
              {encounter.billingCode && encounter.billingCode.length > 0 && (
                <p>{encounter.billingCode[0].name}</p>
              )}
              <p>{encounter.dateOfService}</p>
              <span
                className="toggleBillingStatus"
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleBillingStatus(
                    encounter.id,
                    encounter.BillingStatus
                  );
                }}
                title={"Click to mark as billed."}
              >
                $
              </span>
            </div>
            {openEncounterId === encounter.id && (
              <div className="expandedEncounterDetails">
                {encounter.reasonForVisit && encounter.reasonForVisit.trim() && (
                  <p>
                    <strong>Reason for Visit:</strong> {encounter.reasonForVisit}
                  </p>
                )}
                {encounter.duration && formatDuration(encounter.duration) && (
                  <p>
                    <strong>Duration:</strong> {formatDuration(encounter.duration)}
                  </p>
                )}
                <p>
                  <strong>Encounter Type:</strong> {encounter.encounterType}
                </p>
                {encounter.organizationName && (
                  <p>
                    <strong>Provider Organization:</strong> {encounter.organizationName}
                  </p>
                )}
                {(encounter.providerfirstName || encounter.providerlastName) && (
                  <p>
                    <strong>Provider:</strong> {encounter.providerfirstName} {encounter.providerlastName}
                  </p>
                )}
                {encounter.notes && encounter.notes.trim() && (
                  <p>
                    <strong>Notes:</strong> {encounter.notes}
                  </p>
                )}
                {encounter.billingCode && encounter.billingCode.length > 0 && (
                  <div>
                    <strong>Billing Codes:</strong>
                    <ul>
                      {encounter.billingCode.map((code) => (
                        <li key={code.id}>
                          {code.name} ({code.code}) - {code.billAmount} USD
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <p>
                  <strong>Encounter ID:</strong> {encounter.encounterId}
                </p>
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

export default EncountersView;
