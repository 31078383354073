import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import PayerSelection from "../Payers/PayerSelection";
import useUID from "../General/useUID";
import { useNavigate } from "react-router-dom";
import HelpArticleLink from "../Articles/HelpArticleLink";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../General/Animations/Hexspinner";
import './PatientView.module.css';

const functions = getFunctions();

function EditPatientPage({
  handleSave,
  patientId,
  firstName,
  middleName = "",
  lastName,
  dob,
  gender,
  memberid,
  address1,
  address2,
  city,
  state,
  zip,
  tradingPartnerName,
  email,
  phone,
  handleShowEditPage,
  handleDelete,
  onClose,
  onDataUpdated,
}) {
  const [uid, subUserUID] = useUID();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false); // State to handle deleted status
  const [selectedSecondaryPayer, setSelectedSecondaryPayer] = useState(null);
  const [secondaryMemberId, setSecondaryMemberId] = useState("");
  const [showSecondaryPayer, setShowSecondaryPayer] = useState(false);
  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        const patientRef = doc(db, "patients", uid, "patientData", patientId);
        const patientDoc = await getDoc(patientRef);
        if (patientDoc.exists()) {
          setIsDeleted(patientDoc.data().deleted);
          const patientDataEncrypted = patientDoc.data().patient;
          if (patientDataEncrypted) {
            const decryptFunction = httpsCallable(functions, "decrypt");
            const decryptionResult = await decryptFunction(patientDataEncrypted);
            const decryptedData = decryptionResult.data;
  
            // Convert DOB to the correct format for the date input
            const formattedDOB = decryptedData.patient.dob
              ? convertDateFormat(decryptedData.patient.dob)
              : "";
  
            // Set patientData state
            setPatientData({
              patient: {
                ...decryptedData.patient,
                dob: formattedDOB, // Use the formatted DOB
              },
            });
  
            // Set payerData state
            setPayerData({
              memberId: decryptedData.payers.memberId || "",
              name: decryptedData.payers.name || "",
              CPID: decryptedData.payers.CPID || "",
              RealtimePayerID: decryptedData.payers.RealtimePayerID || "",
              ClaimStatusPayerID: decryptedData.payers.ClaimStatusPayerID || "",
              TradingPartnerID: decryptedData.payers.TradingPartnerID || "",
            });
  
            // Set secondary payer info if it exists
            if (decryptedData.payers.secondaryPayer) {
              setSelectedSecondaryPayer({
                name: decryptedData.payers.secondaryPayer.name,
                CPID: decryptedData.payers.secondaryPayer.CPID || "",
                RealtimePayerID: decryptedData.payers.secondaryPayer.RealtimePayerID || "",
                ClaimStatusPayerID: decryptedData.payers.secondaryPayer.ClaimStatusPayerID || "",
                TradingPartnerID: decryptedData.payers.secondaryPayer.TradingPartnerID || "",
              });
              setSecondaryMemberId(decryptedData.payers.secondaryPayer.memberId || "");
              setShowSecondaryPayer(true);
            } else {
              // If no secondary payer, ensure the secondary payer fields are reset
              setSelectedSecondaryPayer(null);
              setSecondaryMemberId("");
              setShowSecondaryPayer(false);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching patient data: ", error);
      }
    };
  
    fetchPatientData();
  }, [uid, patientId]);
  

  // Utility function to convert DOB format from MM/DD/YYYY to YYYY-MM-DD for input[type="date"]
const convertDateFormat = (inputDate) => {
  if (inputDate.includes('-')) {
    // This assumes the input is in YYYY-MM-DD format
    const [year, month, day] = inputDate.split("-");
    return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
  } else {
    // This assumes the input is in MM/DD/YYYY format
    const [month, day, year] = inputDate.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }
};


  const navigate = useNavigate();

  // Convert DOB to the correct format for the date input
  const formattedDOB = dob ? convertDateFormat(dob) : "";

  const initialPatientData = {
    patient: {
      firstName,
      middleName, // Initialize with the provided value or empty string
      lastName,
      dob: formattedDOB,
      gender,
      email: email || "", // Initialize optional fields as empty strings if undefined
      phone: phone || "",
      address: {
        address1: address1 || "",
        address2: address2 || "",
        city: city || "",
        state: state || "",
        zip: zip || "",
      },
    },
    payers: {
      memberId: memberid,
      name: tradingPartnerName,
    },
  };

  const [patientData, setPatientData] = useState(initialPatientData);
  const [errorMsg, setErrorMsg] = useState(null);
  const [payerData, setPayerData] = useState({
    memberId: memberid || "",
    name: tradingPartnerName || "", // Similarly for tradingPartnerName
  });
  const [selectedPayer, setSelectedPayer] = useState(null); // Renamed for clarity

  const formatDateToDb = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${month}/${day}/${year}`;
  };
  
  

  const getValidationErrors = () => {
    if (!patientData?.patient) return "Patient data is missing or incomplete.";
  
    const { firstName, lastName, gender, dob, address } = patientData.patient;
  
    // Ensure required fields are present and trimmed (to catch names with only spaces)
    if (!firstName.trim() || !lastName.trim() || !gender || !dob) {
      return "Required fields (First Name, Last Name, Gender, Date of Birth) are missing or invalid.";
    }
  
    // Validate state abbreviation
    if (address?.state && address.state.length !== 2) {
      return "State must be a 2-letter abbreviation.";
    }
  
    // Validate ZIP code based on environment
    const zip = address?.zip ? String(address.zip).trim() : "";
    if (zip && zip.length !== 5) {
      return "ZIP code must be exactly 5 digits.";
    }
  
    // Validate secondary payer if visible
    if (showSecondaryPayer && (!selectedSecondaryPayer || !secondaryMemberId.trim())) {
      return "Both Secondary Payer and Secondary Member ID must be provided.";
    }
  
    return null; // No errors found
  };
  
  

  const handlePayerSelect = (payer) => {
    if (!payer) {
      console.error("No payer selected");
      setSelectedPayer(null); // Reset the selected payer if none is selected
      setPayerData({
        name: "",
        memberId: "",
        CPID: "",
        RealtimePayerID: "",
        ClaimStatusPayerID: "",
        TradingPartnerID: "",
      });
      return;
    }

    setSelectedPayer(payer); // Update the selected payer
    setPayerData((prev) => ({
      ...prev,
      name: payer.name, // Update the payer name based on the selected payer
      memberId: payer.memberId || prev.memberId, // Optionally update memberId, if it's part of the payer object
      CPID: payer.CPID || "",
      RealtimePayerID: payer.RealtimePayerID || "",
      ClaimStatusPayerID: payer.ClaimStatusPayerID || "",
      TradingPartnerID: payer.CPID || "",
    }));
  };

  const handleInputChange = (event, field, subfield = null, isPayerData = false) => {
    const value = event.target.value;
    if (isPayerData) {
      setPayerData((prevData) => ({ ...prevData, [field]: value }));
    } else if (subfield) {
      setPatientData((prevData) => ({
        ...prevData,
        patient: {
          ...prevData.patient,
          [field]: {
            ...prevData.patient[field],
            [subfield]: value
          }
        }
      }));
    } else {
      setPatientData((prevData) => ({
        ...prevData,
        patient: {
          ...prevData.patient,
          [field]: value
        }
      }));
    }
  };

  const saveEditedPatientData = async () => {
    setIsLoading(true);
    try {
      const validationErrors = getValidationErrors();
      if (validationErrors) {
        console.error("Validation Error:", validationErrors);
        setErrorMsg(validationErrors);
        return;
      }
  
      // Convert DOB to UTC format before saving
      const utcDOB = formatDateToDb(patientData.patient.dob);
  
      // Organize data into one 'patient' object with nested 'patient' and 'payers' data
      const fullPatientData = {
        patient: {
          ...patientData.patient,
          dob: utcDOB, // Use the UTC converted date
        },
        payers: {
          ...payerData, // Original payer data
          ...(selectedSecondaryPayer && {
            secondaryPayer: {
              name: selectedSecondaryPayer.name,
              memberId: secondaryMemberId,
              CPID: selectedSecondaryPayer.CPID,
              RealtimePayerID: selectedSecondaryPayer.RealtimePayerID,
              ClaimStatusPayerID: selectedSecondaryPayer.ClaimStatusPayerID,
              TradingPartnerID: selectedSecondaryPayer.TradingPartnerID,            
            },
          }),
        },
      };
  
      // Reference to the Firestore document
      const patientDataRef = doc(db, "patients", uid, "patientData", patientId);
  
      // Encrypt the full patient data using a Firebase Cloud Function
      const encryptFunction = httpsCallable(functions, "encrypt");
      const encryptionResult = await encryptFunction(fullPatientData);
      const { iv, ciphertext } = encryptionResult.data; // Deconstruct to get only the IV and ciphertext
  
      // Update the Firestore document with the encrypted data
      await updateDoc(patientDataRef, { patient: { iv, ciphertext } });
  
      handleSave(iv, ciphertext); // Optionally handle the save with encrypted data for local processing
      navigate(`/patients/${patientId}`); // Navigate after successful save
      onDataUpdated(); // Callback to indicate data has been updated
      onClose(); // Close the modal or form
    } catch (error) {
      console.error("Error updating patient data:", error);
      setErrorMsg("Failed to update patient data. Please try again.");
      setIsLoading(false);
    }
  };  
  

  const handleRecover = async () => {
    setIsLoading(true);
    try {
      const patientDataRef = doc(db, "patients", uid, "patientData", patientId);
      await updateDoc(patientDataRef, { deleted: false });
      setIsDeleted(false);
      onDataUpdated(); // Callback to indicate data has been updated
    } catch (error) {
      console.error("Error recovering patient: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveSecondaryPayer = () => {
    setSelectedSecondaryPayer(null);
    setSecondaryMemberId("");
    setShowSecondaryPayer(false);
  };
  

  return (
    <div className="popupContainer">
         <div className="closeButtonContainer">
          <button onClick={onClose} className="filesCloseButton">
            X
          </button>
          <p className="closeBarNav">Edit Patient</p>
        </div>
      <div className="popupContent">
        <div className="editRow">
          {isDeleted && (
            <div className="RecoverPatientsButton">
              <span className="deleteText" onClick={handleRecover}>
                Recover
              </span>
            </div>
          )}
          {!isDeleted && (
          <div className="DeletePatientsButton">
            <span className="deleteText" onClick={handleDelete}>
              Delete
            </span>
          </div>
          )}
              {!isLoading && (
                <button onClick={saveEditedPatientData} className="done-button">
                  Done
                </button>
          )}
        </div>
    

        <form
          className="editPatient"
          onSubmit={(event) => {
            event.preventDefault();
            saveEditedPatientData();
          }}
        >
          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                value={patientData.patient.firstName || ""}
                onChange={(event) => handleInputChange(event, "firstName")}
              />
            </div>

            <div className="input-field">
              <label htmlFor="middleName">Middle Name</label>
              <input
                type="text"
                value={patientData.patient.middleName || ""}
                onChange={(event) => handleInputChange(event, "middleName")}
                style={{width: "5rem", minWidth: "5rem"}}
              />
            </div>

            <div className="input-field">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                value={patientData.patient.lastName || ""}
                onChange={(event) => handleInputChange(event, "lastName")}
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                value={patientData.patient.dob || ""} // Use the state value
                onChange={(event) => handleInputChange(event, "dob")}
              />
            </div>

            {/* Gender Buttons */}
            <div className="input-field">
              <div className="gender-buttons">
                <button
                  type="button"
                  className={`gender-button ${patientData.patient.gender === "M" ? "selected" : ""}`}
                  onClick={() =>
                    handleInputChange({ target: { value: "M" } }, "gender")
                  }
                >
                  M
                </button>
                <button
                  type="button"
                  className={`gender-button ${patientData.patient.gender === "F" ? "selected" : ""}`}
                  onClick={() =>
                    handleInputChange({ target: { value: "F" } }, "gender")
                  }
                >
                  F
                </button>
              </div>
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="address1">Address 1</label>
              <input
                type="text"
                value={patientData.patient.address.address1 || ""}
                onChange={(event) =>
                  handleInputChange(event, "address", "address1")
                }
              />
            </div>

            <div className="input-field">
              <label htmlFor="address2">Address 2</label>
              <input
                type="text"
                value={patientData.patient.address.address2 || ""}
                onChange={(event) =>
                  handleInputChange(event, "address", "address2")
                }
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="city">City</label>
              <input
                type="text"
                value={patientData.patient.address.city || ""}
                onChange={(event) =>
                  handleInputChange(event, "address", "city")
                }
              />
            </div>

            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor="state">State</label>
                <select
                  type="text"
                  name="state"
                  value={patientData.patient.address.state || ""}
                  onChange={(event) =>
                    handleInputChange(event, "address", "state")
                  }
                >
                  <option value="">Select a State</option> {/* Default option */}
                  {states.map((s) => (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-field">
                <label htmlFor="zip">Zip</label>
                <input
                  type="text"
                  id="zip"
                  value={patientData.patient.address.zip || ""}
                  onChange={(event) =>
                    handleInputChange(event, "address", "zip")
                  }
                  maxLength={process.env.NODE_ENV === "development" ? "5" : "5"}
                  pattern={process.env.NODE_ENV === "development" ? "^\\d{5}$" : "^\\d{5}$"}
                  title={
                    process.env.NODE_ENV === "development"
                      ? "ZIP should be exactly 9 digits in development mode."
                      : "ZIP should be exactly 5 digits in production mode."
                  }
                  onInput={(event) => {
                    // Restrict input to only numeric characters
                    event.target.value = event.target.value.replace(/[^0-9]/g, "");
                  }}
                  placeholder="ZIP Code"
                />
              </div>
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                value={patientData.patient.email || ""}
                onChange={(event) => handleInputChange(event, "email")}
              />
            </div>
            <div className="input-field">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                value={patientData.patient.phone || ""}
                onChange={(event) => handleInputChange(event, "phone")}
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="memberId">MemberId</label>
              <input
                type="text"
                id="memberId"
                value={payerData.memberId}
                onChange={(event) => {
                  const sanitizedValue = event.target.value.replace(
                    /[^a-zA-Z0-9]/g,
                    ""
                  ); // Sanitize the input to allow alphanumeric only
                  handleInputChange(
                    { target: { value: sanitizedValue } },
                    "memberId",
                    null,
                    true // Indicate that this change affects payerData
                  );
                }}
              />
            </div>
          </div>

          {/* Payer Selection */}
          <div className="edit-group-payerSelection content-center">
            <PayerSelection
              mode={"all"}
              TradingPartnerName={payerData.name}
              onSelect={handlePayerSelect}
              required
            />
          </div>

          {!showSecondaryPayer && (
            <div className="input-group-row content-center">
              <button
                type="button"
                className="secondaryButton"
                onClick={() => setShowSecondaryPayer(true)}
              >
                + Add Secondary Payer
              </button>
            </div>
          )}

          {showSecondaryPayer && (
            <div>
              <div className="input-group-row">
                <div className="input-field">
                  <label htmlFor="secondaryMemberId">Secondary Member ID</label>
                  <input
                    type="text"
                    id="secondaryMemberId"
                    value={secondaryMemberId}
                    onChange={(event) => {
                      const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
                      setSecondaryMemberId(sanitizedValue);
                    }}
                  />
                </div>
              </div>
              <div className="edit-group-payerSelection content-center">
                <PayerSelection
                  mode={"all"}
                  TradingPartnerName={selectedSecondaryPayer?.name}
                  onSelect={(payer) => setSelectedSecondaryPayer(payer)}
                />
                  <div className="secondaryButtonRemoveDIV content-center">
                <span
                  className="removeSecondaryPayerButton"
                  onClick={() => handleRemoveSecondaryPayer()}
                >
                  Remove Secondary Payer
                </span>
              </div>
              </div>
            
            </div>
          )}




          {errorMsg && <div className="error-message">{errorMsg}</div>}

          {!isLoading && (
            <div className="input-group-row">
              <div className="input-field">
                <button type="submit" className="done-button">
                  Done
                </button>
              </div>
            </div>
          )}
          {isLoading && <HexagonSpinner />}

          <div className="help-article-wide">
            <HelpArticleLink
              article={{
                title: "Editing or Deleting Patients",
                link: "https://popularishealth.com/article/Editing-or-Deleting-Patients",
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditPatientPage;
