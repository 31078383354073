import React, { useState, useEffect } from "react";
import { PosCodeDropdown } from '../Claims/Subcomponents/PosCodes';
import HexagonSpinner from '../General/Animations/Hexspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth, db } from "../../firebase";
import {
    collection,
    onSnapshot,
    getDocs,
    setDoc,
    addDoc,
    deleteDoc,
    doc,
    updateDoc
} from "firebase/firestore";
import useUID from '../General/useUID';
import InputMask from 'react-input-mask';

function ProviderForm(props) {
    const { provider, onClose } = props;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [npi, setNpi] = useState('');
    const [ein, setEin] = useState('');
    const [medicareNumber, setMedicareNumber] = useState('');
    const [medicaidNumber, setMedicaidNumber] = useState('');
    const [cliaNumber, setCliaNumber] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedPosCode, setSelectedPosCode] = useState('');
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editing, setEditing] = useState(false);
    const [currentEditingId, setCurrentEditingId] = useState(null);
    const [uid, subUserUID, isLoading] = useUID();
    const [loading, setLoading] = useState(null);
    const [linkRequired, setLinkRequired] = useState(false);
    const [isDefaultProvider, setIsDefaultProvider] = useState(true);

    const functions = getFunctions();

    const states = [
        'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
        'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
        'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
        'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
        'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
    ];

    const providersRef = collection(db, `users/${uid}/providers`);

    useEffect(() => {
        if (provider) {
            populateFormForEdit(provider);
            setEditing(true);
        }
    }, [uid, provider]);

    const populateFormForEdit = (provider) => {
        setOrganizationName(provider.organizationName);
        setFirstName(provider.firstName);
        setLastName(provider.lastName);
        setNpi(provider.npi);
        setEin(provider.ein);
        setAddress1(provider.address1);
        setAddress2(provider.address2 || "");
        setCity(provider.city);
        setState(provider.state);
        setZip(provider.zip);
        setPhone(provider.phone);
        setSelectedPosCode(provider.posCode);
        setMedicareNumber(provider.medicareNumber || "");
        setMedicaidNumber(provider.medicaidNumber || "");
        setCliaNumber(provider.cliaNumber || "");
        setEditing(true);
        setCurrentEditingId(provider.id);
    };

    const validateAddress = (address, city, state, zip) => {
        let errors = [];
    
        if (!address.trim()) {
            errors.push("Address is required.");
        }
    
        if (!city.trim()) {
            errors.push("City is required.");
        }
    
        if (!state.trim()) {
            errors.push("State is required.");
        }
    
        if (!zip.trim()) {
            errors.push("Zip code is required.");
        } else if (!/^\d{9}$/.test(zip)) {
            errors.push("Invalid zip code format. Must be exactly 9 digits (xxxxxxxxx).");
        }
    
        return errors;
    };
    

    const closeAddProvider = () => {
        if (onClose) onClose();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setIsSubmitting(true);

        const npiUpperCase = npi.toUpperCase();

        // Basic validations for NPI and EIN
        if (npi.length !== 10) {
            setError('NPI must be 10 digits.');
            setIsSubmitting(false);
            return;
        }

        if (ein.length !== 9) {
            setError('EIN must be 9 digits.');
            setIsSubmitting(false);
            return;
        }

        const addressErrors = validateAddress(address1, city, state, zip);
        if (addressErrors.length > 0) {
            setError(addressErrors.join(' '));
            setIsSubmitting(false);
            return;
        }

        try {
            const verifyNpi = httpsCallable(functions, "verifyNpi");
            const result = await verifyNpi({ npi: npiUpperCase });

            if (!result.data.valid) {
                setError("NPI verification failed.");
                setLinkRequired(false);
                setIsSubmitting(false);
                return;
            }

            const npiData = result.data.npiData;
            let matchFound = false;

            for (const record of npiData.results) {
                const basic = record.basic;
                if (basic.organization_name?.toUpperCase() === organizationName.toUpperCase() ||
                    (basic.first_name?.toUpperCase() === firstName.toUpperCase() &&
                     basic.last_name?.toUpperCase() === lastName.toUpperCase()) ||
                    (basic.authorized_official_first_name?.toUpperCase() === firstName.toUpperCase() &&
                     basic.authorized_official_last_name?.toUpperCase() === lastName.toUpperCase())) {
                    matchFound = true;
                    break;
                }
            }

            if (!matchFound) {
                setError("NPI verification passed, but details do not match our records.");
                setLinkRequired(false);
                setIsSubmitting(false);
                return;
            }

        } catch (error) {
            console.error("Error during NPI verification:", error);
            setError("Error during NPI verification. Please try again.");
            setLinkRequired(false);
            setIsSubmitting(false);
            return;
        }

        try {
            if (!editing) { // Only check the limiter for new providers
                const limiterResponse = await httpsCallable(functions, 'limiter')({
                    userId: uid,
                    limiterTag: 'provider'
                });
            
                if (limiterResponse.data.result === false) {
                    setError(`You've reached the limit for adding providers at your tier.`);
                    setLinkRequired(true);
                    setIsSubmitting(false);
                    return;
                }
            }            

        } catch (error) {
            console.error("Error calling limiter function:", error);
            setError("Error calling limiter function. Please try again.");
            setIsSubmitting(false);
            return;
        }

        setError("");
        setIsSubmitting(true);

        const providerData = {
            organizationName,
            firstName,
            lastName,
            npi,
            ein,
            address1,
            address2,
            city,
            state,
            zip,
            phone,
            posCode: selectedPosCode,
        };

        if (medicareNumber) providerData.medicareNumber = medicareNumber;
        if (medicaidNumber) providerData.medicaidNumber = medicaidNumber;
        if (cliaNumber) providerData.cliaNumber = cliaNumber;

        try {
            const encrypt = httpsCallable(functions, 'encrypt');
            const encryptedResponse = await encrypt(providerData);
            if (!encryptedResponse.data) {
                setError('Failed to encrypt data.');
                setIsSubmitting(false);
                return;
            }

            const ciphertext = encryptedResponse.data.ciphertext;
            const iv = encryptedResponse.data.iv;

            if (isDefaultProvider) {
                const allProvidersSnapshot = await getDocs(providersRef);
                for (const docSnapshot of allProvidersSnapshot.docs) {
                    if (docSnapshot.id !== currentEditingId) {
                        await updateDoc(doc(db, `users/${uid}/providers`, docSnapshot.id), { isDefault: false });
                    }
                }
            }

            const savePayload = { ciphertext, iv, isDefault: isDefaultProvider }

            if (editing) {
                const providerDocRef = doc(db, `users/${uid}/providers`, currentEditingId);
                await setDoc(providerDocRef, savePayload);
                setEditing(false);
                setCurrentEditingId(null);

            } else {
                await addDoc(providersRef, savePayload);
            }

            setOrganizationName("");
            setFirstName("");
            setLastName("");
            setNpi("");
            setEin("");
            setAddress1("");
            setAddress2("");
            setCity("");
            setState("");
            setZip("");
            setPhone("");
            setSelectedPosCode("");
            setEditing(false);
            setCurrentEditingId(null);

        } catch (error) {
            console.error("Error adding provider:", error);
            setError("Error adding provider. Please try again.");
        } finally {
            setIsSubmitting(false);
            onClose(true);
        }
    };

    const confirmDefaultProvider = () => {
        setIsDefaultProvider(true);
    };

    const notDefaultProvider = () => {
        setIsDefaultProvider(false);
    };

    return (
        <div className="add-popup over-add-popup">
            <div className="add-form">
                <div className="content-center">
                    <button className='filesCloseButton' onClick={closeAddProvider} >X</button>
                    <h3 style={{ marginTop: 30 }}>Add Provider</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="organizationName">Organization Name</label>
                                <input
                                    type="text"
                                    name="organizationName"
                                    value={organizationName}
                                    onChange={(e) => setOrganizationName(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="npi">NPI</label>
                                <input
                                    type="text"
                                    name="npi"
                                    maxLength='10'
                                    pattern="^\d{10}$"
                                    value={npi}
                                    onChange={(e) => setNpi(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="ein">Tax ID</label>
                                <input
                                    type="text"
                                    name="ein"
                                    maxLength='9'
                                    pattern="^\d{9}$"
                                    value={ein}
                                    onChange={(e) => setEin(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="medicareNumber">Medicare ID (optional)</label>
                                <input
                                    type="text"
                                    name="medicareNumber"
                                    value={medicareNumber}
                                    onChange={(e) => setMedicareNumber(e.target.value)}
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="medicaidNumber">Medicaid Provider Number (optional)</label>
                                <input
                                    type="text"
                                    name="medicaidNumber"
                                    value={medicaidNumber}
                                    onChange={(e) => setMedicaidNumber(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="cliaNumber">CLIA Number (optional)</label>
                                <input
                                    type="text"
                                    name="cliaNumber"
                                    value={cliaNumber}
                                    onChange={(e) => setCliaNumber(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="address1">Address 1</label>
                                <input
                                    type="text"
                                    name="address1"
                                    value={address1}
                                    onChange={(e) => setAddress1(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="address2">Address 2</label>
                                <input
                                    type="text"
                                    name="address2"
                                    value={address2}
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="city">City</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="state">State</label>
                                <select 
                                    name="state" 
                                    className="w-100" 
                                    value={state} 
                                    onChange={(e) => setState(e.target.value)} 
                                    required
                                >
                                    <option value="">Select a State</option>
                                    {states.map(s => <option key={s} value={s}>{s}</option>)}
                                </select>
                            </div>
                            <div className="input-field">
                                <label htmlFor="zip">Zip (9 digit)</label>
                                <input
                                    type="text"
                                    name="zip"
                                    value={zip}
                                    onChange={(e) => setZip(e.target.value)}
                                    required
                                    maxLength='9'
                                    pattern="^\d{9}$"
                                    title="ZIP code must be 9 digits (xxxxxxxxx)"
                                />
                            </div>
                        </div>

                        <div className="input-field">
                            <label htmlFor="phone">Phone</label>
                            <InputMask
                                id="phone"
                                mask="(999) 999-9999"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>

                        <div className="input-field">
                            <label htmlFor="">Place of Service</label>
                            <PosCodeDropdown
                                selectedValue={selectedPosCode}
                                onChange={(e) => setSelectedPosCode(e.target.value)}
                            />
                        </div>

                        <h4>Make this my default provider?</h4>
                        <div className="gender-buttons">
                            <button
                                type="button"
                                className={`gender-button ${isDefaultProvider ? "selected" : ""}`}
                                onClick={confirmDefaultProvider}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className={`gender-button ${!isDefaultProvider ? "selected" : ""}`}
                                onClick={notDefaultProvider}
                            >
                                No
                            </button>
                        </div>

                        {error && (
                            <div className="error">
                                {error}
                                {linkRequired && (
                                    <>
                                        {' '}
                                        Please{' '}
                                        <a href="https://popularishealth.com/payment" target="_blank" rel="noopener noreferrer">
                                            upgrade your account
                                        </a>.
                                    </>
                                )}
                            </div>
                        )}

                        {isSubmitting ? (
                            <>
                                <HexagonSpinner />
                                <p className="authMessage">Verifying Provider NPI...</p>
                            </>
                        ) : (
                            <div className='display-flex mt-3'>
                                <button className='primary' type="submit">
                                    {editing ? (
                                        <>
                                            <FontAwesomeIcon icon={faPencilAlt} className='mr-3' />
                                            Update Provider
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faPlus} className='mr-3' />
                                            Add Provider
                                        </>
                                    )}
                                </button>

                                <button type="button" onClick={closeAddProvider} className='primary'>
                                    Cancel
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ProviderForm;
