import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../subcomponents/Footer';
import Header from '../subcomponents/Header';
import clinics from '../assets/iphone_w_clinics.jpg';
import clinicsLaptop from '../assets/Macbook_clinics.png';
import AIInput from '../assets/note_input.JPG'
import Patients from '../assets/Macbook_patients.png'
import TypingText from '../../AI/UI components/TypingText';
import Activities from '../assets/activities.JPG'
import MedicalHistory from '../assets/medical-history.JPG'
import telehealth from '../assets/telehealth.jpg'
import zoom from '../assets/Zoom-Logo.png'
import HIPAA from '../assets/hipaa-compliance.png'
import FormEditor from '../../PatientFiles/FormEditor/FormEditor/FormEditor';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassDollar, faHeadset, faFileInvoiceDollar, faStickyNote, faStaffSnake, faVideo, faHospital, faHeart, faBrain, faLock, faBoltLightning, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import EncryptionSample from '../subcomponents/EncryptionSample';

const TryPatients = () => {
    const [isVisible, setIsVisible] = useState({});
    const sectionRefs = useRef({});
    const [formData, setFormData] = useState(null);
    const typingContainerRef = useRef(null);
    const [email, setEmail] = useState(localStorage.getItem('email') || new URLSearchParams(location.search).get('email') || '');
    const navigate = useNavigate();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setIsVisible((prev) => ({ ...prev, [entry.target.id]: entry.isIntersecting }));
                });
            },
            { threshold: 0.1, rootMargin: '-50px' }
        );

        Object.values(sectionRefs.current).forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const scrollToBottom = () => {
            if (typingContainerRef.current) {
                typingContainerRef.current.scrollTop = typingContainerRef.current.scrollHeight;
            }
        };

        if (isVisible['ai-notes']) {
            const intervalId = setInterval(scrollToBottom, 100); // Adjust interval as needed
            return () => clearInterval(intervalId);
        }
    }, [isVisible['ai-notes']]);

    const message = `
Patient Name: John Doe
Date: 2/26/2024
Subject: Medical Necessity Letter for T1 Anatomical MRI

Dear United Healthcare,

I am writing to request authorization for my patient, John Doe, to undergo a T1 anatomical MRI due to a traumatic brain injury they sustained on 2/24. 

As John Doe's healthcare provider, I have evaluated their condition and determined that an MRI is necessary to assess the extent of the brain injury and develop an appropriate treatment plan. Anatomical imaging is crucial in this case to provide detailed information about the brain structures and aid in accurate diagnosis.

The T1 anatomical MRI will allow us to visualize the brain in high resolution and identify any abnormalities or areas of damage caused by the traumatic brain injury. This information is essential for guiding further medical interventions and monitoring John Doe's progress towards recovery.

I strongly believe that this imaging study is medically necessary for John Doe's care and urge United Healthcare to approve the request promptly. Your cooperation in this matter is greatly appreciated and will help ensure the best possible outcome for my patient.

Sincerely,

Winchenzo Grumpyface
GRUMPYFACE ORG
123456789`;

    const handleFormDataChange = (newFormData) => {
        setFormData(newFormData);
    };

    const handleSaveData = async () => {
        return;
    };


    useEffect(() => {
        localStorage.setItem('email', email);
    }, [email]);

    const handleNavigation = (page) => {
        if (email) {
            if (!validateEmail(email)) {
                alert('Please enter a valid email address.');
            } else {
                navigate(`/${page}?email=${encodeURIComponent(email)}`);
            }
        } else {
            navigate(`/${page}`);
        }
    };

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    return (
        <div className="patients-page">
            <Helmet>
                <title>Patients | Popularis</title>
                <meta name="description" content="Manage your patients in one place with Popularis Health." />
                <meta name="keywords" content="Patients, Healthcare Management, Electronic Health Records" />
                <meta property="og:title" content="Patients | Popularis Health" />
                <meta property="og:description" content="Manage your patients in one place with Popularis Health." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
            </Helmet>
            <Header />
            <main className="patients-content">
                <section className="hero-section" ref={el => sectionRefs.current['hero'] = el} id="hero">
                    <div className="">
                        <h1 className={`hero-title ${isVisible['hero'] ? 'animate' : ''}`}>Manage all your patients from one place.</h1>
                        <p className={`hero-subtitle ${isVisible['hero'] ? 'animate' : ''}`}>Clinics, Encounters, Notes, Medical History, and Billing.</p>
                        <div className={`hero-image-container ${isVisible['hero'] ? 'animate' : ''}`}>
                            <img src={Patients} alt="Comprehensive patient management interface" className="hero-image" />
                        </div>
                    </div>
                </section>



                <section className="clinics-section" ref={el => sectionRefs.current['clinics'] = el} id="clinics">
                    <div className="golden-container">
                        <h2 className={`section-title ${isVisible['clinics'] ? 'animate' : ''}`}>Streamline Patient Intake with Clinics</h2>
                        <p className={`section-description ${isVisible['clinics'] ? 'animate' : ''}`}>Generate links and QR codes, allowing patients to submit their intake forms directly to different clinics.</p>
                        <div className={`clinics-demo ${isVisible['clinics'] ? 'animate' : ''}`}>
                            <img src={clinics} alt="Mobile clinic interface" className="clinics-mobile" />
                            <img src={clinicsLaptop} alt="Desktop clinic interface" className="clinics-desktop" />
                        </div>
                    </div>
                </section>

         

                <section className="dynamic-forms-section" ref={el => sectionRefs.current['dynamic-forms'] = el} id="dynamic-forms">
                    <div className="golden-container">
                        <h2 className="section-title">Dynamic Patient Intake Forms</h2>
                        <p className="section-description">Create customized, interactive patient intake forms tailored to your specific needs. Our dynamic form builder allows you to design intuitive questionnaires that adapt based on patient responses, ensuring you collect all necessary information efficiently.</p>
                        <ul className="feature-list">
                            <li>Templates customizable for any specialty</li>
                            <li>Conditional logic to show or hide questions based on responses</li>
                            <li>Mobile-friendly design for easy access</li>
                            <li>Branding with your company’s logo</li>
                        </ul>
                        <div className="form-editor-demo">
                            <h3>Try our Form Editor</h3>
                            <FormEditor
                                className=""
                                preview={true}
                                saveLocation="0"
                                initialFormElementsData={[]}
                                onFormElementsDataChange={handleFormDataChange}
                                handleSave={handleSaveData}
                            />
                        </div>
                    </div>
                </section>

                <section className="ai-notes-section" ref={el => sectionRefs.current['ai-notes'] = el} id="ai-notes">
                    <div className="golden-container">
                        <h2 className={`section-title ${isVisible['ai-notes'] ? 'animate' : ''}`}>Leverage PopularisAI to write notes</h2>
                        <p className={`section-description ${isVisible['ai-notes'] ? 'animate' : ''}`}>Give it a short prompt, and using our medically trained AI, notes will give you back a completed doctor's note.</p>
                        <div className={`ai-demo ${isVisible['ai-notes'] ? 'animate' : ''}`}>
                            <div className="ai-input">
                                <img src={AIInput} alt="AI-powered note input interface" className="ai-input-image" />
                            </div>
                            <div className="ai-output">
                                <div className="typing-container" ref={typingContainerRef}>
                                    {isVisible['ai-notes'] && <TypingText text={message} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ai-notes-section">
                <div className="golden-container">
                    <div className=' tile-security tile tile-black'>
                      <div className="security-wrapper">
                        <FontAwesomeIcon icon={faLock} style={{ height: "48px", margin: "1rem" }} />
                        <h1 style={{textAlign: "center"}} className="typography-design-headline text-center">Zero-Trust Encryption</h1>
                        <p className="typography-label-alt-white text-center">In today's cyber-threat environment, it is critical that all sensitive health information is encrypted. We use the Advanced Encryption Standard (AES) developed by the National Institute of Standards (NIST). Additionally, our encrypted ciphertext requires multiple keys to decrypt, adding to the enigma.</p>
                        <EncryptionSample />
                      </div>
                    </div>
                    </div>
                </section>

                <section  className="ai-notes-section" ref={el => sectionRefs.current['sale'] = el} id="ai-notes">
                    <div className="golden-container Card">
                        <div
                            className={`tile-tools tile-dark pt-4`}
                            data-id="tools"
                            ref={el => sectionRefs.current['sale'] = el}
                        >
                            <h1 style={{ marginTop: "10px", textAlign: "center", marginBottom: "0px" }} className='typography-design-headline'>
                                Experience our user-friendliness.
                            </h1>
                            <p style={{ marginTop: "10px", textAlign: "center", marginBottom: "20px" }} className="typography-label-alt-white">The full suite of clinical and administrative tools in healthcare.</p>
                            <div className={`cta-buttons`}>
                                {/* <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                /> */}
                                <button style={{ marginTop: "0px", marginBottom: "0px" }} className="cta-button" onClick={() => handleNavigation('signup')}>
                                    Try for Free
                                </button>
                                <button style={{ marginTop: "0px", marginBottom: "0px" }} className="cta-button" onClick={() => handleNavigation('demo')}>
                                    Book a Demo
                                </button>
                            </div>
                            <div style={{ paddingBottom: "10px" }} className="hero-disclaimer-container" >
                                <p style={{ textAlign: "center", marginBottom: "10px" }} className={`hero-disclaimer ${isVisible['hero'] ? 'animate' : ''}`}>Immediate access - No credit card required</p>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="activities-section" ref={el => sectionRefs.current['activities'] = el} id="activities">
                    <div className="golden-container">
                        <h2 className={`section-title ${isVisible['activities'] ? 'animate' : ''}`}>A bird's-eye view of the clinical staff</h2>
                        <p className={`section-description ${isVisible['activities'] ? 'animate' : ''}`}>Activities tracks the clinical actions within your organization. Notes written, encounters done, patients added, calls scheduled, and more.</p>
                        <div className={`activities-demo ${isVisible['activities'] ? 'animate' : ''}`}>
                            <img src={Activities} alt="Activities dashboard" className="activities-image" />
                        </div>
                    </div>
                </section>

                <section className="medical-history-section" ref={el => sectionRefs.current['medical-history'] = el} id="medical-history">
                    <div className="golden-container">
                        <h2 className={`section-title ${isVisible['medical-history'] ? 'animate' : ''}`}>Comprehensive Medical Records</h2>
                        <div className={`medical-history-demo ${isVisible['medical-history'] ? 'animate' : ''}`}>
                            <div className="medical-history-text">
                                <ul className="feature-list">
                                    <li>Medical History</li>
                                    <li>Family History</li>
                                    <li>Immunizations and Allergies</li>
                                    <li>Lab and Test Results</li>
                                    <li>Medications</li>
                                    <li>Vitals</li>
                                </ul>
                            </div>
                            <div className="medical-history-image">
                                <img src={MedicalHistory} alt="Medical history interface" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="telehealth-section" ref={el => sectionRefs.current['telehealth'] = el} id="telehealth">
                    <div className="golden-container">
                        <h2 className={`section-title ${isVisible['telehealth'] ? 'animate' : ''}`}>Embrace the Telehealth Revolution</h2>
                        <p className={`section-description ${isVisible['telehealth'] ? 'animate' : ''}`}>Schedule HIPAA compliant Zoom calls with patients and keep an eye on those patients that need it. We have learned that sometimes it's safer for a patient to stay at home.</p>
                        <div className={`telehealth-demo ${isVisible['telehealth'] ? 'animate' : ''}`}>
                            <div className="telehealth-image">
                                <img src={telehealth} alt="Telehealth interface" />
                            </div>
                            <div className="partner-logos">
                                <img src={zoom} alt="Zoom logo" className="partner-logo" />
                                <img src={HIPAA} alt="HIPAA compliance logo" className="partner-logo" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />


            <style jsx>{`
    .patients-page {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
        color: #1d1d1f;
        background-color: #f5f5f7;
        -webkit-font-smoothing: antialiased;
    }

    .golden-container {
        width: 980px;
        margin: 0 auto;
        padding: 0 22px;
    }

    .hero-section {
        background-color: #00316f;
        color: #f5f5f7;
        padding: 120px 0;
        text-align: center;
    }

    .hero-title {
        font-size: 56px;
        line-height: 1.07143;
        font-weight: 600;
        letter-spacing: -.005em;
        margin-bottom: 20px;
    }

    .hero-subtitle {
        font-size: 28px;
        line-height: 1.10722;
        font-weight: 400;
        letter-spacing: .004em;
        max-width: 800px;
        margin: 0 auto 40px;
    }


    .section-title {
        font-size: 48px;
        line-height: 1.08349;
        font-weight: 600;
        letter-spacing: -.003em;
        margin-bottom: 20px;
        color: #1d1d1f;
        text-align: center;
    }

    .section-description {
        font-size: 21px;
        line-height: 1.381;
        font-weight: 400;
        letter-spacing: .011em;
        margin-bottom: 40px;
        text-align: center;
        color: #86868b;
    }

    .clinics-section {
        background-color: #ffffff;
        padding: 100px 0;
    }

    .ai-notes-section,
    .activities-section,
    .medical-history-section,
    .telehealth-section {
        padding: 100px 0;
    }

    .ai-notes-section,
    .activities-section,
    .telehealth-section {
        background-color: #fafafa;
    }

    .clinics-demo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
    }

    .clinics-mobile {
        max-width: 35%;
        height: auto;
        border-radius: 18px;
    }

    .clinics-desktop {
        max-width: 55%;
        height: auto;
        border-radius: 18px;
    }

    .activities-demo {
    text-align: center;
    }

.ai-demo, .activities-demo, .medical-history-demo, .telehealth-demo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
    align-content: stretch;
}

    .ai-input,
    .ai-output {
        flex: 1;
    }

    .ai-input-image,
    .activities-image,
    .medical-history-image img,
    .telehealth-image img {
        max-width: 100%;
        height: auto;
        border-radius: 18px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }

 .typing-container {
        background-color: #fff;
        padding: 20px;
        border-radius: 18px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        font-size: 14px;
        line-height: 1.4;
        height: 400px;
        overflow-y: auto;
    }

    .feature-list {
        list-style-type: none;
        padding: 0;
        margin-bottom: 50px;
    }

    .feature-list li {
        font-size: 21px;
        line-height: 1.381;
        font-weight: 400;
        letter-spacing: .011em;
        margin-bottom: 10px;
        color: #1d1d1f;
    }

    .partner-logos {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 40px;
    }

    .partner-logo {
        height: 40px;
        width: auto;
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .animate {
        animation: fadeInUp 0.6s ease-out forwards;
    }

    @media only screen and (max-width: 1068px) {
        .golden-container {
            width: 692px;
        }

        .hero-title {
            font-size: 48px;
        }

        .hero-subtitle {
            font-size: 24px;
        }

        .section-title {
            font-size: 40px;
        }

        .feature-list li {
            font-size: 19px;
        }
    }

    @media only screen and (max-width: 734px) {

    .hero-image-container {
        max-width: 95%;
        margin: 0 auto;
    }
        .golden-container {
            width: 87.5%;
        }

        .hero-title {
            font-size: 40px;
        }

        .hero-subtitle {
            font-size: 22px;
        }

        .section-title {
            font-size: 32px;
        }

        .section-description {
            font-size: 19px;
        }

        .clinics-demo,
        .ai-demo,
        .activities-demo,
        .medical-history-demo,
        .telehealth-demo {
            flex-direction: column;
        }

        .clinics-mobile,
        .clinics-desktop {
            max-width: 100%;
            margin-bottom: 20px;
        }

        .feature-list li {
            font-size: 17px;
        }

        .partner-logos {
            flex-direction: column;
            align-items: center;
        }

        .partner-logo {
            margin-bottom: 20px;
        }

        .form-editor-demo {
            display: none;
        }
    }
`}</style>
        </div>
    );
};

export default TryPatients;