import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { db } from "../../../../firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import useUID from "../../../General/useUID";
import './IntakeView.css'

function Sidebar() {
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [uid] = useUID();
  const navigate = useNavigate();

  useEffect(() => {
    if (!uid) return;

    const clinicsCol = collection(db, "clinics");
    const q = query(clinicsCol, where("ownerId", "==", uid));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const clinicList = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((clinic) => !clinic.deleted); // Exclude deleted clinics

      setClinics(clinicList);

      // Automatically select the first clinic if none selected
      if (!selectedClinic && clinicList.length > 0) {
        handleClinicSelect(clinicList[0]);
      }
    });

    return () => unsubscribe();
  }, [uid, selectedClinic]);

  const handleClinicSelect = (clinic) => {
    setSelectedClinic(clinic);
    navigate(`/intakeview/${clinic.id}`);
  };

  return (
    <div className="sidebarContainer">
      <div className="backButton" onClick={() => navigate("/patients")}>
        <span>&lt; Back to Patients</span>
      </div>
      <ul className="clinicList">
        {clinics.map((clinic) => (
          <li
            key={clinic.id}
            className={`clinicItem ${
              selectedClinic?.id === clinic.id ? "selectedClinic" : ""
            }`}
            onClick={() => handleClinicSelect(clinic)}
          >
            <FontAwesomeIcon icon={faFolder} className="clinicIcon" />
            <span>{clinic.clinicName}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
