import React from 'react';
import '../guides/APIGuides.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const AddPatientGuide = () => {
    return (
        <div className="guide-page">
            <h1>Getting Started with the Add Patient API</h1>
            <p>This guide explains how to integrate your application with the Add Patient API, including details on required fields, authentication, and handling patient and encounter data.</p>

        
            {/* Run in Postman Button */}
              <div className="postman-button-container">
                <a
                    href="https://www.postman.com/galactic-zodiac-954587/apis/request/8ttotgv/addpatientpost?action=share&source=copy-link&creator=25649850&ctx=documentation"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="postman-button"
                >
                    <FontAwesomeIcon icon={faPlay} className="play-icon" />
               
                    <span>Run in Postman</span>
                </a>
            </div>


            <h2>Authentication</h2>
            <p>To authenticate with the Add Patient API, you need an API key and Client ID. Follow these steps to generate or regenerate your credentials:</p>
            <ol>
                <li>Log in to the <strong>Admin Account</strong> of your application.</li>
                <li>Navigate to the <strong>Settings</strong> page and click on the <strong>API</strong> section.</li>
                <li>Scroll to the bottom right of the page and click the <strong>Generate API Credentials</strong> button to create new credentials, or click <strong>Regenerate</strong> if you need to reset your keys.</li>
                <li>Note down the <strong>Client ID</strong> and <strong>API Key</strong>. These will be required for making authenticated API requests.</li>
            </ol>
            <p>Include the API key in the request headers as follows:</p>
            <pre>
                {`
                {
                    "x-api-key": "YOUR_API_KEY"
                }
                `}
            </pre>

            <h2>Overview</h2>
            <p>The Add Patient API allows you to save patient data and their associated encounters into your system. This API is designed to be flexible, supporting optional fields and detailed encounter data to meet your specific requirements.</p>

            <h2>Available Data Fields</h2>
            <p>You can send the following fields in your payload using dot notation (e.g., <code>patient.firstName</code>):</p>
            <ul>
                <li><strong>Patient Information:</strong>
                    <ul>
                        <li><strong>firstName:</strong> Patient's first name</li>
                        <li><strong>lastName:</strong> Patient's last name</li>
                        <li><strong>dob:</strong> Patient's date of birth (formatted as YYYY-MM-DD)</li>
                        <li><strong>gender:</strong> Patient's gender</li>
                        <li><strong>address1:</strong> Primary address</li>
                        <li><strong>city:</strong> City</li>
                        <li><strong>state:</strong> State</li>
                        <li><strong>zip:</strong> ZIP code</li>
                        <li><strong>memberId:</strong> Insurance member ID</li>
                        <li><strong>payerName:</strong> Insurance provider name</li>
                    </ul>
                </li>
                <li><strong>Encounter Information:</strong>
                    <ul>
                        <li><strong>dateOfService:</strong> Date of service</li>
                        <li><strong>reasonForVisit:</strong> Reason for the visit</li>
                        <li><strong>service:</strong> Service provided</li>
                        <li><strong>encounterType:</strong> Type of encounter (e.g., In-person)</li>
                        <li><strong>controlNumber:</strong> Encounter control number</li>
                        <li><strong>notes:</strong> Notes or comments</li>
                        <li><strong>billingCodes:</strong> Array of billing codes with details such as:
                            <ul>
                                <li><strong>code:</strong> Billing code</li>
                                <li><strong>description:</strong> Description of the code</li>
                                <li><strong>amount:</strong> Amount billed</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

            <h2>Payload Structure</h2>
            <p>The payload for the Add Patient API should be structured as follows:</p>
            <pre>
                {`
                {
                    "patient": {
                        "firstName": "John",
                        "lastName": "Doe",
                        "dob": "1980-01-01",
                        "gender": "Male",
                        "address": {
                            "address1": "123 Main St",
                            "city": "Anytown",
                            "state": "CA",
                            "zip": "12345"
                        },
                        "memberId": "A12345",
                        "payerName": "HealthPayer"
                    },
                    "encounters": [
                        {
                            "dateOfService": "2025-01-12",
                            "reasonForVisit": "Routine check-up",
                            "service": "Consultation",
                            "encounterType": "In-person",
                            "controlNumber": "CN123456",
                            "notes": "Patient in good health.",
                            "billingCodes": [
                                { "code": "A123", "description": "Blood test", "amount": 50 }
                            ]
                        }
                    ]
                }
                `}
            </pre>

            <h2>Testing Your Payload</h2>
            <p>Use the provided payload structure to test your API integration. The expected response will look like this:</p>
            <pre>
                {`
                {
                    "status": "success",
                    "message": "Patient data saved successfully with 1 encounter(s).",
                    "patientId": "UID12345",
                    "encounterCount": 1
                }
                `}
            </pre>

            <h2>Common Errors</h2>
            <p>Ensure you provide all required fields. If a required field is missing, you may receive an error response like this:</p>
            <pre>
                {`
                {
                    "status": "error",
                    "message": "Missing required patient fields: firstName, lastName, dob, gender."
                }
                `}
            </pre>

            <h2>Additional Resources</h2>
            <ul>
                <li>
                    <a href="/tryapi/guides/custom-api-mappings" className="guide-link">
                        Customizing API Mappings
                    </a>
                </li>
                <li>
                    <a href="/tryapi/guides/encounters-api" className="guide-link">
                        Working with the Encounters API
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default AddPatientGuide;
